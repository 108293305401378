import {_private as themeDefinitions} from '../../themes/generated/generated-definitions';

const {Themes} = themeDefinitions;
type Themes = typeof Themes;

interface GradientColor {
  color:
    | 'BLACK_500'
    | 'WHITE_500'
    | 'gradient.start'
    | 'gradient.mid'
    | 'gradient.end'
    | 'gradient.primary'
    | 'gradient.secondary';
  saturation?: number | string;
  lightness?: number | string;
  opacity?: number;
}

export type SemanticColors = Record<
  string,
  Record<
    Themes[keyof Themes],
    {
      raw: keyof RawColors;
      opacity?: number;
    }
  > & {
    category: 'background' | 'border' | 'text' | 'generic';
    gradient?: {dark: GradientColor; light: GradientColor};
  }
>;
const SemanticColors = {
  ACTION_SHEET_GRADIENT_BG: {
    category: 'background',
    [Themes.DARK]: {raw: 'PRIMARY_630', opacity: 1},
    [Themes.LIGHT]: {raw: 'PRIMARY_130', opacity: 1},
    [Themes.MIDNIGHT]: {raw: 'BLACK', opacity: 1},
    [Themes.DARKER]: {raw: 'PLUM_20', opacity: 1},
    gradient: {
      light: {color: 'gradient.mid', lightness: 0.925, saturation: '*0.8'},
      dark: {color: 'gradient.mid', lightness: 0.12, saturation: '*0.5'},
    },
  },
  ACTIVITY_CARD_BACKGROUND: {
    category: 'generic',
    [Themes.DARK]: {raw: 'PRIMARY_700', opacity: 1},
    [Themes.LIGHT]: {raw: 'WHITE', opacity: 1},
    [Themes.MIDNIGHT]: {raw: 'PRIMARY_700', opacity: 1},
    [Themes.DARKER]: {raw: 'PRIMARY_700', opacity: 1},
  },
  ACTIVITY_CARD_ICON_OVERLAY: {
    category: 'generic',
    [Themes.DARK]: {raw: 'PRIMARY_600', opacity: 0.85},
    [Themes.LIGHT]: {raw: 'PRIMARY_600', opacity: 0.85},
    [Themes.MIDNIGHT]: {raw: 'PRIMARY_600', opacity: 0.85},
    [Themes.DARKER]: {raw: 'PRIMARY_600', opacity: 0.85},
  },
  ALERT_BG: {
    category: 'background',
    [Themes.DARK]: {raw: 'PRIMARY_600', opacity: 1},
    [Themes.LIGHT]: {raw: 'WHITE', opacity: 1},
    [Themes.MIDNIGHT]: {raw: 'BLACK', opacity: 1},
    [Themes.DARKER]: {raw: 'PLUM_20', opacity: 1},
  },
  ANDROID_NAVIGATION_BAR_BACKGROUND: {
    category: 'generic',
    [Themes.DARK]: {raw: 'PRIMARY_830', opacity: 1},
    [Themes.LIGHT]: {raw: 'PRIMARY_100', opacity: 1},
    [Themes.MIDNIGHT]: {raw: 'PLUM_24', opacity: 1},
    [Themes.DARKER]: {raw: 'PLUM_24', opacity: 1},
  },
  ANDROID_NAVIGATION_SCRIM_BACKGROUND: {
    category: 'generic',
    [Themes.DARK]: {raw: 'PRIMARY_830', opacity: 0.5},
    [Themes.LIGHT]: {raw: 'PRIMARY_100', opacity: 0.5},
    [Themes.MIDNIGHT]: {raw: 'PLUM_24', opacity: 0.5},
    [Themes.DARKER]: {raw: 'PLUM_24', opacity: 0.5},
  },
  ANDROID_RIPPLE: {
    category: 'generic',
    [Themes.DARK]: {raw: 'WHITE', opacity: 0.07},
    [Themes.LIGHT]: {raw: 'BLACK', opacity: 0.12},
    [Themes.MIDNIGHT]: {raw: 'WHITE', opacity: 0.07},
    [Themes.DARKER]: {raw: 'WHITE', opacity: 0.07},
  },
  AUTOCOMPLETE_BG: {
    category: 'background',
    [Themes.DARK]: {raw: 'PRIMARY_630', opacity: 1},
    [Themes.LIGHT]: {raw: 'PRIMARY_100', opacity: 1},
    [Themes.MIDNIGHT]: {raw: 'PRIMARY_630', opacity: 1},
    [Themes.DARKER]: {raw: 'PRIMARY_630', opacity: 1},
  },
  BACKGROUND_ACCENT: {
    category: 'background',
    [Themes.DARK]: {raw: 'PRIMARY_530', opacity: 1},
    [Themes.LIGHT]: {raw: 'PRIMARY_430', opacity: 1},
    [Themes.MIDNIGHT]: {raw: 'PLUM_17', opacity: 1},
    [Themes.DARKER]: {raw: 'PLUM_15', opacity: 1},
    gradient: {
      light: {color: 'gradient.mid', opacity: 0.4, lightness: '*0.75'},
      dark: {color: 'gradient.mid', opacity: 0.3, lightness: 0.75, saturation: '*1.2'},
    },
  },
  BACKGROUND_FLOATING: {
    category: 'background',
    [Themes.DARK]: {raw: 'PRIMARY_800', opacity: 1},
    [Themes.LIGHT]: {raw: 'WHITE', opacity: 1},
    [Themes.MIDNIGHT]: {raw: 'PLUM_23', opacity: 1},
    [Themes.DARKER]: {raw: 'PLUM_18', opacity: 1},
  },
  BACKGROUND_MENTIONED: {
    category: 'background',
    [Themes.DARK]: {raw: 'YELLOW_300', opacity: 0.1},
    [Themes.LIGHT]: {raw: 'YELLOW_300', opacity: 0.1},
    [Themes.MIDNIGHT]: {raw: 'YELLOW_260', opacity: 0.08},
    [Themes.DARKER]: {raw: 'YELLOW_260', opacity: 0.08},
  },
  BACKGROUND_MENTIONED_HOVER: {
    category: 'background',
    [Themes.DARK]: {raw: 'YELLOW_300', opacity: 0.08},
    [Themes.LIGHT]: {raw: 'YELLOW_300', opacity: 0.2},
    [Themes.MIDNIGHT]: {raw: 'YELLOW_300', opacity: 0.08},
    [Themes.DARKER]: {raw: 'YELLOW_300', opacity: 0.08},
  },
  BACKGROUND_MESSAGE_AUTOMOD: {
    category: 'background',
    [Themes.DARK]: {raw: 'RED_400', opacity: 0.05},
    [Themes.LIGHT]: {raw: 'RED_400', opacity: 0.05},
    [Themes.MIDNIGHT]: {raw: 'RED_345', opacity: 0.08},
    [Themes.DARKER]: {raw: 'RED_345', opacity: 0.08},
  },
  BACKGROUND_MESSAGE_AUTOMOD_HOVER: {
    category: 'background',
    [Themes.DARK]: {raw: 'RED_400', opacity: 0.1},
    [Themes.LIGHT]: {raw: 'RED_400', opacity: 0.1},
    [Themes.MIDNIGHT]: {raw: 'RED_400', opacity: 0.1},
    [Themes.DARKER]: {raw: 'RED_400', opacity: 0.1},
  },
  BACKGROUND_MESSAGE_HIGHLIGHT: {
    category: 'background',
    [Themes.DARK]: {raw: 'BRAND_360', opacity: 0.08},
    [Themes.LIGHT]: {raw: 'BRAND_360', opacity: 0.1},
    [Themes.MIDNIGHT]: {raw: 'BRAND_360', opacity: 0.08},
    [Themes.DARKER]: {raw: 'BRAND_360', opacity: 0.08},
  },
  BACKGROUND_MESSAGE_HIGHLIGHT_HOVER: {
    category: 'background',
    [Themes.DARK]: {raw: 'BRAND_360', opacity: 0.06},
    [Themes.LIGHT]: {raw: 'BRAND_360', opacity: 0.2},
    [Themes.MIDNIGHT]: {raw: 'BRAND_360', opacity: 0.06},
    [Themes.DARKER]: {raw: 'BRAND_360', opacity: 0.06},
  },
  BACKGROUND_MESSAGE_HOVER: {
    category: 'background',
    [Themes.DARK]: {raw: 'PRIMARY_900', opacity: 0.06},
    [Themes.LIGHT]: {raw: 'PRIMARY_900', opacity: 0.03},
    [Themes.MIDNIGHT]: {raw: 'PLUM_21', opacity: 1},
    [Themes.DARKER]: {raw: 'PLUM_21', opacity: 1},
  },
  BACKGROUND_MOBILE_PRIMARY: {
    category: 'background',
    [Themes.DARK]: {raw: 'PRIMARY_600', opacity: 1},
    [Themes.LIGHT]: {raw: 'PRIMARY_100', opacity: 1},
    [Themes.MIDNIGHT]: {raw: 'BLACK', opacity: 1},
    [Themes.DARKER]: {raw: 'PLUM_20', opacity: 1},
  },
  BACKGROUND_MOBILE_SECONDARY: {
    category: 'background',
    [Themes.DARK]: {raw: 'PRIMARY_630', opacity: 1},
    [Themes.LIGHT]: {raw: 'WHITE', opacity: 1},
    [Themes.MIDNIGHT]: {raw: 'BLACK', opacity: 1},
    [Themes.DARKER]: {raw: 'PLUM_20', opacity: 1},
    gradient: {light: {color: 'WHITE_500', opacity: 0.6}, dark: {color: 'BLACK_500', opacity: 0.4}},
  },
  BACKGROUND_MODIFIER_ACCENT: {
    category: 'background',
    [Themes.DARK]: {raw: 'PRIMARY_500', opacity: 0.48},
    [Themes.LIGHT]: {raw: 'PRIMARY_400', opacity: 0.24},
    [Themes.MIDNIGHT]: {raw: 'PLUM_23_ALPHA', opacity: 0.1},
    [Themes.DARKER]: {raw: 'PLUM_11', opacity: 0.12},
  },
  BACKGROUND_MODIFIER_ACCENT_2: {
    category: 'background',
    [Themes.DARK]: {raw: 'PRIMARY_500', opacity: 0.48},
    [Themes.LIGHT]: {raw: 'PRIMARY_400', opacity: 0.16},
    [Themes.MIDNIGHT]: {raw: 'PLUM_11', opacity: 0.32},
    [Themes.DARKER]: {raw: 'PLUM_11', opacity: 0.24},
  },
  BACKGROUND_MODIFIER_ACTIVE: {
    category: 'background',
    [Themes.DARK]: {raw: 'PRIMARY_500', opacity: 0.48},
    [Themes.LIGHT]: {raw: 'PRIMARY_400', opacity: 0.2},
    [Themes.MIDNIGHT]: {raw: 'PLUM_13', opacity: 0.48},
    [Themes.DARKER]: {raw: 'PLUM_13', opacity: 0.48},
  },
  BACKGROUND_MODIFIER_HOVER: {
    category: 'background',
    [Themes.DARK]: {raw: 'PRIMARY_500', opacity: 0.3},
    [Themes.LIGHT]: {raw: 'PRIMARY_400', opacity: 0.16},
    [Themes.MIDNIGHT]: {raw: 'PLUM_13', opacity: 0.3},
    [Themes.DARKER]: {raw: 'PLUM_13', opacity: 0.3},
  },
  BACKGROUND_MODIFIER_SELECTED: {
    category: 'background',
    [Themes.DARK]: {raw: 'PRIMARY_500', opacity: 0.6},
    [Themes.LIGHT]: {raw: 'PRIMARY_400', opacity: 0.24},
    [Themes.MIDNIGHT]: {raw: 'PLUM_13', opacity: 0.6},
    [Themes.DARKER]: {raw: 'PLUM_13', opacity: 0.6},
  },
  BACKGROUND_NESTED_FLOATING: {
    category: 'background',
    [Themes.DARK]: {raw: 'PRIMARY_630', opacity: 1},
    [Themes.LIGHT]: {raw: 'WHITE', opacity: 1},
    [Themes.MIDNIGHT]: {raw: 'BLACK', opacity: 1},
    [Themes.DARKER]: {raw: 'PLUM_22', opacity: 1},
    gradient: {light: {color: 'BLACK_500', opacity: 0.1}, dark: {color: 'WHITE_500', opacity: 0.1}},
  },
  BACKGROUND_PRIMARY: {
    category: 'background',
    [Themes.DARK]: {raw: 'PRIMARY_600', opacity: 1},
    [Themes.LIGHT]: {raw: 'WHITE', opacity: 1},
    [Themes.MIDNIGHT]: {raw: 'BLACK', opacity: 1},
    [Themes.DARKER]: {raw: 'PLUM_20', opacity: 1},
    gradient: {light: {color: 'WHITE_500', opacity: 0.5}, dark: {color: 'BLACK_500', opacity: 0.5}},
  },
  BACKGROUND_SECONDARY: {
    category: 'background',
    [Themes.DARK]: {raw: 'PRIMARY_630', opacity: 1},
    [Themes.LIGHT]: {raw: 'PRIMARY_130', opacity: 1},
    [Themes.MIDNIGHT]: {raw: 'PLUM_23', opacity: 1},
    [Themes.DARKER]: {raw: 'PLUM_18', opacity: 1},
  },
  BACKGROUND_SECONDARY_ALT: {
    category: 'background',
    [Themes.DARK]: {raw: 'PRIMARY_660', opacity: 1},
    [Themes.LIGHT]: {raw: 'PRIMARY_160', opacity: 1},
    [Themes.MIDNIGHT]: {raw: 'PLUM_17', opacity: 1},
    [Themes.DARKER]: {raw: 'PLUM_15', opacity: 1},
    gradient: {light: {color: 'WHITE_500', opacity: 0.5}, dark: {color: 'BLACK_500', opacity: 0.5}},
  },
  BACKGROUND_TERTIARY: {
    category: 'background',
    [Themes.DARK]: {raw: 'PRIMARY_700', opacity: 1},
    [Themes.LIGHT]: {raw: 'PRIMARY_200', opacity: 1},
    [Themes.MIDNIGHT]: {raw: 'PLUM_19', opacity: 1},
    [Themes.DARKER]: {raw: 'PLUM_16', opacity: 1},
  },
  BADGE_BRAND_BG: {
    category: 'background',
    [Themes.DARK]: {raw: 'BRAND_260', opacity: 1},
    [Themes.LIGHT]: {raw: 'BRAND_260', opacity: 1},
    [Themes.MIDNIGHT]: {raw: 'BRAND_260', opacity: 1},
    [Themes.DARKER]: {raw: 'BRAND_260', opacity: 1},
  },
  BADGE_BRAND_TEXT: {
    category: 'text',
    [Themes.DARK]: {raw: 'BRAND_560', opacity: 1},
    [Themes.LIGHT]: {raw: 'BRAND_560', opacity: 1},
    [Themes.MIDNIGHT]: {raw: 'BRAND_560', opacity: 1},
    [Themes.DARKER]: {raw: 'BRAND_560', opacity: 1},
  },
  BG_BACKDROP: {
    category: 'background',
    [Themes.DARK]: {raw: 'BLACK', opacity: 0.7},
    [Themes.LIGHT]: {raw: 'BLACK', opacity: 0.54},
    [Themes.MIDNIGHT]: {raw: 'PLUM_19', opacity: 0.75},
    [Themes.DARKER]: {raw: 'BLACK', opacity: 0.7},
    gradient: {
      light: {color: 'gradient.start', saturation: 0.2, lightness: 0.3, opacity: 0.7},
      dark: {color: 'gradient.start', saturation: 0.25, lightness: 0.1, opacity: 0.7},
    },
  },
  BG_BACKDROP_NO_OPACITY: {
    category: 'background',
    [Themes.DARK]: {raw: 'BLACK', opacity: 1},
    [Themes.LIGHT]: {raw: 'BLACK', opacity: 1},
    [Themes.MIDNIGHT]: {raw: 'PLUM_19', opacity: 1},
    [Themes.DARKER]: {raw: 'BLACK', opacity: 1},
  },
  BG_BASE_PRIMARY: {
    category: 'background',
    [Themes.DARK]: {raw: 'PRIMARY_600', opacity: 1},
    [Themes.LIGHT]: {raw: 'WHITE', opacity: 1},
    [Themes.MIDNIGHT]: {raw: 'BLACK', opacity: 1},
    [Themes.DARKER]: {raw: 'PLUM_20', opacity: 1},
    gradient: {light: {color: 'WHITE_500', opacity: 0.8}, dark: {color: 'BLACK_500', opacity: 0.7}},
  },
  BG_BASE_SECONDARY: {
    category: 'background',
    [Themes.DARK]: {raw: 'PRIMARY_630', opacity: 1},
    [Themes.LIGHT]: {raw: 'PRIMARY_130', opacity: 1},
    [Themes.MIDNIGHT]: {raw: 'BLACK', opacity: 1},
    [Themes.DARKER]: {raw: 'PLUM_20', opacity: 1},
    gradient: {light: {color: 'WHITE_500', opacity: 0.8}, dark: {color: 'BLACK_500', opacity: 0.7}},
  },
  BG_BASE_TERTIARY: {
    category: 'background',
    [Themes.DARK]: {raw: 'PRIMARY_660', opacity: 1},
    [Themes.LIGHT]: {raw: 'PRIMARY_160', opacity: 1},
    [Themes.MIDNIGHT]: {raw: 'BLACK', opacity: 1},
    [Themes.DARKER]: {raw: 'PLUM_23', opacity: 1},
    gradient: {light: {color: 'WHITE_500', opacity: 0.8}, dark: {color: 'BLACK_500', opacity: 0.7}},
  },
  BG_BRAND: {
    category: 'background',
    [Themes.DARK]: {raw: 'BRAND_500', opacity: 1},
    [Themes.LIGHT]: {raw: 'BRAND_500', opacity: 1},
    [Themes.MIDNIGHT]: {raw: 'BRAND_500', opacity: 1},
    [Themes.DARKER]: {raw: 'BRAND_500', opacity: 1},
  },
  BG_MOD_FAINT: {
    category: 'background',
    [Themes.DARK]: {raw: 'PRIMARY_500', opacity: 0.3},
    [Themes.LIGHT]: {raw: 'PRIMARY_400', opacity: 0.08},
    [Themes.MIDNIGHT]: {raw: 'PLUM_11', opacity: 0.08},
    [Themes.DARKER]: {raw: 'PLUM_11', opacity: 0.12},
    gradient: {light: {color: 'BLACK_500', opacity: 0.05}, dark: {color: 'WHITE_500', opacity: 0.05}},
  },
  BG_MOD_STRONG: {
    category: 'background',
    [Themes.DARK]: {raw: 'PRIMARY_500', opacity: 0.54},
    [Themes.LIGHT]: {raw: 'PRIMARY_400', opacity: 0.24},
    [Themes.MIDNIGHT]: {raw: 'PLUM_11', opacity: 0.18},
    [Themes.DARKER]: {raw: 'PLUM_11', opacity: 0.24},
    gradient: {light: {color: 'BLACK_500', opacity: 0.1}, dark: {color: 'WHITE_500', opacity: 0.1}},
  },
  BG_MOD_SUBTLE: {
    category: 'background',
    [Themes.DARK]: {raw: 'PRIMARY_500', opacity: 0.48},
    [Themes.LIGHT]: {raw: 'PRIMARY_400', opacity: 0.16},
    [Themes.MIDNIGHT]: {raw: 'PLUM_11', opacity: 0.12},
    [Themes.DARKER]: {raw: 'PLUM_11', opacity: 0.16},
    gradient: {light: {color: 'BLACK_500', opacity: 0.075}, dark: {color: 'WHITE_500', opacity: 0.075}},
  },
  BG_SURFACE_OVERLAY: {
    category: 'background',
    [Themes.DARK]: {raw: 'PRIMARY_800', opacity: 1},
    [Themes.LIGHT]: {raw: 'WHITE', opacity: 1},
    [Themes.MIDNIGHT]: {raw: 'PLUM_19', opacity: 1},
    [Themes.DARKER]: {raw: 'PLUM_17', opacity: 1},
    gradient: {light: {color: 'WHITE_500', opacity: 0.5}, dark: {color: 'BLACK_500', opacity: 0.5}},
  },
  BG_SURFACE_OVERLAY_TMP: {
    category: 'background',
    [Themes.DARK]: {raw: 'PRIMARY_800', opacity: 1},
    [Themes.LIGHT]: {raw: 'PRIMARY_130', opacity: 1},
    [Themes.MIDNIGHT]: {raw: 'PLUM_21', opacity: 1},
    [Themes.DARKER]: {raw: 'PLUM_17', opacity: 1},
    gradient: {light: {color: 'WHITE_500', opacity: 0.5}, dark: {color: 'BLACK_500', opacity: 0.5}},
  },
  BG_SURFACE_RAISED: {
    category: 'background',
    [Themes.DARK]: {raw: 'PRIMARY_600', opacity: 1},
    [Themes.LIGHT]: {raw: 'WHITE', opacity: 1},
    [Themes.MIDNIGHT]: {raw: 'PLUM_23', opacity: 1},
    [Themes.DARKER]: {raw: 'PLUM_18', opacity: 1},
    gradient: {light: {color: 'WHITE_500', opacity: 0.5}, dark: {color: 'BLACK_500', opacity: 0.5}},
  },
  BLACK: {
    category: 'generic',
    [Themes.DARK]: {raw: 'BLACK', opacity: 1},
    [Themes.LIGHT]: {raw: 'BLACK', opacity: 1},
    [Themes.MIDNIGHT]: {raw: 'BLACK', opacity: 1},
    [Themes.DARKER]: {raw: 'BLACK', opacity: 1},
  },
  BLUR_FALLBACK: {
    category: 'generic',
    [Themes.DARK]: {raw: 'PRIMARY_700', opacity: 0.96},
    [Themes.LIGHT]: {raw: 'PRIMARY_530', opacity: 0.96},
    [Themes.MIDNIGHT]: {raw: 'PLUM_19', opacity: 0.96},
    [Themes.DARKER]: {raw: 'PLUM_17', opacity: 0.96},
  },
  BLUR_FALLBACK_PRESSED: {
    category: 'generic',
    [Themes.DARK]: {raw: 'PRIMARY_730', opacity: 0.96},
    [Themes.LIGHT]: {raw: 'PRIMARY_560', opacity: 0.96},
    [Themes.MIDNIGHT]: {raw: 'PLUM_20', opacity: 0.96},
    [Themes.DARKER]: {raw: 'PLUM_18', opacity: 0.96},
  },
  BORDER_FAINT: {
    category: 'border',
    [Themes.DARK]: {raw: 'WHITE', opacity: 0.03},
    [Themes.LIGHT]: {raw: 'BLACK', opacity: 0.04},
    [Themes.MIDNIGHT]: {raw: 'PLUM_11', opacity: 0.06},
    [Themes.DARKER]: {raw: 'PLUM_11', opacity: 0.06},
  },
  BORDER_STRONG: {
    category: 'border',
    [Themes.DARK]: {raw: 'WHITE', opacity: 0.16},
    [Themes.LIGHT]: {raw: 'BLACK', opacity: 0.16},
    [Themes.MIDNIGHT]: {raw: 'PLUM_11', opacity: 0.24},
    [Themes.DARKER]: {raw: 'PLUM_11', opacity: 0.22},
  },
  BORDER_SUBTLE: {
    category: 'border',
    [Themes.DARK]: {raw: 'WHITE', opacity: 0.08},
    [Themes.LIGHT]: {raw: 'BLACK', opacity: 0.08},
    [Themes.MIDNIGHT]: {raw: 'PLUM_11', opacity: 0.12},
    [Themes.DARKER]: {raw: 'PLUM_11', opacity: 0.12},
  },
  BUG_REPORTER_MODAL_SUBMITTING_BACKGROUND: {
    category: 'generic',
    [Themes.DARK]: {raw: 'PRIMARY_800', opacity: 0.6},
    [Themes.LIGHT]: {raw: 'WHITE', opacity: 0.6},
    [Themes.MIDNIGHT]: {raw: 'PLUM_23', opacity: 0.6},
    [Themes.DARKER]: {raw: 'PLUM_23', opacity: 0.6},
  },
  BUTTON_CREATOR_REVENUE_BACKGROUND: {
    category: 'generic',
    [Themes.DARK]: {raw: 'TEAL_430', opacity: 1},
    [Themes.LIGHT]: {raw: 'TEAL_430', opacity: 1},
    [Themes.MIDNIGHT]: {raw: 'TEAL_430', opacity: 1},
    [Themes.DARKER]: {raw: 'TEAL_430', opacity: 1},
  },
  BUTTON_DANGER_BACKGROUND: {
    category: 'generic',
    [Themes.DARK]: {raw: 'RED_430', opacity: 1},
    [Themes.LIGHT]: {raw: 'RED_430', opacity: 1},
    [Themes.MIDNIGHT]: {raw: 'RED_430', opacity: 1},
    [Themes.DARKER]: {raw: 'RED_430', opacity: 1},
  },
  BUTTON_DANGER_BACKGROUND_ACTIVE: {
    category: 'generic',
    [Themes.DARK]: {raw: 'RED_530', opacity: 1},
    [Themes.LIGHT]: {raw: 'RED_530', opacity: 1},
    [Themes.MIDNIGHT]: {raw: 'RED_530', opacity: 1},
    [Themes.DARKER]: {raw: 'RED_530', opacity: 1},
  },
  BUTTON_DANGER_BACKGROUND_DISABLED: {
    category: 'generic',
    [Themes.DARK]: {raw: 'RED_430', opacity: 1},
    [Themes.LIGHT]: {raw: 'RED_430', opacity: 1},
    [Themes.MIDNIGHT]: {raw: 'RED_430', opacity: 1},
    [Themes.DARKER]: {raw: 'RED_430', opacity: 1},
  },
  BUTTON_DANGER_BACKGROUND_HOVER: {
    category: 'generic',
    [Themes.DARK]: {raw: 'RED_500', opacity: 1},
    [Themes.LIGHT]: {raw: 'RED_500', opacity: 1},
    [Themes.MIDNIGHT]: {raw: 'RED_500', opacity: 1},
    [Themes.DARKER]: {raw: 'RED_500', opacity: 1},
  },
  BUTTON_OUTLINE_BRAND_BACKGROUND: {
    category: 'generic',
    [Themes.DARK]: {raw: 'WHITE', opacity: 0},
    [Themes.LIGHT]: {raw: 'WHITE', opacity: 0},
    [Themes.MIDNIGHT]: {raw: 'WHITE', opacity: 0},
    [Themes.DARKER]: {raw: 'WHITE', opacity: 0},
  },
  BUTTON_OUTLINE_BRAND_BACKGROUND_ACTIVE: {
    category: 'generic',
    [Themes.DARK]: {raw: 'BRAND_560', opacity: 1},
    [Themes.LIGHT]: {raw: 'BRAND_560', opacity: 1},
    [Themes.MIDNIGHT]: {raw: 'BRAND_560', opacity: 1},
    [Themes.DARKER]: {raw: 'BRAND_560', opacity: 1},
  },
  BUTTON_OUTLINE_BRAND_BACKGROUND_HOVER: {
    category: 'generic',
    [Themes.DARK]: {raw: 'BRAND_500', opacity: 1},
    [Themes.LIGHT]: {raw: 'BRAND_500', opacity: 1},
    [Themes.MIDNIGHT]: {raw: 'BRAND_500', opacity: 1},
    [Themes.DARKER]: {raw: 'BRAND_500', opacity: 1},
  },
  BUTTON_OUTLINE_BRAND_BORDER: {
    category: 'generic',
    [Themes.DARK]: {raw: 'BRAND_500', opacity: 1},
    [Themes.LIGHT]: {raw: 'BRAND_500', opacity: 1},
    [Themes.MIDNIGHT]: {raw: 'BRAND_500', opacity: 1},
    [Themes.DARKER]: {raw: 'BRAND_500', opacity: 1},
  },
  BUTTON_OUTLINE_BRAND_BORDER_ACTIVE: {
    category: 'generic',
    [Themes.DARK]: {raw: 'BRAND_560', opacity: 1},
    [Themes.LIGHT]: {raw: 'BRAND_560', opacity: 1},
    [Themes.MIDNIGHT]: {raw: 'BRAND_560', opacity: 1},
    [Themes.DARKER]: {raw: 'BRAND_560', opacity: 1},
  },
  BUTTON_OUTLINE_BRAND_BORDER_HOVER: {
    category: 'generic',
    [Themes.DARK]: {raw: 'BRAND_500', opacity: 1},
    [Themes.LIGHT]: {raw: 'BRAND_500', opacity: 1},
    [Themes.MIDNIGHT]: {raw: 'BRAND_500', opacity: 1},
    [Themes.DARKER]: {raw: 'BRAND_500', opacity: 1},
  },
  BUTTON_OUTLINE_BRAND_TEXT: {
    category: 'text',
    [Themes.DARK]: {raw: 'WHITE', opacity: 1},
    [Themes.LIGHT]: {raw: 'BLACK', opacity: 1},
    [Themes.MIDNIGHT]: {raw: 'WHITE', opacity: 1},
    [Themes.DARKER]: {raw: 'WHITE', opacity: 1},
  },
  BUTTON_OUTLINE_BRAND_TEXT_ACTIVE: {
    category: 'generic',
    [Themes.DARK]: {raw: 'WHITE', opacity: 1},
    [Themes.LIGHT]: {raw: 'WHITE', opacity: 1},
    [Themes.MIDNIGHT]: {raw: 'WHITE', opacity: 1},
    [Themes.DARKER]: {raw: 'WHITE', opacity: 1},
  },
  BUTTON_OUTLINE_BRAND_TEXT_HOVER: {
    category: 'generic',
    [Themes.DARK]: {raw: 'WHITE', opacity: 1},
    [Themes.LIGHT]: {raw: 'WHITE', opacity: 1},
    [Themes.MIDNIGHT]: {raw: 'WHITE', opacity: 1},
    [Themes.DARKER]: {raw: 'WHITE', opacity: 1},
  },
  BUTTON_OUTLINE_DANGER_BACKGROUND: {
    category: 'generic',
    [Themes.DARK]: {raw: 'WHITE', opacity: 0},
    [Themes.LIGHT]: {raw: 'WHITE', opacity: 0},
    [Themes.MIDNIGHT]: {raw: 'WHITE', opacity: 0},
    [Themes.DARKER]: {raw: 'WHITE', opacity: 0},
  },
  BUTTON_OUTLINE_DANGER_BACKGROUND_ACTIVE: {
    category: 'generic',
    [Themes.DARK]: {raw: 'RED_460', opacity: 1},
    [Themes.LIGHT]: {raw: 'RED_460', opacity: 1},
    [Themes.MIDNIGHT]: {raw: 'RED_460', opacity: 1},
    [Themes.DARKER]: {raw: 'RED_460', opacity: 1},
  },
  BUTTON_OUTLINE_DANGER_BACKGROUND_HOVER: {
    category: 'generic',
    [Themes.DARK]: {raw: 'RED_430', opacity: 1},
    [Themes.LIGHT]: {raw: 'RED_430', opacity: 1},
    [Themes.MIDNIGHT]: {raw: 'RED_430', opacity: 1},
    [Themes.DARKER]: {raw: 'RED_430', opacity: 1},
  },
  BUTTON_OUTLINE_DANGER_BORDER: {
    category: 'generic',
    [Themes.DARK]: {raw: 'RED_400', opacity: 1},
    [Themes.LIGHT]: {raw: 'RED_430', opacity: 1},
    [Themes.MIDNIGHT]: {raw: 'RED_400', opacity: 1},
    [Themes.DARKER]: {raw: 'RED_400', opacity: 1},
  },
  BUTTON_OUTLINE_DANGER_BORDER_ACTIVE: {
    category: 'generic',
    [Themes.DARK]: {raw: 'RED_430', opacity: 1},
    [Themes.LIGHT]: {raw: 'RED_430', opacity: 1},
    [Themes.MIDNIGHT]: {raw: 'RED_430', opacity: 1},
    [Themes.DARKER]: {raw: 'RED_430', opacity: 1},
  },
  BUTTON_OUTLINE_DANGER_BORDER_HOVER: {
    category: 'generic',
    [Themes.DARK]: {raw: 'RED_430', opacity: 1},
    [Themes.LIGHT]: {raw: 'RED_430', opacity: 1},
    [Themes.MIDNIGHT]: {raw: 'RED_430', opacity: 1},
    [Themes.DARKER]: {raw: 'RED_430', opacity: 1},
  },
  BUTTON_OUTLINE_DANGER_TEXT: {
    category: 'text',
    [Themes.DARK]: {raw: 'WHITE', opacity: 1},
    [Themes.LIGHT]: {raw: 'BLACK', opacity: 1},
    [Themes.MIDNIGHT]: {raw: 'WHITE', opacity: 1},
    [Themes.DARKER]: {raw: 'WHITE', opacity: 1},
  },
  BUTTON_OUTLINE_DANGER_TEXT_ACTIVE: {
    category: 'generic',
    [Themes.DARK]: {raw: 'WHITE', opacity: 1},
    [Themes.LIGHT]: {raw: 'WHITE', opacity: 1},
    [Themes.MIDNIGHT]: {raw: 'WHITE', opacity: 1},
    [Themes.DARKER]: {raw: 'WHITE', opacity: 1},
  },
  BUTTON_OUTLINE_DANGER_TEXT_HOVER: {
    category: 'generic',
    [Themes.DARK]: {raw: 'WHITE', opacity: 1},
    [Themes.LIGHT]: {raw: 'WHITE', opacity: 1},
    [Themes.MIDNIGHT]: {raw: 'WHITE', opacity: 1},
    [Themes.DARKER]: {raw: 'WHITE', opacity: 1},
  },
  BUTTON_OUTLINE_POSITIVE_BACKGROUND: {
    category: 'generic',
    [Themes.DARK]: {raw: 'WHITE', opacity: 0},
    [Themes.LIGHT]: {raw: 'WHITE', opacity: 0},
    [Themes.MIDNIGHT]: {raw: 'WHITE', opacity: 0},
    [Themes.DARKER]: {raw: 'WHITE', opacity: 0},
  },
  BUTTON_OUTLINE_POSITIVE_BACKGROUND_ACTIVE: {
    category: 'generic',
    [Themes.DARK]: {raw: 'GREEN_530', opacity: 1},
    [Themes.LIGHT]: {raw: 'GREEN_530', opacity: 1},
    [Themes.MIDNIGHT]: {raw: 'GREEN_530', opacity: 1},
    [Themes.DARKER]: {raw: 'GREEN_530', opacity: 1},
  },
  BUTTON_OUTLINE_POSITIVE_BACKGROUND_HOVER: {
    category: 'generic',
    [Themes.DARK]: {raw: 'GREEN_430', opacity: 1},
    [Themes.LIGHT]: {raw: 'GREEN_430', opacity: 1},
    [Themes.MIDNIGHT]: {raw: 'GREEN_430', opacity: 1},
    [Themes.DARKER]: {raw: 'GREEN_430', opacity: 1},
  },
  BUTTON_OUTLINE_POSITIVE_BORDER: {
    category: 'generic',
    [Themes.DARK]: {raw: 'GREEN_360', opacity: 1},
    [Themes.LIGHT]: {raw: 'GREEN_400', opacity: 1},
    [Themes.MIDNIGHT]: {raw: 'GREEN_360', opacity: 1},
    [Themes.DARKER]: {raw: 'GREEN_360', opacity: 1},
  },
  BUTTON_OUTLINE_POSITIVE_BORDER_ACTIVE: {
    category: 'generic',
    [Themes.DARK]: {raw: 'GREEN_530', opacity: 1},
    [Themes.LIGHT]: {raw: 'GREEN_530', opacity: 1},
    [Themes.MIDNIGHT]: {raw: 'GREEN_530', opacity: 1},
    [Themes.DARKER]: {raw: 'GREEN_530', opacity: 1},
  },
  BUTTON_OUTLINE_POSITIVE_BORDER_HOVER: {
    category: 'generic',
    [Themes.DARK]: {raw: 'GREEN_430', opacity: 1},
    [Themes.LIGHT]: {raw: 'GREEN_430', opacity: 1},
    [Themes.MIDNIGHT]: {raw: 'GREEN_430', opacity: 1},
    [Themes.DARKER]: {raw: 'GREEN_430', opacity: 1},
  },
  BUTTON_OUTLINE_POSITIVE_TEXT: {
    category: 'text',
    [Themes.DARK]: {raw: 'WHITE', opacity: 1},
    [Themes.LIGHT]: {raw: 'BLACK', opacity: 1},
    [Themes.MIDNIGHT]: {raw: 'WHITE', opacity: 1},
    [Themes.DARKER]: {raw: 'WHITE', opacity: 1},
  },
  BUTTON_OUTLINE_POSITIVE_TEXT_ACTIVE: {
    category: 'generic',
    [Themes.DARK]: {raw: 'WHITE', opacity: 1},
    [Themes.LIGHT]: {raw: 'WHITE', opacity: 1},
    [Themes.MIDNIGHT]: {raw: 'WHITE', opacity: 1},
    [Themes.DARKER]: {raw: 'WHITE', opacity: 1},
  },
  BUTTON_OUTLINE_POSITIVE_TEXT_HOVER: {
    category: 'generic',
    [Themes.DARK]: {raw: 'WHITE', opacity: 1},
    [Themes.LIGHT]: {raw: 'WHITE', opacity: 1},
    [Themes.MIDNIGHT]: {raw: 'WHITE', opacity: 1},
    [Themes.DARKER]: {raw: 'WHITE', opacity: 1},
  },
  BUTTON_OUTLINE_PRIMARY_BACKGROUND: {
    category: 'generic',
    [Themes.DARK]: {raw: 'WHITE', opacity: 0},
    [Themes.LIGHT]: {raw: 'WHITE', opacity: 0},
    [Themes.MIDNIGHT]: {raw: 'WHITE', opacity: 0},
    [Themes.DARKER]: {raw: 'WHITE', opacity: 0},
  },
  BUTTON_OUTLINE_PRIMARY_BACKGROUND_ACTIVE: {
    category: 'generic',
    [Themes.DARK]: {raw: 'PRIMARY_430', opacity: 1},
    [Themes.LIGHT]: {raw: 'PRIMARY_600', opacity: 1},
    [Themes.MIDNIGHT]: {raw: 'PLUM_11', opacity: 1},
    [Themes.DARKER]: {raw: 'PLUM_11', opacity: 1},
  },
  BUTTON_OUTLINE_PRIMARY_BACKGROUND_HOVER: {
    category: 'generic',
    [Themes.DARK]: {raw: 'PRIMARY_500', opacity: 1},
    [Themes.LIGHT]: {raw: 'PRIMARY_530', opacity: 1},
    [Themes.MIDNIGHT]: {raw: 'PLUM_13', opacity: 1},
    [Themes.DARKER]: {raw: 'PLUM_13', opacity: 1},
  },
  BUTTON_OUTLINE_PRIMARY_BORDER: {
    category: 'generic',
    [Themes.DARK]: {raw: 'PRIMARY_500', opacity: 1},
    [Themes.LIGHT]: {raw: 'PRIMARY_530', opacity: 1},
    [Themes.MIDNIGHT]: {raw: 'PRIMARY_500', opacity: 1},
    [Themes.DARKER]: {raw: 'PRIMARY_500', opacity: 1},
  },
  BUTTON_OUTLINE_PRIMARY_BORDER_ACTIVE: {
    category: 'generic',
    [Themes.DARK]: {raw: 'PRIMARY_430', opacity: 1},
    [Themes.LIGHT]: {raw: 'PRIMARY_600', opacity: 1},
    [Themes.MIDNIGHT]: {raw: 'PLUM_11', opacity: 1},
    [Themes.DARKER]: {raw: 'PLUM_11', opacity: 1},
  },
  BUTTON_OUTLINE_PRIMARY_BORDER_HOVER: {
    category: 'generic',
    [Themes.DARK]: {raw: 'PRIMARY_500', opacity: 1},
    [Themes.LIGHT]: {raw: 'PRIMARY_530', opacity: 1},
    [Themes.MIDNIGHT]: {raw: 'PLUM_13', opacity: 1},
    [Themes.DARKER]: {raw: 'PLUM_13', opacity: 1},
  },
  BUTTON_OUTLINE_PRIMARY_TEXT: {
    category: 'text',
    [Themes.DARK]: {raw: 'WHITE', opacity: 1},
    [Themes.LIGHT]: {raw: 'BLACK', opacity: 1},
    [Themes.MIDNIGHT]: {raw: 'WHITE', opacity: 1},
    [Themes.DARKER]: {raw: 'WHITE', opacity: 1},
  },
  BUTTON_OUTLINE_PRIMARY_TEXT_ACTIVE: {
    category: 'generic',
    [Themes.DARK]: {raw: 'WHITE', opacity: 1},
    [Themes.LIGHT]: {raw: 'WHITE', opacity: 1},
    [Themes.MIDNIGHT]: {raw: 'WHITE', opacity: 1},
    [Themes.DARKER]: {raw: 'WHITE', opacity: 1},
  },
  BUTTON_OUTLINE_PRIMARY_TEXT_HOVER: {
    category: 'generic',
    [Themes.DARK]: {raw: 'WHITE', opacity: 1},
    [Themes.LIGHT]: {raw: 'WHITE', opacity: 1},
    [Themes.MIDNIGHT]: {raw: 'WHITE', opacity: 1},
    [Themes.DARKER]: {raw: 'WHITE', opacity: 1},
  },
  BUTTON_POSITIVE_BACKGROUND: {
    category: 'generic',
    [Themes.DARK]: {raw: 'GREEN_430', opacity: 1},
    [Themes.LIGHT]: {raw: 'GREEN_430', opacity: 1},
    [Themes.MIDNIGHT]: {raw: 'GREEN_430', opacity: 1},
    [Themes.DARKER]: {raw: 'GREEN_430', opacity: 1},
  },
  BUTTON_POSITIVE_BACKGROUND_ACTIVE: {
    category: 'generic',
    [Themes.DARK]: {raw: 'GREEN_530', opacity: 1},
    [Themes.LIGHT]: {raw: 'GREEN_530', opacity: 1},
    [Themes.MIDNIGHT]: {raw: 'GREEN_530', opacity: 1},
    [Themes.DARKER]: {raw: 'GREEN_530', opacity: 1},
  },
  BUTTON_POSITIVE_BACKGROUND_DISABLED: {
    category: 'generic',
    [Themes.DARK]: {raw: 'GREEN_430', opacity: 1},
    [Themes.LIGHT]: {raw: 'GREEN_430', opacity: 1},
    [Themes.MIDNIGHT]: {raw: 'GREEN_430', opacity: 1},
    [Themes.DARKER]: {raw: 'GREEN_430', opacity: 1},
  },
  BUTTON_POSITIVE_BACKGROUND_HOVER: {
    category: 'generic',
    [Themes.DARK]: {raw: 'GREEN_500', opacity: 1},
    [Themes.LIGHT]: {raw: 'GREEN_530', opacity: 1},
    [Themes.MIDNIGHT]: {raw: 'GREEN_530', opacity: 1},
    [Themes.DARKER]: {raw: 'GREEN_500', opacity: 1},
  },
  BUTTON_SECONDARY_BACKGROUND: {
    category: 'generic',
    [Themes.DARK]: {raw: 'PRIMARY_500', opacity: 1},
    [Themes.LIGHT]: {raw: 'PRIMARY_430', opacity: 1},
    [Themes.MIDNIGHT]: {raw: 'PLUM_13', opacity: 1},
    [Themes.DARKER]: {raw: 'PLUM_13', opacity: 1},
  },
  BUTTON_SECONDARY_BACKGROUND_ACTIVE: {
    category: 'generic',
    [Themes.DARK]: {raw: 'PRIMARY_400', opacity: 1},
    [Themes.LIGHT]: {raw: 'PRIMARY_530', opacity: 1},
    [Themes.MIDNIGHT]: {raw: 'PLUM_10', opacity: 1},
    [Themes.DARKER]: {raw: 'PLUM_10', opacity: 1},
  },
  BUTTON_SECONDARY_BACKGROUND_DISABLED: {
    category: 'generic',
    [Themes.DARK]: {raw: 'PRIMARY_500', opacity: 1},
    [Themes.LIGHT]: {raw: 'PRIMARY_430', opacity: 1},
    [Themes.MIDNIGHT]: {raw: 'PLUM_13', opacity: 1},
    [Themes.DARKER]: {raw: 'PLUM_13', opacity: 1},
  },
  BUTTON_SECONDARY_BACKGROUND_HOVER: {
    category: 'generic',
    [Themes.DARK]: {raw: 'PRIMARY_430', opacity: 1},
    [Themes.LIGHT]: {raw: 'PRIMARY_500', opacity: 1},
    [Themes.MIDNIGHT]: {raw: 'PLUM_11', opacity: 1},
    [Themes.DARKER]: {raw: 'PLUM_11', opacity: 1},
  },
  CARD_GRADIENT_BG: {
    category: 'background',
    [Themes.DARK]: {raw: 'BLACK', opacity: 0.4},
    [Themes.LIGHT]: {raw: 'WHITE', opacity: 0.7},
    [Themes.MIDNIGHT]: {raw: 'BLACK', opacity: 0.4},
    [Themes.DARKER]: {raw: 'BLACK', opacity: 0.4},
  },
  CARD_GRADIENT_PRESSED_BG: {
    category: 'background',
    [Themes.DARK]: {raw: 'BLACK', opacity: 0.5},
    [Themes.LIGHT]: {raw: 'WHITE', opacity: 0.5},
    [Themes.MIDNIGHT]: {raw: 'BLACK', opacity: 0.5},
    [Themes.DARKER]: {raw: 'BLACK', opacity: 0.5},
  },
  CARD_PRIMARY_BG: {
    category: 'background',
    [Themes.DARK]: {raw: 'PRIMARY_600', opacity: 1},
    [Themes.LIGHT]: {raw: 'WHITE', opacity: 1},
    [Themes.MIDNIGHT]: {raw: 'PLUM_23', opacity: 1},
    [Themes.DARKER]: {raw: 'PLUM_18', opacity: 1},
    gradient: {light: {color: 'WHITE_500', opacity: 0.75}, dark: {color: 'WHITE_500', opacity: 0.1}},
  },
  CARD_PRIMARY_PRESSED_BG: {
    category: 'background',
    [Themes.DARK]: {raw: 'PRIMARY_645', opacity: 1},
    [Themes.LIGHT]: {raw: 'PRIMARY_160', opacity: 1},
    [Themes.MIDNIGHT]: {raw: 'PLUM_25', opacity: 1},
    [Themes.DARKER]: {raw: 'PLUM_19', opacity: 1},
    gradient: {light: {color: 'WHITE_500', opacity: 0.075}, dark: {color: 'WHITE_500', opacity: 0.05}},
  },
  CARD_SECONDARY_BG: {
    category: 'background',
    [Themes.DARK]: {raw: 'PRIMARY_630', opacity: 1},
    [Themes.LIGHT]: {raw: 'WHITE', opacity: 1},
    [Themes.MIDNIGHT]: {raw: 'PLUM_25', opacity: 1},
    [Themes.DARKER]: {raw: 'PLUM_19', opacity: 1},
    gradient: {light: {color: 'WHITE_500', opacity: 0.4}, dark: {color: 'WHITE_500', opacity: 0.1}},
  },
  CARD_SECONDARY_PRESSED_BG: {
    category: 'background',
    [Themes.DARK]: {raw: 'PRIMARY_645', opacity: 1},
    [Themes.LIGHT]: {raw: 'PRIMARY_160', opacity: 1},
    [Themes.MIDNIGHT]: {raw: 'PLUM_26', opacity: 1},
    [Themes.DARKER]: {raw: 'PLUM_21', opacity: 1},
    gradient: {light: {color: 'WHITE_500', opacity: 0.75}, dark: {color: 'WHITE_500', opacity: 0.05}},
  },
  CHANNEL_ICON: {
    category: 'generic',
    [Themes.DARK]: {raw: 'PRIMARY_400', opacity: 1},
    [Themes.LIGHT]: {raw: 'PRIMARY_430', opacity: 1},
    [Themes.MIDNIGHT]: {raw: 'PLUM_10', opacity: 1},
    [Themes.DARKER]: {raw: 'PLUM_10', opacity: 1},
  },
  CHANNEL_TEXT_AREA_PLACEHOLDER: {
    category: 'generic',
    [Themes.DARK]: {raw: 'PRIMARY_430', opacity: 1},
    [Themes.LIGHT]: {raw: 'PRIMARY_400', opacity: 1},
    [Themes.MIDNIGHT]: {raw: 'PLUM_11', opacity: 1},
    [Themes.DARKER]: {raw: 'PLUM_11', opacity: 1},
  },
  CHANNELS_DEFAULT: {
    category: 'generic',
    [Themes.DARK]: {raw: 'PRIMARY_360', opacity: 1},
    [Themes.LIGHT]: {raw: 'PRIMARY_460', opacity: 1},
    [Themes.MIDNIGHT]: {raw: 'PLUM_9', opacity: 1},
    [Themes.DARKER]: {raw: 'PLUM_9', opacity: 1},
  },
  CHANNELTEXTAREA_BACKGROUND: {
    category: 'generic',
    [Themes.DARK]: {raw: 'PRIMARY_560', opacity: 1},
    [Themes.LIGHT]: {raw: 'PRIMARY_160', opacity: 1},
    [Themes.MIDNIGHT]: {raw: 'PLUM_23', opacity: 1},
    [Themes.DARKER]: {raw: 'PLUM_15', opacity: 1},
  },
  CHAT_BACKGROUND: {
    category: 'generic',
    [Themes.DARK]: {raw: 'PRIMARY_600', opacity: 1},
    [Themes.LIGHT]: {raw: 'WHITE', opacity: 1},
    [Themes.MIDNIGHT]: {raw: 'BLACK', opacity: 1},
    [Themes.DARKER]: {raw: 'PLUM_16', opacity: 1},
  },
  CHAT_BANNER_BG: {
    category: 'background',
    [Themes.DARK]: {raw: 'PRIMARY_630', opacity: 1},
    [Themes.LIGHT]: {raw: 'PRIMARY_130', opacity: 1},
    [Themes.MIDNIGHT]: {raw: 'BLACK', opacity: 1},
    [Themes.DARKER]: {raw: 'PLUM_20', opacity: 1},
    gradient: {light: {color: 'gradient.start'}, dark: {color: 'gradient.start', opacity: 1}},
  },
  CHAT_BORDER: {
    category: 'generic',
    [Themes.DARK]: {raw: 'PRIMARY_700', opacity: 1},
    [Themes.LIGHT]: {raw: 'PRIMARY_200', opacity: 1},
    [Themes.MIDNIGHT]: {raw: 'PLUM_20', opacity: 1},
    [Themes.DARKER]: {raw: 'PLUM_20', opacity: 1},
  },
  CHAT_INPUT_CONTAINER_BACKGROUND: {
    category: 'generic',
    [Themes.DARK]: {raw: 'PRIMARY_600', opacity: 1},
    [Themes.LIGHT]: {raw: 'WHITE', opacity: 1},
    [Themes.MIDNIGHT]: {raw: 'PLUM_16', opacity: 1},
    [Themes.DARKER]: {raw: 'PLUM_16', opacity: 1},
  },
  CHAT_SWIPE_TO_REPLY_BACKGROUND: {
    category: 'generic',
    [Themes.DARK]: {raw: 'PRIMARY_660', opacity: 1},
    [Themes.LIGHT]: {raw: 'PRIMARY_160', opacity: 1},
    [Themes.MIDNIGHT]: {raw: 'PLUM_22', opacity: 1},
    [Themes.DARKER]: {raw: 'PLUM_22', opacity: 1},
  },
  CHAT_SWIPE_TO_REPLY_GRADIENT_BACKGROUND: {
    category: 'generic',
    [Themes.DARK]: {raw: 'BLACK', opacity: 0.1},
    [Themes.LIGHT]: {raw: 'WHITE', opacity: 0.4},
    [Themes.MIDNIGHT]: {raw: 'BLACK', opacity: 0.1},
    [Themes.DARKER]: {raw: 'BLACK', opacity: 0.1},
  },
  COACHMARK_BG: {
    category: 'background',
    [Themes.DARK]: {raw: 'PRIMARY_800', opacity: 1},
    [Themes.LIGHT]: {raw: 'WHITE', opacity: 1},
    [Themes.MIDNIGHT]: {raw: 'PLUM_19', opacity: 1},
    [Themes.DARKER]: {raw: 'PLUM_17', opacity: 1},
  },
  CONTENT_INVENTORY_MEDIA_SEEKBAR_CONTAINER: {
    category: 'generic',
    [Themes.DARK]: {raw: 'PLUM_6', opacity: 0.24},
    [Themes.LIGHT]: {raw: 'PLUM_6', opacity: 0.24},
    [Themes.MIDNIGHT]: {raw: 'PLUM_6', opacity: 0.24},
    [Themes.DARKER]: {raw: 'PLUM_6', opacity: 0.24},
  },
  CONTENT_INVENTORY_OVERLAY_TEXT_PRIMARY: {
    category: 'generic',
    [Themes.DARK]: {raw: 'WHITE', opacity: 0.85},
    [Themes.LIGHT]: {raw: 'WHITE', opacity: 0.85},
    [Themes.MIDNIGHT]: {raw: 'WHITE', opacity: 0.85},
    [Themes.DARKER]: {raw: 'WHITE', opacity: 0.85},
  },
  CONTENT_INVENTORY_OVERLAY_TEXT_SECONDARY: {
    category: 'generic',
    [Themes.DARK]: {raw: 'WHITE', opacity: 0.7},
    [Themes.LIGHT]: {raw: 'WHITE', opacity: 0.7},
    [Themes.MIDNIGHT]: {raw: 'WHITE', opacity: 0.7},
    [Themes.DARKER]: {raw: 'WHITE', opacity: 0.7},
  },
  CONTENT_INVENTORY_OVERLAY_UI_MOD: {
    category: 'generic',
    [Themes.DARK]: {raw: 'WHITE', opacity: 0.7},
    [Themes.LIGHT]: {raw: 'WHITE', opacity: 0.7},
    [Themes.MIDNIGHT]: {raw: 'WHITE', opacity: 0.7},
    [Themes.DARKER]: {raw: 'WHITE', opacity: 0.7},
  },
  CONTENT_INVENTORY_OVERLAY_UI_MOD_BG: {
    category: 'background',
    [Themes.DARK]: {raw: 'BLACK', opacity: 0.1},
    [Themes.LIGHT]: {raw: 'BLACK', opacity: 0.1},
    [Themes.MIDNIGHT]: {raw: 'BLACK', opacity: 0.1},
    [Themes.DARKER]: {raw: 'BLACK', opacity: 0.1},
  },
  CONTEXT_MENU_BACKDROP_BACKGROUND: {
    category: 'generic',
    [Themes.DARK]: {raw: 'BLACK', opacity: 0.7},
    [Themes.LIGHT]: {raw: 'BLACK', opacity: 0.54},
    [Themes.MIDNIGHT]: {raw: 'BLACK', opacity: 0.7},
    [Themes.DARKER]: {raw: 'BLACK', opacity: 0.7},
  },
  CONTROL_BRAND_FOREGROUND: {
    category: 'generic',
    [Themes.DARK]: {raw: 'BRAND_360', opacity: 1},
    [Themes.LIGHT]: {raw: 'BRAND_500', opacity: 1},
    [Themes.MIDNIGHT]: {raw: 'BRAND_360', opacity: 1},
    [Themes.DARKER]: {raw: 'BRAND_360', opacity: 1},
  },
  CONTROL_BRAND_FOREGROUND_NEW: {
    category: 'generic',
    [Themes.DARK]: {raw: 'BRAND_360', opacity: 1},
    [Themes.LIGHT]: {raw: 'BRAND_500', opacity: 1},
    [Themes.MIDNIGHT]: {raw: 'BRAND_360', opacity: 1},
    [Themes.DARKER]: {raw: 'BRAND_360', opacity: 1},
  },
  CREATOR_REVENUE_ICON_GRADIENT_END: {
    category: 'generic',
    [Themes.DARK]: {raw: 'TEAL_430', opacity: 1},
    [Themes.LIGHT]: {raw: 'TEAL_400', opacity: 1},
    [Themes.MIDNIGHT]: {raw: 'TEAL_430', opacity: 1},
    [Themes.DARKER]: {raw: 'TEAL_430', opacity: 1},
  },
  CREATOR_REVENUE_ICON_GRADIENT_START: {
    category: 'generic',
    [Themes.DARK]: {raw: 'TEAL_360', opacity: 1},
    [Themes.LIGHT]: {raw: 'TEAL_345', opacity: 1},
    [Themes.MIDNIGHT]: {raw: 'TEAL_360', opacity: 1},
    [Themes.DARKER]: {raw: 'TEAL_360', opacity: 1},
  },
  CREATOR_REVENUE_INFO_BOX_BACKGROUND: {
    category: 'generic',
    [Themes.DARK]: {raw: 'TEAL_430', opacity: 0.1},
    [Themes.LIGHT]: {raw: 'TEAL_430', opacity: 0.1},
    [Themes.MIDNIGHT]: {raw: 'TEAL_430', opacity: 0.1},
    [Themes.DARKER]: {raw: 'TEAL_430', opacity: 0.1},
  },
  CREATOR_REVENUE_INFO_BOX_BORDER: {
    category: 'generic',
    [Themes.DARK]: {raw: 'TEAL_400', opacity: 1},
    [Themes.LIGHT]: {raw: 'TEAL_400', opacity: 1},
    [Themes.MIDNIGHT]: {raw: 'TEAL_400', opacity: 1},
    [Themes.DARKER]: {raw: 'TEAL_400', opacity: 1},
  },
  CREATOR_REVENUE_LOCKED_CHANNEL_ICON: {
    category: 'generic',
    [Themes.DARK]: {raw: 'TEAL_345', opacity: 1},
    [Themes.LIGHT]: {raw: 'TEAL_400', opacity: 1},
    [Themes.MIDNIGHT]: {raw: 'TEAL_345', opacity: 1},
    [Themes.DARKER]: {raw: 'TEAL_345', opacity: 1},
  },
  CREATOR_REVENUE_PROGRESS_BAR: {
    category: 'generic',
    [Themes.DARK]: {raw: 'TEAL_400', opacity: 1},
    [Themes.LIGHT]: {raw: 'TEAL_345', opacity: 1},
    [Themes.MIDNIGHT]: {raw: 'TEAL_400', opacity: 1},
    [Themes.DARKER]: {raw: 'TEAL_400', opacity: 1},
  },
  DEPRECATED_CARD_BG: {
    category: 'background',
    [Themes.DARK]: {raw: 'PRIMARY_700', opacity: 0.6},
    [Themes.LIGHT]: {raw: 'PRIMARY_100', opacity: 1},
    [Themes.MIDNIGHT]: {raw: 'PRIMARY_700', opacity: 0.6},
    [Themes.DARKER]: {raw: 'PRIMARY_700', opacity: 0.6},
  },
  DEPRECATED_CARD_EDITABLE_BG: {
    category: 'background',
    [Themes.DARK]: {raw: 'PRIMARY_700', opacity: 0.3},
    [Themes.LIGHT]: {raw: 'PRIMARY_100', opacity: 0.6},
    [Themes.MIDNIGHT]: {raw: 'PRIMARY_700', opacity: 0.3},
    [Themes.DARKER]: {raw: 'PRIMARY_700', opacity: 0.3},
  },
  DEPRECATED_QUICKSWITCHER_INPUT_BACKGROUND: {
    category: 'generic',
    [Themes.DARK]: {raw: 'PRIMARY_400', opacity: 1},
    [Themes.LIGHT]: {raw: 'WHITE', opacity: 1},
    [Themes.MIDNIGHT]: {raw: 'PRIMARY_400', opacity: 1},
    [Themes.DARKER]: {raw: 'PRIMARY_400', opacity: 1},
  },
  DEPRECATED_QUICKSWITCHER_INPUT_PLACEHOLDER: {
    category: 'generic',
    [Themes.DARK]: {raw: 'WHITE', opacity: 0.3},
    [Themes.LIGHT]: {raw: 'PRIMARY_500', opacity: 0.3},
    [Themes.MIDNIGHT]: {raw: 'WHITE', opacity: 0.3},
    [Themes.DARKER]: {raw: 'WHITE', opacity: 0.3},
  },
  DEPRECATED_STORE_BG: {
    category: 'background',
    [Themes.DARK]: {raw: 'PRIMARY_600', opacity: 1},
    [Themes.LIGHT]: {raw: 'PRIMARY_100', opacity: 1},
    [Themes.MIDNIGHT]: {raw: 'PRIMARY_600', opacity: 1},
    [Themes.DARKER]: {raw: 'PRIMARY_600', opacity: 1},
  },
  DEPRECATED_TEXT_INPUT_BG: {
    category: 'background',
    [Themes.DARK]: {raw: 'PRIMARY_700', opacity: 1},
    [Themes.LIGHT]: {raw: 'PRIMARY_200', opacity: 1},
    [Themes.MIDNIGHT]: {raw: 'PRIMARY_700', opacity: 1},
    [Themes.DARKER]: {raw: 'PRIMARY_700', opacity: 1},
  },
  DEPRECATED_TEXT_INPUT_BORDER: {
    category: 'generic',
    [Themes.DARK]: {raw: 'BLACK', opacity: 0.3},
    [Themes.LIGHT]: {raw: 'PRIMARY_500', opacity: 0.3},
    [Themes.MIDNIGHT]: {raw: 'BLACK', opacity: 0.3},
    [Themes.DARKER]: {raw: 'BLACK', opacity: 0.3},
  },
  DEPRECATED_TEXT_INPUT_BORDER_DISABLED: {
    category: 'generic',
    [Themes.DARK]: {raw: 'PRIMARY_700', opacity: 1},
    [Themes.LIGHT]: {raw: 'PRIMARY_200', opacity: 1},
    [Themes.MIDNIGHT]: {raw: 'PRIMARY_700', opacity: 1},
    [Themes.DARKER]: {raw: 'PRIMARY_700', opacity: 1},
  },
  DEPRECATED_TEXT_INPUT_BORDER_HOVER: {
    category: 'generic',
    [Themes.DARK]: {raw: 'PRIMARY_900', opacity: 1},
    [Themes.LIGHT]: {raw: 'PRIMARY_300', opacity: 1},
    [Themes.MIDNIGHT]: {raw: 'PRIMARY_900', opacity: 1},
    [Themes.DARKER]: {raw: 'PRIMARY_900', opacity: 1},
  },
  DEPRECATED_TEXT_INPUT_PREFIX: {
    category: 'generic',
    [Themes.DARK]: {raw: 'PRIMARY_200', opacity: 1},
    [Themes.LIGHT]: {raw: 'PRIMARY_300', opacity: 1},
    [Themes.MIDNIGHT]: {raw: 'PRIMARY_200', opacity: 1},
    [Themes.DARKER]: {raw: 'PRIMARY_200', opacity: 1},
  },
  DISPLAY_BANNER_OVERFLOW_BACKGROUND: {
    category: 'generic',
    [Themes.DARK]: {raw: 'PRIMARY_700', opacity: 0.5},
    [Themes.LIGHT]: {raw: 'WHITE', opacity: 0.5},
    [Themes.MIDNIGHT]: {raw: 'PLUM_20', opacity: 0.5},
    [Themes.DARKER]: {raw: 'PLUM_20', opacity: 0.5},
  },
  DIVIDER_STRONG: {
    category: 'border',
    [Themes.DARK]: {raw: 'WHITE', opacity: 0.16},
    [Themes.LIGHT]: {raw: 'BLACK', opacity: 0.16},
    [Themes.MIDNIGHT]: {raw: 'PLUM_11', opacity: 0.24},
    [Themes.DARKER]: {raw: 'PLUM_11', opacity: 0.22},
  },
  DIVIDER_SUBTLE: {
    category: 'border',
    [Themes.DARK]: {raw: 'WHITE', opacity: 0.08},
    [Themes.LIGHT]: {raw: 'BLACK', opacity: 0.08},
    [Themes.MIDNIGHT]: {raw: 'PLUM_11', opacity: 0.12},
    [Themes.DARKER]: {raw: 'PLUM_11', opacity: 0.12},
  },
  EMBED_BACKGROUND: {
    category: 'generic',
    [Themes.DARK]: {raw: 'PRIMARY_630', opacity: 1},
    [Themes.LIGHT]: {raw: 'PRIMARY_130', opacity: 1},
    [Themes.MIDNIGHT]: {raw: 'PLUM_23', opacity: 1},
    [Themes.DARKER]: {raw: 'PLUM_18', opacity: 1},
    gradient: {light: {color: 'WHITE_500', opacity: 0.25}, dark: {color: 'WHITE_500', opacity: 0.1}},
  },
  EMBED_BACKGROUND_ALTERNATE: {
    category: 'generic',
    [Themes.DARK]: {raw: 'PRIMARY_600', opacity: 1},
    [Themes.LIGHT]: {raw: 'PRIMARY_200', opacity: 1},
    [Themes.MIDNIGHT]: {raw: 'PRIMARY_600', opacity: 1},
    [Themes.DARKER]: {raw: 'PRIMARY_600', opacity: 1},
    gradient: {light: {color: 'WHITE_500', opacity: 0.25}, dark: {color: 'WHITE_500', opacity: 0.075}},
  },
  EMBED_TITLE: {
    category: 'generic',
    [Themes.DARK]: {raw: 'PRIMARY_130', opacity: 1},
    [Themes.LIGHT]: {raw: 'PRIMARY_860', opacity: 1},
    [Themes.MIDNIGHT]: {raw: 'PLUM_2', opacity: 1},
    [Themes.DARKER]: {raw: 'PLUM_3', opacity: 1},
  },
  EXPRESSION_PICKER_BG: {
    category: 'background',
    [Themes.DARK]: {raw: 'PRIMARY_630', opacity: 1},
    [Themes.LIGHT]: {raw: 'PRIMARY_130', opacity: 1},
    [Themes.MIDNIGHT]: {raw: 'BLACK', opacity: 1},
    [Themes.DARKER]: {raw: 'PLUM_20', opacity: 1},
    gradient: {light: {color: 'gradient.end', lightness: '*1.05'}, dark: {color: 'gradient.end', lightness: '*0.95'}},
  },
  FOCUS_PRIMARY: {
    category: 'generic',
    [Themes.DARK]: {raw: 'BLUE_345', opacity: 1},
    [Themes.LIGHT]: {raw: 'BLUE_360', opacity: 1},
    [Themes.MIDNIGHT]: {raw: 'BLUE_345', opacity: 1},
    [Themes.DARKER]: {raw: 'BLUE_345', opacity: 1},
  },
  FORUM_POST_EXTRA_MEDIA_COUNT_CONTAINER_BACKGROUND: {
    category: 'generic',
    [Themes.DARK]: {raw: 'PRIMARY_660', opacity: 0.8},
    [Themes.LIGHT]: {raw: 'PRIMARY_160', opacity: 0.8},
    [Themes.MIDNIGHT]: {raw: 'PLUM_19', opacity: 0.8},
    [Themes.DARKER]: {raw: 'PLUM_19', opacity: 0.8},
  },
  FORUM_POST_TAG_BACKGROUND: {
    category: 'generic',
    [Themes.DARK]: {raw: 'PRIMARY_660', opacity: 0.9},
    [Themes.LIGHT]: {raw: 'PRIMARY_160', opacity: 0.9},
    [Themes.MIDNIGHT]: {raw: 'PLUM_19', opacity: 0.9},
    [Themes.DARKER]: {raw: 'PLUM_19', opacity: 0.9},
  },
  GUILD_ICON_INACTIVE_BG: {
    category: 'background',
    [Themes.DARK]: {raw: 'PRIMARY_630', opacity: 1},
    [Themes.LIGHT]: {raw: 'PRIMARY_130', opacity: 1},
    [Themes.MIDNIGHT]: {raw: 'BLACK', opacity: 1},
    [Themes.DARKER]: {raw: 'PLUM_20', opacity: 1},
  },
  GUILD_ICON_INACTIVE_NESTED_BG: {
    category: 'background',
    [Themes.DARK]: {raw: 'PRIMARY_600', opacity: 1},
    [Themes.LIGHT]: {raw: 'WHITE', opacity: 1},
    [Themes.MIDNIGHT]: {raw: 'BLACK', opacity: 1},
    [Themes.DARKER]: {raw: 'PLUM_20', opacity: 1},
  },
  GUILD_NOTIFICATIONS_BOTTOM_SHEET_PILL_BACKGROUND: {
    category: 'generic',
    [Themes.DARK]: {raw: 'PRIMARY_700', opacity: 1},
    [Themes.LIGHT]: {raw: 'WHITE', opacity: 1},
    [Themes.MIDNIGHT]: {raw: 'PRIMARY_700', opacity: 1},
    [Themes.DARKER]: {raw: 'PRIMARY_700', opacity: 1},
  },
  HALO_POSITIVE: {
    category: 'generic',
    [Themes.DARK]: {raw: 'GREEN_360', opacity: 0.24},
    [Themes.LIGHT]: {raw: 'GREEN_360', opacity: 0.24},
    [Themes.MIDNIGHT]: {raw: 'GREEN_360', opacity: 0.24},
    [Themes.DARKER]: {raw: 'GREEN_360', opacity: 0.24},
  },
  HEADER_MUTED: {
    category: 'text',
    [Themes.DARK]: {raw: 'PRIMARY_360', opacity: 1},
    [Themes.LIGHT]: {raw: 'PRIMARY_460', opacity: 1},
    [Themes.MIDNIGHT]: {raw: 'PLUM_10', opacity: 1},
    [Themes.DARKER]: {raw: 'PLUM_10', opacity: 1},
  },
  HEADER_PRIMARY: {
    category: 'text',
    [Themes.DARK]: {raw: 'PRIMARY_130', opacity: 1},
    [Themes.LIGHT]: {raw: 'PRIMARY_860', opacity: 1},
    [Themes.MIDNIGHT]: {raw: 'PLUM_2', opacity: 1},
    [Themes.DARKER]: {raw: 'PLUM_3', opacity: 1},
  },
  HEADER_SECONDARY: {
    category: 'text',
    [Themes.DARK]: {raw: 'PRIMARY_330', opacity: 1},
    [Themes.LIGHT]: {raw: 'PRIMARY_500', opacity: 1},
    [Themes.MIDNIGHT]: {raw: 'PLUM_8', opacity: 1},
    [Themes.DARKER]: {raw: 'PLUM_9', opacity: 1},
  },
  HOME_BACKGROUND: {
    category: 'generic',
    [Themes.DARK]: {raw: 'PRIMARY_645', opacity: 1},
    [Themes.LIGHT]: {raw: 'PRIMARY_100', opacity: 1},
    [Themes.MIDNIGHT]: {raw: 'PLUM_18', opacity: 1},
    [Themes.DARKER]: {raw: 'PLUM_18', opacity: 1},
  },
  HOME_CARD_RESTING_BORDER: {
    category: 'generic',
    [Themes.DARK]: {raw: 'TRANSPARENT', opacity: 0},
    [Themes.LIGHT]: {raw: 'PRIMARY_400', opacity: 0.24},
    [Themes.MIDNIGHT]: {raw: 'TRANSPARENT', opacity: 0},
    [Themes.DARKER]: {raw: 'TRANSPARENT', opacity: 0},
  },
  ICON_MUTED: {
    category: 'generic',
    [Themes.DARK]: {raw: 'PRIMARY_400', opacity: 1},
    [Themes.LIGHT]: {raw: 'PRIMARY_400', opacity: 1},
    [Themes.MIDNIGHT]: {raw: 'PLUM_12', opacity: 1},
    [Themes.DARKER]: {raw: 'PLUM_11', opacity: 1},
  },
  ICON_PRIMARY: {
    category: 'generic',
    [Themes.DARK]: {raw: 'PRIMARY_130', opacity: 1},
    [Themes.LIGHT]: {raw: 'PRIMARY_860', opacity: 1},
    [Themes.MIDNIGHT]: {raw: 'PLUM_5', opacity: 1},
    [Themes.DARKER]: {raw: 'PLUM_4', opacity: 1},
  },
  ICON_SECONDARY: {
    category: 'generic',
    [Themes.DARK]: {raw: 'PRIMARY_330', opacity: 1},
    [Themes.LIGHT]: {raw: 'PRIMARY_500', opacity: 1},
    [Themes.MIDNIGHT]: {raw: 'PLUM_9', opacity: 1},
    [Themes.DARKER]: {raw: 'PLUM_9', opacity: 1},
  },
  ICON_TRANSPARENT: {
    category: 'generic',
    [Themes.DARK]: {raw: 'TRANSPARENT', opacity: 0},
    [Themes.LIGHT]: {raw: 'TRANSPARENT', opacity: 0},
    [Themes.MIDNIGHT]: {raw: 'TRANSPARENT', opacity: 0},
    [Themes.DARKER]: {raw: 'TRANSPARENT', opacity: 0},
  },
  INFO_BOX_BACKGROUND: {
    category: 'generic',
    [Themes.DARK]: {raw: 'BLUE_345', opacity: 0.1},
    [Themes.LIGHT]: {raw: 'BLUE_345', opacity: 0.1},
    [Themes.MIDNIGHT]: {raw: 'BLUE_345', opacity: 0.1},
    [Themes.DARKER]: {raw: 'BLUE_345', opacity: 0.1},
  },
  INFO_DANGER_BACKGROUND: {
    category: 'generic',
    [Themes.DARK]: {raw: 'RED_400', opacity: 0.1},
    [Themes.LIGHT]: {raw: 'RED_430', opacity: 0.1},
    [Themes.MIDNIGHT]: {raw: 'RED_400', opacity: 0.1},
    [Themes.DARKER]: {raw: 'RED_400', opacity: 0.1},
  },
  INFO_DANGER_FOREGROUND: {
    category: 'generic',
    [Themes.DARK]: {raw: 'RED_400', opacity: 1},
    [Themes.LIGHT]: {raw: 'RED_430', opacity: 1},
    [Themes.MIDNIGHT]: {raw: 'RED_400', opacity: 1},
    [Themes.DARKER]: {raw: 'RED_400', opacity: 1},
  },
  INFO_DANGER_TEXT: {
    category: 'text',
    [Themes.DARK]: {raw: 'WHITE', opacity: 1},
    [Themes.LIGHT]: {raw: 'BLACK', opacity: 1},
    [Themes.MIDNIGHT]: {raw: 'WHITE', opacity: 1},
    [Themes.DARKER]: {raw: 'WHITE', opacity: 1},
  },
  INFO_HELP_BACKGROUND: {
    category: 'generic',
    [Themes.DARK]: {raw: 'BLUE_345', opacity: 0.1},
    [Themes.LIGHT]: {raw: 'BLUE_430', opacity: 0.1},
    [Themes.MIDNIGHT]: {raw: 'BLUE_345', opacity: 0.1},
    [Themes.DARKER]: {raw: 'BLUE_345', opacity: 0.1},
  },
  INFO_HELP_FOREGROUND: {
    category: 'generic',
    [Themes.DARK]: {raw: 'BLUE_345', opacity: 1},
    [Themes.LIGHT]: {raw: 'BLUE_430', opacity: 1},
    [Themes.MIDNIGHT]: {raw: 'BLUE_345', opacity: 1},
    [Themes.DARKER]: {raw: 'BLUE_345', opacity: 1},
  },
  INFO_HELP_TEXT: {
    category: 'text',
    [Themes.DARK]: {raw: 'WHITE', opacity: 1},
    [Themes.LIGHT]: {raw: 'BLACK', opacity: 1},
    [Themes.MIDNIGHT]: {raw: 'WHITE', opacity: 1},
    [Themes.DARKER]: {raw: 'WHITE', opacity: 1},
  },
  INFO_POSITIVE_BACKGROUND: {
    category: 'generic',
    [Themes.DARK]: {raw: 'GREEN_360', opacity: 0.1},
    [Themes.LIGHT]: {raw: 'GREEN_400', opacity: 0.05},
    [Themes.MIDNIGHT]: {raw: 'GREEN_360', opacity: 0.1},
    [Themes.DARKER]: {raw: 'GREEN_360', opacity: 0.1},
  },
  INFO_POSITIVE_FOREGROUND: {
    category: 'generic',
    [Themes.DARK]: {raw: 'GREEN_360', opacity: 1},
    [Themes.LIGHT]: {raw: 'GREEN_400', opacity: 1},
    [Themes.MIDNIGHT]: {raw: 'GREEN_360', opacity: 1},
    [Themes.DARKER]: {raw: 'GREEN_360', opacity: 1},
  },
  INFO_POSITIVE_TEXT: {
    category: 'text',
    [Themes.DARK]: {raw: 'WHITE', opacity: 1},
    [Themes.LIGHT]: {raw: 'BLACK', opacity: 1},
    [Themes.MIDNIGHT]: {raw: 'WHITE', opacity: 1},
    [Themes.DARKER]: {raw: 'WHITE', opacity: 1},
  },
  INFO_WARNING_BACKGROUND: {
    category: 'generic',
    [Themes.DARK]: {raw: 'YELLOW_300', opacity: 0.1},
    [Themes.LIGHT]: {raw: 'YELLOW_400', opacity: 0.1},
    [Themes.MIDNIGHT]: {raw: 'YELLOW_300', opacity: 0.1},
    [Themes.DARKER]: {raw: 'YELLOW_300', opacity: 0.1},
  },
  INFO_WARNING_FOREGROUND: {
    category: 'generic',
    [Themes.DARK]: {raw: 'YELLOW_300', opacity: 1},
    [Themes.LIGHT]: {raw: 'YELLOW_400', opacity: 1},
    [Themes.MIDNIGHT]: {raw: 'YELLOW_300', opacity: 1},
    [Themes.DARKER]: {raw: 'YELLOW_300', opacity: 1},
  },
  INFO_WARNING_TEXT: {
    category: 'text',
    [Themes.DARK]: {raw: 'WHITE', opacity: 1},
    [Themes.LIGHT]: {raw: 'BLACK', opacity: 1},
    [Themes.MIDNIGHT]: {raw: 'WHITE', opacity: 1},
    [Themes.DARKER]: {raw: 'WHITE', opacity: 1},
  },
  INPUT_BACKGROUND: {
    category: 'background',
    [Themes.DARK]: {raw: 'PRIMARY_700', opacity: 1},
    [Themes.LIGHT]: {raw: 'BLACK', opacity: 0.08},
    [Themes.MIDNIGHT]: {raw: 'PLUM_23_ALPHA', opacity: 0.1},
    [Themes.DARKER]: {raw: 'PLUM_24', opacity: 0.85},
    gradient: {light: {color: 'BLACK_500', opacity: 0.1}, dark: {color: 'WHITE_500', opacity: 0.1}},
  },
  INPUT_FOCUSED_BORDER: {
    category: 'background',
    [Themes.DARK]: {raw: 'WHITE', opacity: 0.16},
    [Themes.LIGHT]: {raw: 'BLACK', opacity: 0.16},
    [Themes.MIDNIGHT]: {raw: 'PLUM_11', opacity: 0.24},
    [Themes.DARKER]: {raw: 'PLUM_11', opacity: 0.32},
  },
  INPUT_PLACEHOLDER_TEXT: {
    category: 'background',
    [Themes.DARK]: {raw: 'INPUT_PLACEHOLDER_TEXT_DARK', opacity: 1},
    [Themes.LIGHT]: {raw: 'PRIMARY_460', opacity: 1},
    [Themes.MIDNIGHT]: {raw: 'PLUM_9', opacity: 1},
    [Themes.DARKER]: {raw: 'PLUM_10', opacity: 1},
  },
  INTERACTIVE_ACTIVE: {
    category: 'generic',
    [Themes.DARK]: {raw: 'WHITE', opacity: 1},
    [Themes.LIGHT]: {raw: 'PRIMARY_860', opacity: 1},
    [Themes.MIDNIGHT]: {raw: 'PLUM_3', opacity: 1},
    [Themes.DARKER]: {raw: 'PLUM_1', opacity: 1},
  },
  INTERACTIVE_HOVER: {
    category: 'generic',
    [Themes.DARK]: {raw: 'PRIMARY_230', opacity: 1},
    [Themes.LIGHT]: {raw: 'PRIMARY_600', opacity: 1},
    [Themes.MIDNIGHT]: {raw: 'PLUM_4', opacity: 1},
    [Themes.DARKER]: {raw: 'PLUM_4', opacity: 1},
  },
  INTERACTIVE_MUTED: {
    category: 'generic',
    [Themes.DARK]: {raw: 'PRIMARY_500', opacity: 1},
    [Themes.LIGHT]: {raw: 'PRIMARY_300', opacity: 1},
    [Themes.MIDNIGHT]: {raw: 'PLUM_13', opacity: 1},
    [Themes.DARKER]: {raw: 'PLUM_13', opacity: 1},
    gradient: {light: {color: 'BLACK_500', opacity: 0.3}, dark: {color: 'WHITE_500', opacity: 0.3}},
  },
  INTERACTIVE_NORMAL: {
    category: 'generic',
    [Themes.DARK]: {raw: 'PRIMARY_330', opacity: 1},
    [Themes.LIGHT]: {raw: 'PRIMARY_500', opacity: 1},
    [Themes.MIDNIGHT]: {raw: 'PLUM_6', opacity: 1},
    [Themes.DARKER]: {raw: 'PLUM_6', opacity: 1},
  },
  LEGACY_ANDROID_BLUR_OVERLAY_DEFAULT: {
    category: 'generic',
    [Themes.DARK]: {raw: 'PRIMARY_660', opacity: 0.5},
    [Themes.LIGHT]: {raw: 'PRIMARY_130', opacity: 0.1},
    [Themes.MIDNIGHT]: {raw: 'PRIMARY_660', opacity: 0.5},
    [Themes.DARKER]: {raw: 'PRIMARY_660', opacity: 0.5},
  },
  LEGACY_ANDROID_BLUR_OVERLAY_ULTRA_THIN: {
    category: 'generic',
    [Themes.DARK]: {raw: 'BLACK', opacity: 0.025},
    [Themes.LIGHT]: {raw: 'WHITE', opacity: 0.25},
    [Themes.MIDNIGHT]: {raw: 'BLACK', opacity: 0.025},
    [Themes.DARKER]: {raw: 'BLACK', opacity: 0.025},
  },
  LEGACY_BLUR_FALLBACK_DEFAULT: {
    category: 'generic',
    [Themes.DARK]: {raw: 'PRIMARY_660', opacity: 0.975},
    [Themes.LIGHT]: {raw: 'PRIMARY_230', opacity: 0.975},
    [Themes.MIDNIGHT]: {raw: 'PRIMARY_660', opacity: 0.975},
    [Themes.DARKER]: {raw: 'PRIMARY_660', opacity: 0.975},
  },
  LEGACY_BLUR_FALLBACK_ULTRA_THIN: {
    category: 'generic',
    [Themes.DARK]: {raw: 'BLACK', opacity: 0.95},
    [Themes.LIGHT]: {raw: 'WHITE', opacity: 0.95},
    [Themes.MIDNIGHT]: {raw: 'BLACK', opacity: 0.95},
    [Themes.DARKER]: {raw: 'BLACK', opacity: 0.95},
  },
  LIVE_STAGE_TILE_BORDER: {
    category: 'generic',
    [Themes.DARK]: {raw: 'PRIMARY_500', opacity: 0.6},
    [Themes.LIGHT]: {raw: 'PRIMARY_430', opacity: 0.15},
    [Themes.MIDNIGHT]: {raw: 'PLUM_13', opacity: 0.6},
    [Themes.DARKER]: {raw: 'PLUM_13', opacity: 0.6},
  },
  LOGO_PRIMARY: {
    category: 'generic',
    [Themes.DARK]: {raw: 'WHITE', opacity: 1},
    [Themes.LIGHT]: {raw: 'BRAND_500', opacity: 1},
    [Themes.MIDNIGHT]: {raw: 'WHITE', opacity: 1},
    [Themes.DARKER]: {raw: 'WHITE', opacity: 1},
  },
  MENTION_BACKGROUND: {
    category: 'generic',
    [Themes.DARK]: {raw: 'BRAND_500', opacity: 0.3},
    [Themes.LIGHT]: {raw: 'BRAND_500', opacity: 0.15},
    [Themes.MIDNIGHT]: {raw: 'BRAND_500', opacity: 0.5},
    [Themes.DARKER]: {raw: 'BRAND_500', opacity: 0.5},
  },
  MENTION_FOREGROUND: {
    category: 'generic',
    [Themes.DARK]: {raw: 'BRAND_260', opacity: 1},
    [Themes.LIGHT]: {raw: 'BRAND_560', opacity: 1},
    [Themes.MIDNIGHT]: {raw: 'BRAND_230', opacity: 1},
    [Themes.DARKER]: {raw: 'BRAND_230', opacity: 1},
  },
  MENU_ITEM_DANGER_ACTIVE_BG: {
    category: 'background',
    [Themes.DARK]: {raw: 'RED_530', opacity: 1},
    [Themes.LIGHT]: {raw: 'RED_530', opacity: 1},
    [Themes.MIDNIGHT]: {raw: 'RED_530', opacity: 1},
    [Themes.DARKER]: {raw: 'RED_530', opacity: 1},
  },
  MENU_ITEM_DANGER_HOVER_BG: {
    category: 'background',
    [Themes.DARK]: {raw: 'RED_430', opacity: 1},
    [Themes.LIGHT]: {raw: 'RED_430', opacity: 1},
    [Themes.MIDNIGHT]: {raw: 'RED_430', opacity: 1},
    [Themes.DARKER]: {raw: 'RED_430', opacity: 1},
  },
  MENU_ITEM_DEFAULT_ACTIVE_BG: {
    category: 'background',
    [Themes.DARK]: {raw: 'BRAND_560', opacity: 1},
    [Themes.LIGHT]: {raw: 'BRAND_560', opacity: 1},
    [Themes.MIDNIGHT]: {raw: 'BRAND_560', opacity: 1},
    [Themes.DARKER]: {raw: 'BRAND_560', opacity: 1},
  },
  MENU_ITEM_DEFAULT_HOVER_BG: {
    category: 'background',
    [Themes.DARK]: {raw: 'BRAND_500', opacity: 1},
    [Themes.LIGHT]: {raw: 'BRAND_500', opacity: 1},
    [Themes.MIDNIGHT]: {raw: 'BRAND_500', opacity: 1},
    [Themes.DARKER]: {raw: 'BRAND_500', opacity: 1},
  },
  MODAL_BACKGROUND: {
    category: 'generic',
    [Themes.DARK]: {raw: 'PRIMARY_600', opacity: 1},
    [Themes.LIGHT]: {raw: 'WHITE', opacity: 1},
    [Themes.MIDNIGHT]: {raw: 'PLUM_16', opacity: 1},
    [Themes.DARKER]: {raw: 'PLUM_16', opacity: 1},
  },
  MODAL_FOOTER_BACKGROUND: {
    category: 'generic',
    [Themes.DARK]: {raw: 'PRIMARY_630', opacity: 1},
    [Themes.LIGHT]: {raw: 'PRIMARY_130', opacity: 1},
    [Themes.MIDNIGHT]: {raw: 'PLUM_17', opacity: 1},
    [Themes.DARKER]: {raw: 'PLUM_17', opacity: 1},
  },
  NAVIGATOR_HEADER_TINT: {
    category: 'generic',
    [Themes.DARK]: {raw: 'WHITE', opacity: 1},
    [Themes.LIGHT]: {raw: 'PRIMARY_500', opacity: 1},
    [Themes.MIDNIGHT]: {raw: 'WHITE', opacity: 1},
    [Themes.DARKER]: {raw: 'WHITE', opacity: 1},
  },
  PANEL_BG: {
    category: 'background',
    [Themes.DARK]: {raw: 'PRIMARY_600', opacity: 1},
    [Themes.LIGHT]: {raw: 'WHITE', opacity: 1},
    [Themes.MIDNIGHT]: {raw: 'PLUM_24', opacity: 1},
    [Themes.DARKER]: {raw: 'PLUM_20', opacity: 1},
    gradient: {light: {color: 'WHITE_500', opacity: 0.2}, dark: {color: 'BLACK_500', opacity: 0.2}},
  },
  POLLS_NORMAL_FILL_HOVER: {
    category: 'generic',
    [Themes.DARK]: {raw: 'PRIMARY_600', opacity: 1},
    [Themes.LIGHT]: {raw: 'PRIMARY_230', opacity: 1},
    [Themes.MIDNIGHT]: {raw: 'PRIMARY_600', opacity: 1},
    [Themes.DARKER]: {raw: 'PRIMARY_600', opacity: 1},
  },
  POLLS_NORMAL_IMAGE_BACKGROUND: {
    category: 'generic',
    [Themes.DARK]: {raw: 'PRIMARY_660', opacity: 1},
    [Themes.LIGHT]: {raw: 'WHITE', opacity: 1},
    [Themes.MIDNIGHT]: {raw: 'PRIMARY_660', opacity: 1},
    [Themes.DARKER]: {raw: 'PRIMARY_660', opacity: 1},
  },
  POLLS_VICTOR_FILL: {
    category: 'generic',
    [Themes.DARK]: {raw: 'GREEN_360', opacity: 0.2},
    [Themes.LIGHT]: {raw: 'GREEN_400', opacity: 0.2},
    [Themes.MIDNIGHT]: {raw: 'GREEN_360', opacity: 0.2},
    [Themes.DARKER]: {raw: 'GREEN_360', opacity: 0.2},
  },
  POLLS_VOTED_FILL: {
    category: 'generic',
    [Themes.DARK]: {raw: 'BRAND_500', opacity: 0.2},
    [Themes.LIGHT]: {raw: 'BRAND_500', opacity: 0.2},
    [Themes.MIDNIGHT]: {raw: 'BRAND_500', opacity: 0.2},
    [Themes.DARKER]: {raw: 'BRAND_500', opacity: 0.2},
  },
  PREMIUM_NITRO_PINK_TEXT: {
    category: 'text',
    [Themes.DARK]: {raw: 'PREMIUM_NITRO_PINK_LIGHT', opacity: 1},
    [Themes.LIGHT]: {raw: 'PREMIUM_NITRO_PINK_DARK', opacity: 1},
    [Themes.MIDNIGHT]: {raw: 'PREMIUM_NITRO_PINK_LIGHT', opacity: 1},
    [Themes.DARKER]: {raw: 'PREMIUM_NITRO_PINK_LIGHT', opacity: 1},
  },
  PROFILE_GRADIENT_CARD_BACKGROUND: {
    category: 'generic',
    [Themes.DARK]: {raw: 'BLACK', opacity: 0.7},
    [Themes.LIGHT]: {raw: 'WHITE', opacity: 0.7},
    [Themes.MIDNIGHT]: {raw: 'BLACK', opacity: 0.7},
    [Themes.DARKER]: {raw: 'BLACK', opacity: 0.7},
  },
  PROFILE_GRADIENT_MESSAGE_INPUT_BORDER: {
    category: 'generic',
    [Themes.DARK]: {raw: 'PRIMARY_500', opacity: 0.48},
    [Themes.LIGHT]: {raw: 'PRIMARY_430', opacity: 0.24},
    [Themes.MIDNIGHT]: {raw: 'PRIMARY_500', opacity: 0.48},
    [Themes.DARKER]: {raw: 'PRIMARY_500', opacity: 0.48},
  },
  PROFILE_GRADIENT_NOTE_BACKGROUND: {
    category: 'generic',
    [Themes.DARK]: {raw: 'BLACK', opacity: 0.3},
    [Themes.LIGHT]: {raw: 'WHITE', opacity: 0.3},
    [Themes.MIDNIGHT]: {raw: 'BLACK', opacity: 0.3},
    [Themes.DARKER]: {raw: 'BLACK', opacity: 0.3},
  },
  PROFILE_GRADIENT_OVERLAY: {
    category: 'generic',
    [Themes.DARK]: {raw: 'BLACK', opacity: 0.6},
    [Themes.LIGHT]: {raw: 'WHITE', opacity: 0.6},
    [Themes.MIDNIGHT]: {raw: 'BLACK', opacity: 0.6},
    [Themes.DARKER]: {raw: 'BLACK', opacity: 0.6},
  },
  PROFILE_GRADIENT_OVERLAY_SYNCED_WITH_USER_THEME: {
    category: 'generic',
    [Themes.DARK]: {raw: 'BLACK', opacity: 0.8},
    [Themes.LIGHT]: {raw: 'WHITE', opacity: 0.8},
    [Themes.MIDNIGHT]: {raw: 'BLACK', opacity: 0.8},
    [Themes.DARKER]: {raw: 'BLACK', opacity: 0.8},
  },
  PROFILE_GRADIENT_PROFILE_BODY_BACKGROUND_HOVER: {
    category: 'generic',
    [Themes.DARK]: {raw: 'WHITE', opacity: 0.16},
    [Themes.LIGHT]: {raw: 'BLACK', opacity: 0.08},
    [Themes.MIDNIGHT]: {raw: 'WHITE', opacity: 0.16},
    [Themes.DARKER]: {raw: 'WHITE', opacity: 0.16},
  },
  PROFILE_GRADIENT_ROLE_PILL_BACKGROUND: {
    category: 'generic',
    [Themes.DARK]: {raw: 'PRIMARY_660', opacity: 0.5},
    [Themes.LIGHT]: {raw: 'WHITE', opacity: 0.5},
    [Themes.MIDNIGHT]: {raw: 'PRIMARY_660', opacity: 0.5},
    [Themes.DARKER]: {raw: 'PRIMARY_660', opacity: 0.5},
  },
  PROFILE_GRADIENT_ROLE_PILL_BORDER: {
    category: 'generic',
    [Themes.DARK]: {raw: 'WHITE', opacity: 0.2},
    [Themes.LIGHT]: {raw: 'PRIMARY_660', opacity: 0.2},
    [Themes.MIDNIGHT]: {raw: 'WHITE', opacity: 0.2},
    [Themes.DARKER]: {raw: 'WHITE', opacity: 0.2},
  },
  PROFILE_GRADIENT_SECTION_BOX: {
    category: 'generic',
    [Themes.DARK]: {raw: 'BLACK', opacity: 0.45},
    [Themes.LIGHT]: {raw: 'WHITE', opacity: 0.45},
    [Themes.MIDNIGHT]: {raw: 'BLACK', opacity: 0.45},
    [Themes.DARKER]: {raw: 'BLACK', opacity: 0.45},
  },
  REDESIGN_ACTIVITY_CARD_BACKGROUND: {
    category: 'generic',
    [Themes.DARK]: {raw: 'PRIMARY_560', opacity: 1},
    [Themes.LIGHT]: {raw: 'WHITE', opacity: 1},
    [Themes.MIDNIGHT]: {raw: 'PRIMARY_560', opacity: 1},
    [Themes.DARKER]: {raw: 'PRIMARY_560', opacity: 1},
  },
  REDESIGN_ACTIVITY_CARD_BACKGROUND_PRESSED: {
    category: 'generic',
    [Themes.DARK]: {raw: 'PRIMARY_630', opacity: 1},
    [Themes.LIGHT]: {raw: 'PRIMARY_160', opacity: 1},
    [Themes.MIDNIGHT]: {raw: 'PRIMARY_630', opacity: 1},
    [Themes.DARKER]: {raw: 'PRIMARY_630', opacity: 1},
    gradient: {light: {color: 'BLACK_500', opacity: 0.2}, dark: {color: 'WHITE_500', opacity: 0.2}},
  },
  REDESIGN_ACTIVITY_CARD_BADGE_ICON: {
    category: 'generic',
    [Themes.DARK]: {raw: 'PRIMARY_360', opacity: 1},
    [Themes.LIGHT]: {raw: 'PRIMARY_400', opacity: 1},
    [Themes.MIDNIGHT]: {raw: 'PLUM_11', opacity: 1},
    [Themes.DARKER]: {raw: 'PLUM_10', opacity: 1},
  },
  REDESIGN_ACTIVITY_CARD_BORDER: {
    category: 'generic',
    [Themes.DARK]: {raw: 'WHITE', opacity: 0.02},
    [Themes.LIGHT]: {raw: 'PRIMARY_400', opacity: 0.24},
    [Themes.MIDNIGHT]: {raw: 'PLUM_11', opacity: 0.12},
    [Themes.DARKER]: {raw: 'PLUM_11', opacity: 0.06},
    gradient: {light: {color: 'BLACK_500', opacity: 0.1}, dark: {color: 'WHITE_500', opacity: 0.1}},
  },
  REDESIGN_ACTIVITY_CARD_OVERFLOW_BACKGROUND: {
    category: 'generic',
    [Themes.DARK]: {raw: 'PRIMARY_630', opacity: 1},
    [Themes.LIGHT]: {raw: 'PRIMARY_230', opacity: 1},
    [Themes.MIDNIGHT]: {raw: 'PLUM_17', opacity: 1},
    [Themes.DARKER]: {raw: 'PLUM_17', opacity: 1},
  },
  REDESIGN_BUTTON_ACTIVE_BACKGROUND: {
    category: 'generic',
    [Themes.DARK]: {raw: 'GREEN_430', opacity: 1},
    [Themes.LIGHT]: {raw: 'GREEN_430', opacity: 1},
    [Themes.MIDNIGHT]: {raw: 'GREEN_430', opacity: 1},
    [Themes.DARKER]: {raw: 'GREEN_430', opacity: 1},
  },
  REDESIGN_BUTTON_ACTIVE_PRESSED_BACKGROUND: {
    category: 'generic',
    [Themes.DARK]: {raw: 'GREEN_460', opacity: 1},
    [Themes.LIGHT]: {raw: 'GREEN_460', opacity: 1},
    [Themes.MIDNIGHT]: {raw: 'GREEN_460', opacity: 1},
    [Themes.DARKER]: {raw: 'GREEN_460', opacity: 1},
  },
  REDESIGN_BUTTON_ACTIVE_TEXT: {
    category: 'text',
    [Themes.DARK]: {raw: 'WHITE', opacity: 1},
    [Themes.LIGHT]: {raw: 'WHITE', opacity: 1},
    [Themes.MIDNIGHT]: {raw: 'WHITE', opacity: 1},
    [Themes.DARKER]: {raw: 'WHITE', opacity: 1},
  },
  REDESIGN_BUTTON_DANGER_BACKGROUND: {
    category: 'generic',
    [Themes.DARK]: {raw: 'RED_430', opacity: 1},
    [Themes.LIGHT]: {raw: 'RED_430', opacity: 1},
    [Themes.MIDNIGHT]: {raw: 'RED_430', opacity: 1},
    [Themes.DARKER]: {raw: 'RED_430', opacity: 1},
  },
  REDESIGN_BUTTON_DANGER_PRESSED_BACKGROUND: {
    category: 'generic',
    [Themes.DARK]: {raw: 'RED_460', opacity: 1},
    [Themes.LIGHT]: {raw: 'RED_460', opacity: 1},
    [Themes.MIDNIGHT]: {raw: 'RED_460', opacity: 1},
    [Themes.DARKER]: {raw: 'RED_460', opacity: 1},
  },
  REDESIGN_BUTTON_DANGER_TEXT: {
    category: 'text',
    [Themes.DARK]: {raw: 'WHITE', opacity: 1},
    [Themes.LIGHT]: {raw: 'WHITE', opacity: 1},
    [Themes.MIDNIGHT]: {raw: 'WHITE', opacity: 1},
    [Themes.DARKER]: {raw: 'WHITE', opacity: 1},
  },
  REDESIGN_BUTTON_DESTRUCTIVE_BACKGROUND: {
    category: 'generic',
    [Themes.DARK]: {raw: 'RED_430', opacity: 1},
    [Themes.LIGHT]: {raw: 'RED_430', opacity: 1},
    [Themes.MIDNIGHT]: {raw: 'RED_430', opacity: 1},
    [Themes.DARKER]: {raw: 'RED_430', opacity: 1},
  },
  REDESIGN_BUTTON_DESTRUCTIVE_PRESSED_BACKGROUND: {
    category: 'generic',
    [Themes.DARK]: {raw: 'RED_460', opacity: 1},
    [Themes.LIGHT]: {raw: 'RED_460', opacity: 1},
    [Themes.MIDNIGHT]: {raw: 'RED_460', opacity: 1},
    [Themes.DARKER]: {raw: 'RED_460', opacity: 1},
  },
  REDESIGN_BUTTON_DESTRUCTIVE_TEXT: {
    category: 'text',
    [Themes.DARK]: {raw: 'WHITE', opacity: 1},
    [Themes.LIGHT]: {raw: 'WHITE', opacity: 1},
    [Themes.MIDNIGHT]: {raw: 'WHITE', opacity: 1},
    [Themes.DARKER]: {raw: 'WHITE', opacity: 1},
  },
  REDESIGN_BUTTON_OVERLAY_ALPHA_BACKGROUND: {
    category: 'generic',
    [Themes.DARK]: {raw: 'BLACK', opacity: 0.54},
    [Themes.LIGHT]: {raw: 'BLACK', opacity: 0.54},
    [Themes.MIDNIGHT]: {raw: 'BLACK', opacity: 0.54},
    [Themes.DARKER]: {raw: 'BLACK', opacity: 0.54},
  },
  REDESIGN_BUTTON_OVERLAY_ALPHA_PRESSED_BACKGROUND: {
    category: 'generic',
    [Themes.DARK]: {raw: 'BLACK', opacity: 0.64},
    [Themes.LIGHT]: {raw: 'BLACK', opacity: 0.64},
    [Themes.MIDNIGHT]: {raw: 'BLACK', opacity: 0.64},
    [Themes.DARKER]: {raw: 'BLACK', opacity: 0.64},
  },
  REDESIGN_BUTTON_OVERLAY_ALPHA_TEXT: {
    category: 'text',
    [Themes.DARK]: {raw: 'WHITE', opacity: 1},
    [Themes.LIGHT]: {raw: 'WHITE', opacity: 1},
    [Themes.MIDNIGHT]: {raw: 'WHITE', opacity: 1},
    [Themes.DARKER]: {raw: 'WHITE', opacity: 1},
  },
  REDESIGN_BUTTON_OVERLAY_BACKGROUND: {
    category: 'generic',
    [Themes.DARK]: {raw: 'WHITE', opacity: 1},
    [Themes.LIGHT]: {raw: 'WHITE', opacity: 1},
    [Themes.MIDNIGHT]: {raw: 'WHITE', opacity: 1},
    [Themes.DARKER]: {raw: 'WHITE', opacity: 1},
  },
  REDESIGN_BUTTON_OVERLAY_PRESSED_BACKGROUND: {
    category: 'generic',
    [Themes.DARK]: {raw: 'PRIMARY_230', opacity: 1},
    [Themes.LIGHT]: {raw: 'PRIMARY_230', opacity: 1},
    [Themes.MIDNIGHT]: {raw: 'PRIMARY_230', opacity: 1},
    [Themes.DARKER]: {raw: 'PRIMARY_230', opacity: 1},
  },
  REDESIGN_BUTTON_OVERLAY_TEXT: {
    category: 'text',
    [Themes.DARK]: {raw: 'PRIMARY_860', opacity: 1},
    [Themes.LIGHT]: {raw: 'PRIMARY_860', opacity: 1},
    [Themes.MIDNIGHT]: {raw: 'PRIMARY_860', opacity: 1},
    [Themes.DARKER]: {raw: 'PRIMARY_860', opacity: 1},
  },
  REDESIGN_BUTTON_POSITIVE_BACKGROUND: {
    category: 'generic',
    [Themes.DARK]: {raw: 'GREEN_430', opacity: 1},
    [Themes.LIGHT]: {raw: 'GREEN_430', opacity: 1},
    [Themes.MIDNIGHT]: {raw: 'GREEN_430', opacity: 1},
    [Themes.DARKER]: {raw: 'GREEN_430', opacity: 1},
  },
  REDESIGN_BUTTON_POSITIVE_PRESSED_BACKGROUND: {
    category: 'generic',
    [Themes.DARK]: {raw: 'GREEN_460', opacity: 1},
    [Themes.LIGHT]: {raw: 'GREEN_460', opacity: 1},
    [Themes.MIDNIGHT]: {raw: 'GREEN_460', opacity: 1},
    [Themes.DARKER]: {raw: 'GREEN_460', opacity: 1},
  },
  REDESIGN_BUTTON_POSITIVE_TEXT: {
    category: 'text',
    [Themes.DARK]: {raw: 'WHITE', opacity: 1},
    [Themes.LIGHT]: {raw: 'WHITE', opacity: 1},
    [Themes.MIDNIGHT]: {raw: 'WHITE', opacity: 1},
    [Themes.DARKER]: {raw: 'WHITE', opacity: 1},
  },
  REDESIGN_BUTTON_PRIMARY_ALT_BACKGROUND: {
    category: 'generic',
    [Themes.DARK]: {raw: 'BRAND_500', opacity: 0},
    [Themes.LIGHT]: {raw: 'BRAND_500', opacity: 0},
    [Themes.MIDNIGHT]: {raw: 'BRAND_500', opacity: 0},
    [Themes.DARKER]: {raw: 'BRAND_500', opacity: 0},
  },
  REDESIGN_BUTTON_PRIMARY_ALT_BORDER: {
    category: 'generic',
    [Themes.DARK]: {raw: 'BRAND_360', opacity: 1},
    [Themes.LIGHT]: {raw: 'BRAND_500', opacity: 1},
    [Themes.MIDNIGHT]: {raw: 'BRAND_400', opacity: 1},
    [Themes.DARKER]: {raw: 'BRAND_360', opacity: 1},
  },
  REDESIGN_BUTTON_PRIMARY_ALT_ON_BLURPLE_BACKGROUND: {
    category: 'generic',
    [Themes.DARK]: {raw: 'BRAND_530', opacity: 0},
    [Themes.LIGHT]: {raw: 'BRAND_530', opacity: 0},
    [Themes.MIDNIGHT]: {raw: 'BRAND_530', opacity: 0},
    [Themes.DARKER]: {raw: 'BRAND_530', opacity: 0},
  },
  REDESIGN_BUTTON_PRIMARY_ALT_ON_BLURPLE_BORDER: {
    category: 'generic',
    [Themes.DARK]: {raw: 'WHITE', opacity: 1},
    [Themes.LIGHT]: {raw: 'WHITE', opacity: 1},
    [Themes.MIDNIGHT]: {raw: 'WHITE', opacity: 1},
    [Themes.DARKER]: {raw: 'WHITE', opacity: 1},
  },
  REDESIGN_BUTTON_PRIMARY_ALT_ON_BLURPLE_PRESSED_BACKGROUND: {
    category: 'generic',
    [Themes.DARK]: {raw: 'BRAND_530', opacity: 1},
    [Themes.LIGHT]: {raw: 'BRAND_530', opacity: 1},
    [Themes.MIDNIGHT]: {raw: 'BRAND_530', opacity: 1},
    [Themes.DARKER]: {raw: 'BRAND_530', opacity: 1},
  },
  REDESIGN_BUTTON_PRIMARY_ALT_ON_BLURPLE_PRESSED_BORDER: {
    category: 'generic',
    [Themes.DARK]: {raw: 'BRAND_360', opacity: 1},
    [Themes.LIGHT]: {raw: 'BRAND_360', opacity: 1},
    [Themes.MIDNIGHT]: {raw: 'BRAND_360', opacity: 1},
    [Themes.DARKER]: {raw: 'BRAND_360', opacity: 1},
  },
  REDESIGN_BUTTON_PRIMARY_ALT_ON_BLURPLE_TEXT: {
    category: 'text',
    [Themes.DARK]: {raw: 'WHITE', opacity: 1},
    [Themes.LIGHT]: {raw: 'WHITE', opacity: 1},
    [Themes.MIDNIGHT]: {raw: 'WHITE', opacity: 1},
    [Themes.DARKER]: {raw: 'WHITE', opacity: 1},
  },
  REDESIGN_BUTTON_PRIMARY_ALT_PRESSED_BACKGROUND: {
    category: 'generic',
    [Themes.DARK]: {raw: 'BRAND_700', opacity: 0.16},
    [Themes.LIGHT]: {raw: 'BRAND_500', opacity: 0.06},
    [Themes.MIDNIGHT]: {raw: 'BRAND_700', opacity: 0.16},
    [Themes.DARKER]: {raw: 'BRAND_700', opacity: 0.16},
  },
  REDESIGN_BUTTON_PRIMARY_ALT_PRESSED_BORDER: {
    category: 'generic',
    [Themes.DARK]: {raw: 'BRAND_400', opacity: 0.5},
    [Themes.LIGHT]: {raw: 'BRAND_330', opacity: 1},
    [Themes.MIDNIGHT]: {raw: 'BRAND_400', opacity: 0.5},
    [Themes.DARKER]: {raw: 'BRAND_400', opacity: 0.5},
  },
  REDESIGN_BUTTON_PRIMARY_ALT_PRESSED_TEXT: {
    category: 'text',
    [Themes.DARK]: {raw: 'BRAND_360', opacity: 1},
    [Themes.LIGHT]: {raw: 'BRAND_560', opacity: 1},
    [Themes.MIDNIGHT]: {raw: 'BRAND_360', opacity: 1},
    [Themes.DARKER]: {raw: 'BRAND_360', opacity: 1},
  },
  REDESIGN_BUTTON_PRIMARY_ALT_TEXT: {
    category: 'text',
    [Themes.DARK]: {raw: 'BRAND_360', opacity: 1},
    [Themes.LIGHT]: {raw: 'BRAND_500', opacity: 1},
    [Themes.MIDNIGHT]: {raw: 'BRAND_400', opacity: 1},
    [Themes.DARKER]: {raw: 'BRAND_360', opacity: 1},
  },
  REDESIGN_BUTTON_PRIMARY_BACKGROUND: {
    category: 'generic',
    [Themes.DARK]: {raw: 'BRAND_500', opacity: 1},
    [Themes.LIGHT]: {raw: 'BRAND_500', opacity: 1},
    [Themes.MIDNIGHT]: {raw: 'BRAND_500', opacity: 1},
    [Themes.DARKER]: {raw: 'BRAND_500', opacity: 1},
  },
  REDESIGN_BUTTON_PRIMARY_ON_BLURPLE_PRESSED_TEXT: {
    category: 'text',
    [Themes.DARK]: {raw: 'BRAND_530', opacity: 1},
    [Themes.LIGHT]: {raw: 'BRAND_530', opacity: 1},
    [Themes.MIDNIGHT]: {raw: 'BRAND_530', opacity: 1},
    [Themes.DARKER]: {raw: 'BRAND_530', opacity: 1},
  },
  REDESIGN_BUTTON_PRIMARY_OVERLAY_BACKGROUND: {
    category: 'generic',
    [Themes.DARK]: {raw: 'WHITE', opacity: 1},
    [Themes.LIGHT]: {raw: 'WHITE', opacity: 1},
    [Themes.MIDNIGHT]: {raw: 'WHITE', opacity: 1},
    [Themes.DARKER]: {raw: 'WHITE', opacity: 1},
  },
  REDESIGN_BUTTON_PRIMARY_OVERLAY_PRESSED_BACKGROUND: {
    category: 'generic',
    [Themes.DARK]: {raw: 'PRIMARY_230', opacity: 1},
    [Themes.LIGHT]: {raw: 'PRIMARY_230', opacity: 1},
    [Themes.MIDNIGHT]: {raw: 'PLUM_4', opacity: 1},
    [Themes.DARKER]: {raw: 'PLUM_4', opacity: 1},
  },
  REDESIGN_BUTTON_PRIMARY_OVERLAY_TEXT: {
    category: 'text',
    [Themes.DARK]: {raw: 'PRIMARY_860', opacity: 1},
    [Themes.LIGHT]: {raw: 'PRIMARY_860', opacity: 1},
    [Themes.MIDNIGHT]: {raw: 'PLUM_25', opacity: 1},
    [Themes.DARKER]: {raw: 'PLUM_25', opacity: 1},
  },
  REDESIGN_BUTTON_PRIMARY_PRESSED_BACKGROUND: {
    category: 'generic',
    [Themes.DARK]: {raw: 'BRAND_560', opacity: 1},
    [Themes.LIGHT]: {raw: 'BRAND_560', opacity: 1},
    [Themes.MIDNIGHT]: {raw: 'BRAND_560', opacity: 1},
    [Themes.DARKER]: {raw: 'BRAND_560', opacity: 1},
  },
  REDESIGN_BUTTON_PRIMARY_TEXT: {
    category: 'text',
    [Themes.DARK]: {raw: 'WHITE', opacity: 1},
    [Themes.LIGHT]: {raw: 'WHITE', opacity: 1},
    [Themes.MIDNIGHT]: {raw: 'WHITE', opacity: 1},
    [Themes.DARKER]: {raw: 'WHITE', opacity: 1},
  },
  REDESIGN_BUTTON_SECONDARY_BACKGROUND: {
    category: 'generic',
    [Themes.DARK]: {raw: 'PRIMARY_460', opacity: 1},
    [Themes.LIGHT]: {raw: 'WHITE', opacity: 1},
    [Themes.MIDNIGHT]: {raw: 'PLUM_17', opacity: 1},
    [Themes.DARKER]: {raw: 'PLUM_15', opacity: 1},
    gradient: {light: {color: 'WHITE_500', opacity: 0.72}, dark: {color: 'WHITE_500', opacity: 0.24}},
  },
  REDESIGN_BUTTON_SECONDARY_BORDER: {
    category: 'generic',
    [Themes.DARK]: {raw: 'WHITE', opacity: 0.08},
    [Themes.LIGHT]: {raw: 'BLACK', opacity: 0.08},
    [Themes.MIDNIGHT]: {raw: 'PLUM_11', opacity: 0.12},
    [Themes.DARKER]: {raw: 'PLUM_11', opacity: 0.12},
    gradient: {light: {color: 'WHITE_500', opacity: 0}, dark: {color: 'WHITE_500', opacity: 0}},
  },
  REDESIGN_BUTTON_SECONDARY_OVERLAY_BACKGROUND: {
    category: 'generic',
    [Themes.DARK]: {raw: 'BLACK', opacity: 0.54},
    [Themes.LIGHT]: {raw: 'BLACK', opacity: 0.54},
    [Themes.MIDNIGHT]: {raw: 'BLACK', opacity: 0.54},
    [Themes.DARKER]: {raw: 'BLACK', opacity: 0.54},
  },
  REDESIGN_BUTTON_SECONDARY_OVERLAY_PRESSED_BACKGROUND: {
    category: 'generic',
    [Themes.DARK]: {raw: 'BLACK', opacity: 0.64},
    [Themes.LIGHT]: {raw: 'BLACK', opacity: 0.64},
    [Themes.MIDNIGHT]: {raw: 'BLACK', opacity: 0.64},
    [Themes.DARKER]: {raw: 'BLACK', opacity: 0.64},
  },
  REDESIGN_BUTTON_SECONDARY_OVERLAY_TEXT: {
    category: 'text',
    [Themes.DARK]: {raw: 'WHITE', opacity: 1},
    [Themes.LIGHT]: {raw: 'WHITE', opacity: 1},
    [Themes.MIDNIGHT]: {raw: 'WHITE', opacity: 1},
    [Themes.DARKER]: {raw: 'WHITE', opacity: 1},
  },
  REDESIGN_BUTTON_SECONDARY_PRESSED_BACKGROUND: {
    category: 'generic',
    [Themes.DARK]: {raw: 'PRIMARY_500', opacity: 1},
    [Themes.LIGHT]: {raw: 'PRIMARY_130', opacity: 1},
    [Themes.MIDNIGHT]: {raw: 'PLUM_15', opacity: 1},
    [Themes.DARKER]: {raw: 'PLUM_13', opacity: 1},
    gradient: {light: {color: 'WHITE_500', opacity: 0.62}, dark: {color: 'WHITE_500', opacity: 0.34}},
  },
  REDESIGN_BUTTON_SECONDARY_PRESSED_BORDER: {
    category: 'generic',
    [Themes.DARK]: {raw: 'TRANSPARENT', opacity: 0},
    [Themes.LIGHT]: {raw: 'BLACK', opacity: 0.04},
    [Themes.MIDNIGHT]: {raw: 'PLUM_11', opacity: 0.06},
    [Themes.DARKER]: {raw: 'PLUM_11', opacity: 0.06},
    gradient: {light: {color: 'WHITE_500', opacity: 0}, dark: {color: 'WHITE_500', opacity: 0}},
  },
  REDESIGN_BUTTON_SECONDARY_TEXT: {
    category: 'text',
    [Themes.DARK]: {raw: 'PRIMARY_230', opacity: 1},
    [Themes.LIGHT]: {raw: 'PRIMARY_600', opacity: 1},
    [Themes.MIDNIGHT]: {raw: 'PLUM_4', opacity: 1},
    [Themes.DARKER]: {raw: 'PLUM_6', opacity: 1},
  },
  REDESIGN_BUTTON_SELECTED_BACKGROUND: {
    category: 'generic',
    [Themes.DARK]: {raw: 'BRAND_500', opacity: 0.24},
    [Themes.LIGHT]: {raw: 'BRAND_500', opacity: 0.16},
    [Themes.MIDNIGHT]: {raw: 'BRAND_500', opacity: 0.24},
    [Themes.DARKER]: {raw: 'BRAND_500', opacity: 0.24},
  },
  REDESIGN_BUTTON_SELECTED_PRESSED_BACKGROUND: {
    category: 'generic',
    [Themes.DARK]: {raw: 'BRAND_500', opacity: 0.28},
    [Themes.LIGHT]: {raw: 'BRAND_500', opacity: 0.24},
    [Themes.MIDNIGHT]: {raw: 'BRAND_500', opacity: 0.28},
    [Themes.DARKER]: {raw: 'BRAND_500', opacity: 0.28},
  },
  REDESIGN_BUTTON_SELECTED_TEXT: {
    category: 'text',
    [Themes.DARK]: {raw: 'BRAND_500', opacity: 1},
    [Themes.LIGHT]: {raw: 'BRAND_500', opacity: 1},
    [Themes.MIDNIGHT]: {raw: 'BRAND_500', opacity: 1},
    [Themes.DARKER]: {raw: 'BRAND_500', opacity: 1},
  },
  REDESIGN_BUTTON_TERTIARY_BACKGROUND: {
    category: 'generic',
    [Themes.DARK]: {raw: 'PRIMARY_660', opacity: 1},
    [Themes.LIGHT]: {raw: 'BLACK', opacity: 0.08},
    [Themes.MIDNIGHT]: {raw: 'PLUM_23_ALPHA', opacity: 0.1},
    [Themes.DARKER]: {raw: 'PLUM_11', opacity: 0.12},
    gradient: {light: {color: 'gradient.secondary', opacity: 0.4}, dark: {color: 'WHITE_500', opacity: 0.1}},
  },
  REDESIGN_BUTTON_TERTIARY_PRESSED_BACKGROUND: {
    category: 'generic',
    [Themes.DARK]: {raw: 'PRIMARY_560', opacity: 1},
    [Themes.LIGHT]: {raw: 'BLACK', opacity: 0.16},
    [Themes.MIDNIGHT]: {raw: 'PLUM_11', opacity: 0.2},
    [Themes.DARKER]: {raw: 'PLUM_11', opacity: 0.2},
    gradient: {light: {color: 'gradient.secondary', opacity: 0.3}, dark: {color: 'WHITE_500', opacity: 0.2}},
  },
  REDESIGN_BUTTON_TERTIARY_PRESSED_TEXT: {
    category: 'text',
    [Themes.DARK]: {raw: 'PRIMARY_330', opacity: 1},
    [Themes.LIGHT]: {raw: 'PRIMARY_500', opacity: 1},
    [Themes.MIDNIGHT]: {raw: 'PLUM_6', opacity: 1},
    [Themes.DARKER]: {raw: 'PLUM_5', opacity: 1},
  },
  REDESIGN_BUTTON_TERTIARY_TEXT: {
    category: 'text',
    [Themes.DARK]: {raw: 'PRIMARY_230', opacity: 1},
    [Themes.LIGHT]: {raw: 'PRIMARY_600', opacity: 1},
    [Themes.MIDNIGHT]: {raw: 'PLUM_4', opacity: 1},
    [Themes.DARKER]: {raw: 'PLUM_6', opacity: 1},
  },
  REDESIGN_CHANNEL_CATEGORY_NAME_TEXT: {
    category: 'text',
    [Themes.DARK]: {raw: 'PRIMARY_330', opacity: 1},
    [Themes.LIGHT]: {raw: 'PRIMARY_500', opacity: 1},
    [Themes.MIDNIGHT]: {raw: 'PLUM_8', opacity: 1},
    [Themes.DARKER]: {raw: 'PLUM_9', opacity: 1},
  },
  REDESIGN_CHANNEL_MESSAGE_PREVIEW_TEXT: {
    category: 'text',
    [Themes.DARK]: {raw: 'PRIMARY_360', opacity: 1},
    [Themes.LIGHT]: {raw: 'PRIMARY_460', opacity: 1},
    [Themes.MIDNIGHT]: {raw: 'PLUM_10', opacity: 1},
    [Themes.DARKER]: {raw: 'PLUM_10', opacity: 1},
  },
  REDESIGN_CHANNEL_NAME_MUTED_TEXT: {
    category: 'text',
    [Themes.DARK]: {raw: 'PRIMARY_360', opacity: 1},
    [Themes.LIGHT]: {raw: 'PRIMARY_460', opacity: 1},
    [Themes.MIDNIGHT]: {raw: 'PLUM_10', opacity: 1},
    [Themes.DARKER]: {raw: 'PLUM_10', opacity: 1},
  },
  REDESIGN_CHANNEL_NAME_TEXT: {
    category: 'text',
    [Themes.DARK]: {raw: 'PRIMARY_130', opacity: 1},
    [Themes.LIGHT]: {raw: 'PRIMARY_860', opacity: 1},
    [Themes.MIDNIGHT]: {raw: 'PLUM_2', opacity: 1},
    [Themes.DARKER]: {raw: 'PLUM_3', opacity: 1},
  },
  REDESIGN_CHAT_INPUT_BACKGROUND: {
    category: 'generic',
    [Themes.DARK]: {raw: 'PRIMARY_700', opacity: 1},
    [Themes.LIGHT]: {raw: 'BLACK', opacity: 0.08},
    [Themes.MIDNIGHT]: {raw: 'PLUM_23_ALPHA', opacity: 0.1},
    [Themes.DARKER]: {raw: 'PLUM_11', opacity: 0.12},
  },
  REDESIGN_IMAGE_BUTTON_PRESSED_BACKGROUND: {
    category: 'generic',
    [Themes.DARK]: {raw: 'BLACK', opacity: 0.08},
    [Themes.LIGHT]: {raw: 'BLACK', opacity: 0.08},
    [Themes.MIDNIGHT]: {raw: 'BLACK', opacity: 0.08},
    [Themes.DARKER]: {raw: 'BLACK', opacity: 0.08},
  },
  REDESIGN_INPUT_CONTROL_ACTIVE_BG: {
    category: 'background',
    [Themes.DARK]: {raw: 'PRIMARY_645', opacity: 1},
    [Themes.LIGHT]: {raw: 'WHITE', opacity: 1},
    [Themes.MIDNIGHT]: {raw: 'PLUM_17', opacity: 1},
    [Themes.DARKER]: {raw: 'PLUM_16', opacity: 1},
  },
  REDESIGN_INPUT_CONTROL_SELECTED: {
    category: 'generic',
    [Themes.DARK]: {raw: 'BRAND_500', opacity: 1},
    [Themes.LIGHT]: {raw: 'BRAND_500', opacity: 1},
    [Themes.MIDNIGHT]: {raw: 'BRAND_500', opacity: 1},
    [Themes.DARKER]: {raw: 'BRAND_500', opacity: 1},
  },
  REDESIGN_ONLY_BACKGROUND_ACTIVE: {
    category: 'generic',
    [Themes.DARK]: {raw: 'PRIMARY_530', opacity: 1},
    [Themes.LIGHT]: {raw: 'PRIMARY_430', opacity: 1},
    [Themes.MIDNIGHT]: {raw: 'PRIMARY_530', opacity: 1},
    [Themes.DARKER]: {raw: 'PRIMARY_530', opacity: 1},
  },
  REDESIGN_ONLY_BACKGROUND_DEFAULT: {
    category: 'generic',
    [Themes.DARK]: {raw: 'PRIMARY_600', opacity: 1},
    [Themes.LIGHT]: {raw: 'WHITE', opacity: 1},
    [Themes.MIDNIGHT]: {raw: 'PRIMARY_600', opacity: 1},
    [Themes.DARKER]: {raw: 'PRIMARY_600', opacity: 1},
  },
  REDESIGN_ONLY_BACKGROUND_OVERLAY: {
    category: 'generic',
    [Themes.DARK]: {raw: 'PRIMARY_645', opacity: 1},
    [Themes.LIGHT]: {raw: 'PRIMARY_160', opacity: 1},
    [Themes.MIDNIGHT]: {raw: 'PRIMARY_645', opacity: 1},
    [Themes.DARKER]: {raw: 'PRIMARY_645', opacity: 1},
  },
  REDESIGN_ONLY_BACKGROUND_RAISED: {
    category: 'generic',
    [Themes.DARK]: {raw: 'PRIMARY_630', opacity: 1},
    [Themes.LIGHT]: {raw: 'PRIMARY_130', opacity: 1},
    [Themes.MIDNIGHT]: {raw: 'PRIMARY_630', opacity: 1},
    [Themes.DARKER]: {raw: 'PRIMARY_630', opacity: 1},
  },
  REDESIGN_ONLY_BACKGROUND_SUNKEN: {
    category: 'generic',
    [Themes.DARK]: {raw: 'PRIMARY_660', opacity: 1},
    [Themes.LIGHT]: {raw: 'PRIMARY_200', opacity: 1},
    [Themes.MIDNIGHT]: {raw: 'PRIMARY_660', opacity: 1},
    [Themes.DARKER]: {raw: 'PRIMARY_660', opacity: 1},
  },
  SCROLLBAR_AUTO_SCROLLBAR_COLOR_THUMB: {
    category: 'generic',
    [Themes.DARK]: {raw: 'PRIMARY_730', opacity: 1},
    [Themes.LIGHT]: {raw: 'PRIMARY_230', opacity: 1},
    [Themes.MIDNIGHT]: {raw: 'PLUM_20', opacity: 1},
    [Themes.DARKER]: {raw: 'PLUM_21', opacity: 1},
  },
  SCROLLBAR_AUTO_SCROLLBAR_COLOR_TRACK: {
    category: 'generic',
    [Themes.DARK]: {raw: 'PRIMARY_630', opacity: 1},
    [Themes.LIGHT]: {raw: 'PRIMARY_130', opacity: 1},
    [Themes.MIDNIGHT]: {raw: 'PLUM_17', opacity: 1},
    [Themes.DARKER]: {raw: 'PLUM_17', opacity: 1},
  },
  SCROLLBAR_AUTO_THUMB: {
    category: 'generic',
    [Themes.DARK]: {raw: 'PRIMARY_730', opacity: 1},
    [Themes.LIGHT]: {raw: 'PRIMARY_300', opacity: 1},
    [Themes.MIDNIGHT]: {raw: 'PLUM_16', opacity: 1},
    [Themes.DARKER]: {raw: 'PLUM_21', opacity: 1},
  },
  SCROLLBAR_AUTO_TRACK: {
    category: 'generic',
    [Themes.DARK]: {raw: 'PRIMARY_630', opacity: 1},
    [Themes.LIGHT]: {raw: 'PRIMARY_160', opacity: 1},
    [Themes.MIDNIGHT]: {raw: 'PLUM_25', opacity: 1},
    [Themes.DARKER]: {raw: 'PLUM_19', opacity: 1},
  },
  SCROLLBAR_THIN_THUMB: {
    category: 'generic',
    [Themes.DARK]: {raw: 'PRIMARY_730', opacity: 1},
    [Themes.LIGHT]: {raw: 'PRIMARY_500', opacity: 0.3},
    [Themes.MIDNIGHT]: {raw: 'PLUM_20', opacity: 1},
    [Themes.DARKER]: {raw: 'PLUM_21', opacity: 1},
  },
  SCROLLBAR_THIN_TRACK: {
    category: 'generic',
    [Themes.DARK]: {raw: 'BLACK', opacity: 0},
    [Themes.LIGHT]: {raw: 'BLACK', opacity: 0},
    [Themes.MIDNIGHT]: {raw: 'BLACK', opacity: 0},
    [Themes.DARKER]: {raw: 'BLACK', opacity: 0},
  },
  SPOILER_HIDDEN_BACKGROUND: {
    category: 'generic',
    [Themes.DARK]: {raw: 'PRIMARY_700', opacity: 1},
    [Themes.LIGHT]: {raw: 'PRIMARY_200', opacity: 1},
    [Themes.MIDNIGHT]: {raw: 'PLUM_14', opacity: 1},
    [Themes.DARKER]: {raw: 'PLUM_14', opacity: 1},
  },
  SPOILER_REVEALED_BACKGROUND: {
    category: 'generic',
    [Themes.DARK]: {raw: 'PRIMARY_660', opacity: 1},
    [Themes.LIGHT]: {raw: 'PRIMARY_160', opacity: 1},
    [Themes.MIDNIGHT]: {raw: 'PLUM_11', opacity: 0.16},
    [Themes.DARKER]: {raw: 'PLUM_11', opacity: 0.16},
  },
  STAGE_CARD_PILL_BG: {
    category: 'background',
    [Themes.DARK]: {raw: 'PRIMARY_630', opacity: 1},
    [Themes.LIGHT]: {raw: 'WHITE', opacity: 1},
    [Themes.MIDNIGHT]: {raw: 'PLUM_25', opacity: 1},
    [Themes.DARKER]: {raw: 'PLUM_19', opacity: 1},
    gradient: {light: {color: 'gradient.mid', lightness: 0.98}, dark: {color: 'gradient.mid', lightness: 0.16}},
  },
  STATUS_DANGER: {
    category: 'generic',
    [Themes.DARK]: {raw: 'RED_400', opacity: 1},
    [Themes.LIGHT]: {raw: 'RED_430', opacity: 1},
    [Themes.MIDNIGHT]: {raw: 'RED_400', opacity: 1},
    [Themes.DARKER]: {raw: 'RED_400', opacity: 1},
  },
  STATUS_DANGER_BACKGROUND: {
    category: 'generic',
    [Themes.DARK]: {raw: 'RED_430', opacity: 1},
    [Themes.LIGHT]: {raw: 'RED_430', opacity: 1},
    [Themes.MIDNIGHT]: {raw: 'RED_430', opacity: 1},
    [Themes.DARKER]: {raw: 'RED_430', opacity: 1},
  },
  STATUS_DANGER_TEXT: {
    category: 'text',
    [Themes.DARK]: {raw: 'WHITE', opacity: 1},
    [Themes.LIGHT]: {raw: 'WHITE', opacity: 1},
    [Themes.MIDNIGHT]: {raw: 'WHITE', opacity: 1},
    [Themes.DARKER]: {raw: 'WHITE', opacity: 1},
  },
  STATUS_DND: {
    category: 'generic',
    [Themes.DARK]: {raw: 'RED_400', opacity: 1},
    [Themes.LIGHT]: {raw: 'RED_430', opacity: 1},
    [Themes.MIDNIGHT]: {raw: 'RED_400', opacity: 1},
    [Themes.DARKER]: {raw: 'RED_400', opacity: 1},
  },
  STATUS_IDLE: {
    category: 'generic',
    [Themes.DARK]: {raw: 'YELLOW_300', opacity: 1},
    [Themes.LIGHT]: {raw: 'YELLOW_400', opacity: 1},
    [Themes.MIDNIGHT]: {raw: 'YELLOW_300', opacity: 1},
    [Themes.DARKER]: {raw: 'YELLOW_300', opacity: 1},
  },
  STATUS_OFFLINE: {
    category: 'generic',
    [Themes.DARK]: {raw: 'PRIMARY_360', opacity: 1},
    [Themes.LIGHT]: {raw: 'PRIMARY_460', opacity: 1},
    [Themes.MIDNIGHT]: {raw: 'PLUM_9', opacity: 1},
    [Themes.DARKER]: {raw: 'PLUM_9', opacity: 1},
  },
  STATUS_ONLINE: {
    category: 'generic',
    [Themes.DARK]: {raw: 'GREEN_360', opacity: 1},
    [Themes.LIGHT]: {raw: 'GREEN_400', opacity: 1},
    [Themes.MIDNIGHT]: {raw: 'GREEN_360', opacity: 1},
    [Themes.DARKER]: {raw: 'GREEN_360', opacity: 1},
  },
  STATUS_POSITIVE: {
    category: 'generic',
    [Themes.DARK]: {raw: 'GREEN_360', opacity: 1},
    [Themes.LIGHT]: {raw: 'GREEN_400', opacity: 1},
    [Themes.MIDNIGHT]: {raw: 'GREEN_360', opacity: 1},
    [Themes.DARKER]: {raw: 'GREEN_360', opacity: 1},
  },
  STATUS_POSITIVE_BACKGROUND: {
    category: 'generic',
    [Themes.DARK]: {raw: 'GREEN_430', opacity: 1},
    [Themes.LIGHT]: {raw: 'GREEN_430', opacity: 1},
    [Themes.MIDNIGHT]: {raw: 'GREEN_430', opacity: 1},
    [Themes.DARKER]: {raw: 'GREEN_430', opacity: 1},
  },
  STATUS_POSITIVE_TEXT: {
    category: 'text',
    [Themes.DARK]: {raw: 'WHITE', opacity: 1},
    [Themes.LIGHT]: {raw: 'WHITE', opacity: 1},
    [Themes.MIDNIGHT]: {raw: 'WHITE', opacity: 1},
    [Themes.DARKER]: {raw: 'WHITE', opacity: 1},
  },
  STATUS_SPEAKING: {
    category: 'generic',
    [Themes.DARK]: {raw: 'GREEN_360', opacity: 1},
    [Themes.LIGHT]: {raw: 'GREEN_360', opacity: 1},
    [Themes.MIDNIGHT]: {raw: 'GREEN_360', opacity: 1},
    [Themes.DARKER]: {raw: 'GREEN_360', opacity: 1},
  },
  STATUS_WARNING: {
    category: 'generic',
    [Themes.DARK]: {raw: 'YELLOW_300', opacity: 1},
    [Themes.LIGHT]: {raw: 'YELLOW_400', opacity: 1},
    [Themes.MIDNIGHT]: {raw: 'YELLOW_300', opacity: 1},
    [Themes.DARKER]: {raw: 'YELLOW_300', opacity: 1},
  },
  STATUS_WARNING_BACKGROUND: {
    category: 'generic',
    [Themes.DARK]: {raw: 'YELLOW_300', opacity: 1},
    [Themes.LIGHT]: {raw: 'YELLOW_400', opacity: 1},
    [Themes.MIDNIGHT]: {raw: 'YELLOW_300', opacity: 1},
    [Themes.DARKER]: {raw: 'YELLOW_300', opacity: 1},
  },
  STATUS_WARNING_TEXT: {
    category: 'text',
    [Themes.DARK]: {raw: 'BLACK', opacity: 1},
    [Themes.LIGHT]: {raw: 'WHITE', opacity: 1},
    [Themes.MIDNIGHT]: {raw: 'BLACK', opacity: 1},
    [Themes.DARKER]: {raw: 'BLACK', opacity: 1},
  },
  TEXT_BRAND: {
    category: 'text',
    [Themes.DARK]: {raw: 'BRAND_360', opacity: 1},
    [Themes.LIGHT]: {raw: 'BRAND_500', opacity: 1},
    [Themes.MIDNIGHT]: {raw: 'BRAND_400', opacity: 1},
    [Themes.DARKER]: {raw: 'BRAND_360', opacity: 1},
  },
  TEXT_DANGER: {
    category: 'text',
    [Themes.DARK]: {raw: 'RED_345', opacity: 1},
    [Themes.LIGHT]: {raw: 'RED_500', opacity: 1},
    [Themes.MIDNIGHT]: {raw: 'RED_345', opacity: 1},
    [Themes.DARKER]: {raw: 'RED_345', opacity: 1},
  },
  TEXT_LINK: {
    category: 'generic',
    [Themes.DARK]: {raw: 'BLUE_345', opacity: 1},
    [Themes.LIGHT]: {raw: 'BLUE_430', opacity: 1},
    [Themes.MIDNIGHT]: {raw: 'BLUE_345', opacity: 1},
    [Themes.DARKER]: {raw: 'BLUE_345', opacity: 1},
  },
  TEXT_LINK_LOW_SATURATION: {
    category: 'generic',
    [Themes.DARK]: {raw: 'BLUE_330', opacity: 1},
    [Themes.LIGHT]: {raw: 'BLUE_430', opacity: 1},
    [Themes.MIDNIGHT]: {raw: 'BLUE_330', opacity: 1},
    [Themes.DARKER]: {raw: 'BLUE_330', opacity: 1},
  },
  TEXT_LOW_CONTRAST: {
    category: 'text',
    [Themes.DARK]: {raw: 'PRIMARY_360', opacity: 1},
    [Themes.LIGHT]: {raw: 'PRIMARY_460', opacity: 1},
    [Themes.MIDNIGHT]: {raw: 'PLUM_12', opacity: 1},
    [Themes.DARKER]: {raw: 'PLUM_11', opacity: 1},
  },
  TEXT_MESSAGE_PREVIEW_LOW_SAT: {
    category: 'text',
    [Themes.DARK]: {raw: 'PRIMARY_360', opacity: 1},
    [Themes.LIGHT]: {raw: 'PRIMARY_460', opacity: 1},
    [Themes.MIDNIGHT]: {raw: 'PLUM_9', opacity: 1},
    [Themes.DARKER]: {raw: 'PLUM_11', opacity: 1},
  },
  TEXT_MUTED: {
    category: 'text',
    [Themes.DARK]: {raw: 'PRIMARY_360', opacity: 1},
    [Themes.LIGHT]: {raw: 'PRIMARY_460', opacity: 1},
    [Themes.MIDNIGHT]: {raw: 'PLUM_10', opacity: 1},
    [Themes.DARKER]: {raw: 'PLUM_10', opacity: 1},
  },
  TEXT_MUTED_ON_DEFAULT: {
    category: 'text',
    [Themes.DARK]: {raw: 'PRIMARY_330', opacity: 1},
    [Themes.LIGHT]: {raw: 'PRIMARY_430', opacity: 1},
    [Themes.MIDNIGHT]: {raw: 'PRIMARY_330', opacity: 1},
    [Themes.DARKER]: {raw: 'PRIMARY_330', opacity: 1},
  },
  TEXT_NORMAL: {
    category: 'text',
    [Themes.DARK]: {raw: 'PRIMARY_230', opacity: 1},
    [Themes.LIGHT]: {raw: 'PRIMARY_600', opacity: 1},
    [Themes.MIDNIGHT]: {raw: 'PLUM_4', opacity: 1},
    [Themes.DARKER]: {raw: 'PLUM_6', opacity: 1},
  },
  TEXT_POSITIVE: {
    category: 'text',
    [Themes.DARK]: {raw: 'GREEN_330', opacity: 1},
    [Themes.LIGHT]: {raw: 'GREEN_430', opacity: 1},
    [Themes.MIDNIGHT]: {raw: 'GREEN_330', opacity: 1},
    [Themes.DARKER]: {raw: 'GREEN_330', opacity: 1},
  },
  TEXT_PRIMARY: {
    category: 'text',
    [Themes.DARK]: {raw: 'PRIMARY_230', opacity: 1},
    [Themes.LIGHT]: {raw: 'PRIMARY_600', opacity: 1},
    [Themes.MIDNIGHT]: {raw: 'PLUM_4', opacity: 1},
    [Themes.DARKER]: {raw: 'PLUM_6', opacity: 1},
  },
  TEXT_SECONDARY: {
    category: 'text',
    [Themes.DARK]: {raw: 'PRIMARY_330', opacity: 1},
    [Themes.LIGHT]: {raw: 'PRIMARY_500', opacity: 1},
    [Themes.MIDNIGHT]: {raw: 'PLUM_8', opacity: 1},
    [Themes.DARKER]: {raw: 'PLUM_9', opacity: 1},
  },
  TEXT_WARNING: {
    category: 'text',
    [Themes.DARK]: {raw: 'YELLOW_300', opacity: 1},
    [Themes.LIGHT]: {raw: 'YELLOW_430', opacity: 1},
    [Themes.MIDNIGHT]: {raw: 'YELLOW_300', opacity: 1},
    [Themes.DARKER]: {raw: 'YELLOW_300', opacity: 1},
  },
  TEXTBOX_MARKDOWN_SYNTAX: {
    category: 'text',
    [Themes.DARK]: {raw: 'PRIMARY_360', opacity: 1},
    [Themes.LIGHT]: {raw: 'PRIMARY_530', opacity: 1},
    [Themes.MIDNIGHT]: {raw: 'PLUM_9', opacity: 1},
    [Themes.DARKER]: {raw: 'PLUM_9', opacity: 1},
  },
  THEME_LOCKED_BLUR_FALLBACK: {
    category: 'generic',
    [Themes.DARK]: {raw: 'PRIMARY_700', opacity: 0.96},
    [Themes.LIGHT]: {raw: 'PRIMARY_700', opacity: 0.96},
    [Themes.MIDNIGHT]: {raw: 'PRIMARY_700', opacity: 0.96},
    [Themes.DARKER]: {raw: 'PRIMARY_700', opacity: 0.96},
  },
  THREAD_CHANNEL_SPINE: {
    category: 'generic',
    [Themes.DARK]: {raw: 'PRIMARY_500', opacity: 1},
    [Themes.LIGHT]: {raw: 'PRIMARY_300', opacity: 1},
    [Themes.MIDNIGHT]: {raw: 'PLUM_13', opacity: 1},
    [Themes.DARKER]: {raw: 'PLUM_13', opacity: 1},
  },
  TOAST_BG: {
    category: 'background',
    [Themes.DARK]: {raw: 'PRIMARY_800', opacity: 1},
    [Themes.LIGHT]: {raw: 'WHITE', opacity: 1},
    [Themes.MIDNIGHT]: {raw: 'PLUM_19', opacity: 1},
    [Themes.DARKER]: {raw: 'PLUM_17', opacity: 1},
    gradient: {
      light: {color: 'gradient.start', lightness: 0.7, saturation: 0.5, opacity: 0.95},
      dark: {color: 'gradient.start', lightness: 0.4, saturation: 0.5, opacity: 0.95},
    },
  },
  TYPING_INDICATOR_BG: {
    category: 'background',
    [Themes.DARK]: {raw: 'PRIMARY_630', opacity: 1},
    [Themes.LIGHT]: {raw: 'PRIMARY_130', opacity: 1},
    [Themes.MIDNIGHT]: {raw: 'BLACK', opacity: 1},
    [Themes.DARKER]: {raw: 'PLUM_20', opacity: 1},
    gradient: {
      light: {color: 'gradient.end', lightness: '*0.9', saturation: '*0.9', opacity: 0.9},
      dark: {color: 'gradient.end', lightness: '*1.5', saturation: '*1.2', opacity: 0.9},
    },
  },
  USER_PROFILE_HEADER_OVERFLOW_BACKGROUND: {
    category: 'generic',
    [Themes.DARK]: {raw: 'PRIMARY_700', opacity: 0.5},
    [Themes.LIGHT]: {raw: 'WHITE', opacity: 0.5},
    [Themes.MIDNIGHT]: {raw: 'PLUM_20', opacity: 0.5},
    [Themes.DARKER]: {raw: 'PLUM_20', opacity: 0.5},
  },
  VOICE_VIDEO_VIDEO_TILE_BACKGROUND: {
    category: 'generic',
    [Themes.DARK]: {raw: 'PLUM_17', opacity: 0.4},
    [Themes.LIGHT]: {raw: 'PLUM_17', opacity: 0.4},
    [Themes.MIDNIGHT]: {raw: 'PLUM_17', opacity: 0.4},
    [Themes.DARKER]: {raw: 'PLUM_17', opacity: 0.4},
  },
  VOICE_VIDEO_VIDEO_TILE_BLUR_FALLBACK: {
    category: 'generic',
    [Themes.DARK]: {raw: 'PRIMARY_700', opacity: 0.48},
    [Themes.LIGHT]: {raw: 'PRIMARY_700', opacity: 0.48},
    [Themes.MIDNIGHT]: {raw: 'PRIMARY_700', opacity: 0.48},
    [Themes.DARKER]: {raw: 'PRIMARY_700', opacity: 0.48},
  },
  WHITE: {
    category: 'generic',
    [Themes.DARK]: {raw: 'WHITE', opacity: 1},
    [Themes.LIGHT]: {raw: 'WHITE', opacity: 1},
    [Themes.MIDNIGHT]: {raw: 'WHITE', opacity: 1},
    [Themes.DARKER]: {raw: 'WHITE', opacity: 1},
  },
  YOU_BAR_BG: {
    category: 'background',
    [Themes.DARK]: {raw: 'PRIMARY_800', opacity: 1},
    [Themes.LIGHT]: {raw: 'WHITE', opacity: 1},
    [Themes.MIDNIGHT]: {raw: 'PLUM_19', opacity: 1},
    [Themes.DARKER]: {raw: 'PLUM_17', opacity: 1},
    gradient: {light: {color: 'gradient.mid', lightness: 0.98}, dark: {color: 'gradient.mid', lightness: 0.16}},
  },
} satisfies SemanticColors;

const RawColors = {
  APPLICATION_SUBSCRIPTION_END: /* hsl(197, 98.8%, 32.4%) */ '#0177a4',
  APPLICATION_SUBSCRIPTION_START: /* hsl(235, 68.5%, 52.7%) */ '#3442d9',
  BATTLENET: /* hsl(200, 100%, 44.9%) */ '#009ae5',
  BG_GRADIENT_AURORA_1: /* hsl(220, 86.5%, 17.5%) */ '#062053',
  BG_GRADIENT_AURORA_2: /* hsl(238, 76.4%, 41.6%) */ '#191fbb',
  BG_GRADIENT_AURORA_3: /* hsl(184, 78%, 33.9%) */ '#13929a',
  BG_GRADIENT_AURORA_4: /* hsl(169, 60.2%, 32.5%) */ '#218573',
  BG_GRADIENT_AURORA_5: /* hsl(230, 92.5%, 26.3%) */ '#051a81',
  BG_GRADIENT_BLURPLE_TWILIGHT_1: /* hsl(234, 79.6%, 53.9%) */ '#2C3FE7',
  BG_GRADIENT_BLURPLE_TWILIGHT_2: /* hsl(245, 63.7%, 31.4%) */ '#261D83',
  BG_GRADIENT_CHROMA_GLOW_1: /* hsl(183, 86.3%, 40.2%) */ '#0eb5bf',
  BG_GRADIENT_CHROMA_GLOW_2: /* hsl(258, 89.8%, 46.3%) */ '#4c0ce0',
  BG_GRADIENT_CHROMA_GLOW_3: /* hsl(298, 90.9%, 34.3%) */ '#a308a7',
  BG_GRADIENT_CHROMA_GLOW_4: /* hsl(265, 100%, 66.3%) */ '#9a53ff',
  BG_GRADIENT_CHROMA_GLOW_5: /* hsl(207, 75.5%, 50.4%) */ '#218be0',
  BG_GRADIENT_CITRUS_SHERBERT_1: /* hsl(40, 88.7%, 58.2%) */ '#f3b336',
  BG_GRADIENT_CITRUS_SHERBERT_2: /* hsl(18, 81.5%, 63.9%) */ '#ee8558',
  BG_GRADIENT_COTTON_CANDY_1: /* hsl(349, 76.8%, 81.4%) */ '#f4abb8',
  BG_GRADIENT_COTTON_CANDY_2: /* hsl(226, 92.6%, 84.1%) */ '#b1c2fc',
  BG_GRADIENT_CRIMSON_MOON_1: /* hsl(0, 88.6%, 31%) */ '#950909',
  BG_GRADIENT_CRIMSON_MOON_2: /* hsl(0, 0%, 0%) */ '#000000',
  BG_GRADIENT_DESERT_KHAKI_1: /* hsl(29, 32.4%, 86.1%) */ '#e7dbd0',
  BG_GRADIENT_DESERT_KHAKI_2: /* hsl(40, 41.3%, 78.6%) */ '#dfd0b2',
  BG_GRADIENT_DESERT_KHAKI_3: /* hsl(50, 49.6%, 75.9%) */ '#e0d6a3',
  BG_GRADIENT_DUSK_1: /* hsl(293, 13.5%, 36.3%) */ '#665069',
  BG_GRADIENT_DUSK_2: /* hsl(223, 41%, 69.4%) */ '#91a3d1',
  BG_GRADIENT_EASTER_EGG_1: /* hsl(227, 58.4%, 65.1%) */ '#7289da',
  BG_GRADIENT_EASTER_EGG_2: /* hsl(227, 31%, 44.3%) */ '#4e5d94',
  BG_GRADIENT_FOREST_1: /* hsl(124, 25.9%, 10.6%) */ '#142215',
  BG_GRADIENT_FOREST_2: /* hsl(143, 26.2%, 23.9%) */ '#2d4d39',
  BG_GRADIENT_FOREST_3: /* hsl(76, 20.6%, 24.7%) */ '#454c32',
  BG_GRADIENT_FOREST_4: /* hsl(117, 17%, 41.6%) */ '#5a7c58',
  BG_GRADIENT_FOREST_5: /* hsl(43, 38.5%, 47.8%) */ '#a98e4b',
  BG_GRADIENT_HANAMI_1: /* hsl(352, 68.3%, 80.2%) */ '#efaab3',
  BG_GRADIENT_HANAMI_2: /* hsl(43, 73.6%, 76.3%) */ '#efd696',
  BG_GRADIENT_HANAMI_3: /* hsl(116, 43.1%, 74.5%) */ '#a6daa2',
  BG_GRADIENT_LOFI_VIBES_1: /* hsl(220, 83.8%, 80.6%) */ '#a4c0f7',
  BG_GRADIENT_LOFI_VIBES_2: /* hsl(184, 57.8%, 78.6%) */ '#a9e4e8',
  BG_GRADIENT_LOFI_VIBES_3: /* hsl(130, 46.3%, 78.8%) */ '#b0e2b8',
  BG_GRADIENT_LOFI_VIBES_4: /* hsl(76, 48.8%, 75.5%) */ '#cfdfa2',
  BG_GRADIENT_MARS_1: /* hsl(15, 36.3%, 39.4%) */ '#895240',
  BG_GRADIENT_MARS_2: /* hsl(0, 36.2%, 41.2%) */ '#8f4343',
  BG_GRADIENT_MIDNIGHT_BLURPLE_1: /* hsl(245, 55.1%, 53.7%) */ '#5348ca',
  BG_GRADIENT_MIDNIGHT_BLURPLE_2: /* hsl(259, 74.5%, 10.8%) */ '#140730',
  BG_GRADIENT_MINT_APPLE_1: /* hsl(166, 39.7%, 52.5%) */ '#56b69f',
  BG_GRADIENT_MINT_APPLE_2: /* hsl(119, 40.4%, 55.9%) */ '#63bc61',
  BG_GRADIENT_MINT_APPLE_3: /* hsl(87, 48.3%, 59.8%) */ '#9eca67',
  BG_GRADIENT_NEON_NIGHTS_1: /* hsl(176, 98.8%, 33.1%) */ '#01a89e',
  BG_GRADIENT_NEON_NIGHTS_2: /* hsl(259, 39.5%, 55.3%) */ '#7d60ba',
  BG_GRADIENT_NEON_NIGHTS_3: /* hsl(314, 52.5%, 46.3%) */ '#b43898',
  BG_GRADIENT_RETRO_RAINCLOUD_1: /* hsl(202, 47%, 42.9%) */ '#3a7ca1',
  BG_GRADIENT_RETRO_RAINCLOUD_2: /* hsl(241, 29.6%, 61%) */ '#7f7eb9',
  BG_GRADIENT_RETRO_STORM_1: /* hsl(202, 47%, 42.9%) */ '#3a7ca1',
  BG_GRADIENT_RETRO_STORM_2: /* hsl(241, 27.8%, 47.3%) */ '#58579a',
  BG_GRADIENT_SEPIA_1: /* hsl(33, 14.2%, 45.7%) */ '#857664',
  BG_GRADIENT_SEPIA_2: /* hsl(36, 46.8%, 24.3%) */ '#5b4421',
  BG_GRADIENT_STRAWBERRY_LEMONADE_1: /* hsl(327, 74.1%, 39.4%) */ '#af1a6c',
  BG_GRADIENT_STRAWBERRY_LEMONADE_2: /* hsl(28, 71.7%, 44.3%) */ '#c26b20',
  BG_GRADIENT_STRAWBERRY_LEMONADE_3: /* hsl(40, 80.2%, 52.5%) */ '#e7a525',
  BG_GRADIENT_SUNRISE_1: /* hsl(327, 42%, 43.9%) */ '#9f4175',
  BG_GRADIENT_SUNRISE_2: /* hsl(27, 44.9%, 58%) */ '#c49064',
  BG_GRADIENT_SUNRISE_3: /* hsl(50, 46.3%, 44.5%) */ '#a6953d',
  BG_GRADIENT_SUNSET_1: /* hsl(259, 55.6%, 35.3%) */ '#48288c',
  BG_GRADIENT_SUNSET_2: /* hsl(22, 66.7%, 57.6%) */ '#db7f4b',
  BG_GRADIENT_UNDER_THE_SEA_1: /* hsl(115, 10.5%, 42.9%) */ '#647962',
  BG_GRADIENT_UNDER_THE_SEA_2: /* hsl(159, 20.4%, 43.3%) */ '#588575',
  BG_GRADIENT_UNDER_THE_SEA_3: /* hsl(175, 10.9%, 46.7%) */ '#6a8482',
  BLACK: /* hsl(0, 0%, 0%) */ '#000000',
  BLACK_100: /* hsl(0, 0%, 94.9%) */ '#f2f2f2',
  BLACK_130: /* hsl(0, 0%, 91%) */ '#e8e8e8',
  BLACK_160: /* hsl(0, 0%, 85.5%) */ '#dadada',
  BLACK_200: /* hsl(0, 0%, 80%) */ '#cccccc',
  BLACK_230: /* hsl(0, 0%, 74.1%) */ '#bdbdbd',
  BLACK_260: /* hsl(0, 0%, 67.5%) */ '#acacac',
  BLACK_300: /* hsl(0, 0%, 60%) */ '#999999',
  BLACK_330: /* hsl(0, 0%, 47.8%) */ '#7a7a7a',
  BLACK_345: /* hsl(0, 0%, 40%) */ '#666666',
  BLACK_360: /* hsl(0, 0%, 36.1%) */ '#5c5c5c',
  BLACK_400: /* hsl(0, 0%, 20%) */ '#333333',
  BLACK_430: /* hsl(0, 0%, 14.5%) */ '#252525',
  BLACK_460: /* hsl(0, 0%, 7.8%) */ '#141414',
  BLACK_500: /* hsl(0, 0%, 0%) */ '#000000',
  BLACK_530: /* hsl(0, 0%, 0%) */ '#000000',
  BLACK_560: /* hsl(0, 0%, 0%) */ '#000000',
  BLACK_600: /* hsl(0, 0%, 0%) */ '#000000',
  BLACK_630: /* hsl(0, 0%, 0%) */ '#000000',
  BLACK_660: /* hsl(0, 0%, 0%) */ '#000000',
  BLACK_700: /* hsl(0, 0%, 0%) */ '#000000',
  BLACK_730: /* hsl(0, 0%, 0%) */ '#000000',
  BLACK_760: /* hsl(0, 0%, 0%) */ '#000000',
  BLACK_800: /* hsl(0, 0%, 0%) */ '#000000',
  BLACK_830: /* hsl(0, 0%, 0%) */ '#000000',
  BLACK_860: /* hsl(0, 0%, 0%) */ '#000000',
  BLACK_900: /* hsl(0, 0%, 0%) */ '#000000',
  BLUE_100: /* hsl(210, 80%, 98%) */ '#f6fafe',
  BLUE_130: /* hsl(210, 87.5%, 96.9%) */ '#f0f7fe',
  BLUE_160: /* hsl(209, 87.1%, 93.9%) */ '#e2f0fd',
  BLUE_200: /* hsl(206, 92.3%, 89.8%) */ '#cde8fd',
  BLUE_230: /* hsl(205, 92.5%, 84.3%) */ '#b2ddfc',
  BLUE_260: /* hsl(204, 94.5%, 78.4%) */ '#94d2fc',
  BLUE_300: /* hsl(203, 97.4%, 69.6%) */ '#66c4fd',
  BLUE_330: /* hsl(201, 100%, 59%) */ '#2eb6ff',
  BLUE_345: /* hsl(200, 100%, 49.4%) */ '#00aafc',
  BLUE_360: /* hsl(203, 100%, 47.5%) */ '#0097f2',
  BLUE_400: /* hsl(207, 100%, 46.1%) */ '#0082eb',
  BLUE_430: /* hsl(212, 100%, 45.3%) */ '#006be7',
  BLUE_460: /* hsl(214, 100%, 41%) */ '#005cd1',
  BLUE_500: /* hsl(213, 100%, 35.7%) */ '#0051b6',
  BLUE_530: /* hsl(212, 100%, 30.4%) */ '#00489b',
  BLUE_560: /* hsl(212, 100%, 26.7%) */ '#004088',
  BLUE_600: /* hsl(211, 100%, 23.1%) */ '#003976',
  BLUE_630: /* hsl(211, 100%, 20.8%) */ '#00336a',
  BLUE_660: /* hsl(212, 100%, 18.6%) */ '#002d5f',
  BLUE_700: /* hsl(212, 100%, 16.7%) */ '#002855',
  BLUE_730: /* hsl(211, 100%, 14.1%) */ '#002348',
  BLUE_760: /* hsl(211, 100%, 12.4%) */ '#001e3f',
  BLUE_800: /* hsl(211, 100%, 10.6%) */ '#001a36',
  BLUE_830: /* hsl(213, 100%, 9.4%) */ '#001630',
  BLUE_860: /* hsl(213, 100%, 8.4%) */ '#00132b',
  BLUE_900: /* hsl(213, 100%, 7.1%) */ '#001024',
  BRAND_100: /* hsl(240, 77.8%, 98.2%) */ '#f7f7fe',
  BRAND_130: /* hsl(236, 87.5%, 96.9%) */ '#f0f1fe',
  BRAND_160: /* hsl(235, 84.6%, 94.9%) */ '#e7e9fd',
  BRAND_200: /* hsl(236, 83.3%, 92.9%) */ '#dee0fc',
  BRAND_230: /* hsl(236, 87%, 91%) */ '#d4d7fc',
  BRAND_260: /* hsl(235, 86.2%, 88.6%) */ '#c9cdfb',
  BRAND_300: /* hsl(235, 86.1%, 85.9%) */ '#bcc1fa',
  BRAND_330: /* hsl(235, 85.1%, 81.6%) */ '#a8aff8',
  BRAND_345: /* hsl(235, 85.2%, 78.8%) */ '#9ba3f7',
  BRAND_360: /* hsl(235, 86.1%, 77.5%) */ '#949cf7',
  BRAND_400: /* hsl(235, 86.1%, 71.8%) */ '#7984f5',
  BRAND_430: /* hsl(235, 85.7%, 69.8%) */ '#707bf4',
  BRAND_460: /* hsl(235, 85.5%, 67.5%) */ '#6571f3',
  BRAND_500: /* hsl(235, 85.6%, 64.7%) */ '#5865f2',
  BRAND_530: /* hsl(235, 66.7%, 58.8%) */ '#505cdc',
  BRAND_560: /* hsl(235, 51.4%, 52.4%) */ '#4752c4',
  BRAND_600: /* hsl(235, 46.7%, 44.1%) */ '#3c45a5',
  BRAND_630: /* hsl(235, 46.7%, 38.2%) */ '#343b8f',
  BRAND_660: /* hsl(235, 47.1%, 33.3%) */ '#2d347d',
  BRAND_700: /* hsl(235, 47%, 25.9%) */ '#232861',
  BRAND_730: /* hsl(235, 46.8%, 24.3%) */ '#21265b',
  BRAND_760: /* hsl(234, 46.9%, 22.2%) */ '#1e2353',
  BRAND_800: /* hsl(235, 47.5%, 19.4%) */ '#1a1e49',
  BRAND_830: /* hsl(235, 47.4%, 14.9%) */ '#141738',
  BRAND_860: /* hsl(235, 46.9%, 9.6%) */ '#0d0f24',
  BRAND_900: /* hsl(233, 50%, 3.1%) */ '#04050c',
  BUNGIE: /* hsl(197, 100%, 44.5%) */ '#00a3e3',
  CRUNCHYROLL: /* hsl(29, 93%, 55.5%) */ '#f78b24',
  EBAY: /* hsl(211, 100%, 41.2%) */ '#0064d2',
  EPIC_GAMES: /* hsl(34, 6.2%, 22.2%) */ '#3c3935',
  FACEBOOK: /* hsl(221, 44.2%, 37.3%) */ '#355089',
  GITHUB: /* hsl(0, 4.2%, 9.4%) */ '#191717',
  GOLD: /* hsl(46, 100%, 54.9%) */ '#ffc819',
  GREEN_100: /* hsl(137, 90%, 96.1%) */ '#ecfef1',
  GREEN_130: /* hsl(137, 94.1%, 93.3%) */ '#defee7',
  GREEN_160: /* hsl(141, 91.3%, 86.5%) */ '#bdfcd3',
  GREEN_200: /* hsl(143, 93.5%, 75.9%) */ '#88fbb5',
  GREEN_230: /* hsl(146, 86.6%, 64.9%) */ '#58f39c',
  GREEN_260: /* hsl(146, 75.1%, 59%) */ '#48e58b',
  GREEN_300: /* hsl(146, 65.4%, 53.5%) */ '#3bd67f',
  GREEN_330: /* hsl(146, 63.1%, 47.8%) */ '#2dc771',
  GREEN_345: /* hsl(147, 65.9%, 43.7%) */ '#26b968',
  GREEN_360: /* hsl(145, 65%, 39.2%) */ '#23a55a',
  GREEN_400: /* hsl(143, 60.7%, 35.9%) */ '#24934e',
  GREEN_430: /* hsl(142, 56.1%, 32.2%) */ '#248045',
  GREEN_460: /* hsl(141, 56.6%, 28%) */ '#1f703c',
  GREEN_500: /* hsl(141, 58.4%, 24.5%) */ '#1a6334',
  GREEN_530: /* hsl(140, 60.7%, 21%) */ '#15562b',
  GREEN_560: /* hsl(139, 61.7%, 18.4%) */ '#124c24',
  GREEN_600: /* hsl(139, 65.4%, 15.9%) */ '#0e431f',
  GREEN_630: /* hsl(140, 66.7%, 14.1%) */ '#0c3c1c',
  GREEN_660: /* hsl(139, 68.8%, 12.5%) */ '#0a3618',
  GREEN_700: /* hsl(141, 74.1%, 10.6%) */ '#072f15',
  GREEN_730: /* hsl(138, 78.3%, 9%) */ '#052910',
  GREEN_760: /* hsl(140, 84.6%, 7.6%) */ '#03240e',
  GREEN_800: /* hsl(139, 82.4%, 6.7%) */ '#031f0c',
  GREEN_830: /* hsl(138, 80%, 5.9%) */ '#031b0a',
  GREEN_860: /* hsl(133, 70.4%, 5.3%) */ '#041708',
  GREEN_900: /* hsl(129, 58.3%, 4.7%) */ '#051307',
  GUILD_BOOSTING_BLUE: /* hsl(221, 70%, 55.5%) */ '#3e70dd',
  GUILD_BOOSTING_BLUE_FOR_GRADIENTS: /* hsl(235, 68.5%, 52.7%) */ '#3442d9',
  GUILD_BOOSTING_PINK: /* hsl(302, 100%, 72.5%) */ '#ff73fa',
  GUILD_BOOSTING_PURPLE: /* hsl(269, 83.8%, 71%) */ '#b377f3',
  GUILD_BOOSTING_PURPLE_FOR_GRADIENTS: /* hsl(269, 91.8%, 61.8%) */ '#9b44f7',
  HYPESQUAD_HOUSE_1: /* hsl(253, 77%, 72.7%) */ '#9c84ef',
  HYPESQUAD_HOUSE_2: /* hsl(9, 86.5%, 68%) */ '#f47b67',
  HYPESQUAD_HOUSE_3: /* hsl(169, 69.1%, 56.9%) */ '#45ddc0',
  INPUT_PLACEHOLDER_TEXT_DARK: /* hsl(216, 2.1%, 53.9%) */ '#87898c',
  LOL: /* hsl(190, 89.7%, 7.6%) */ '#021f25',
  ORANGE_100: /* hsl(18, 100%, 98%) */ '#fff8f5',
  ORANGE_130: /* hsl(23, 100%, 96.5%) */ '#fff4ed',
  ORANGE_160: /* hsl(21, 93.9%, 93.5%) */ '#feeadf',
  ORANGE_200: /* hsl(21, 92.3%, 89.8%) */ '#fddecd',
  ORANGE_230: /* hsl(24, 92.5%, 84.3%) */ '#fccfb2',
  ORANGE_260: /* hsl(23, 94.5%, 78.6%) */ '#fcbd95',
  ORANGE_300: /* hsl(25, 94.6%, 71.2%) */ '#fbab70',
  ORANGE_330: /* hsl(27, 94.7%, 62.7%) */ '#fa9746',
  ORANGE_345: /* hsl(28, 87.7%, 55.5%) */ '#f1882a',
  ORANGE_360: /* hsl(26, 71.3%, 50.8%) */ '#db7628',
  ORANGE_400: /* hsl(25, 67.5%, 45.9%) */ '#c46926',
  ORANGE_430: /* hsl(25, 69.5%, 39.8%) */ '#ac591f',
  ORANGE_460: /* hsl(24, 72.2%, 35.3%) */ '#9b4c19',
  ORANGE_500: /* hsl(22, 76.1%, 31.2%) */ '#8c4013',
  ORANGE_530: /* hsl(22, 77%, 27.3%) */ '#7b3710',
  ORANGE_560: /* hsl(21, 77.2%, 24.1%) */ '#6d300e',
  ORANGE_600: /* hsl(23, 79.2%, 20.8%) */ '#5f2b0b',
  ORANGE_630: /* hsl(22, 79.2%, 18.8%) */ '#56260a',
  ORANGE_660: /* hsl(22, 78.8%, 16.7%) */ '#4c2209',
  ORANGE_700: /* hsl(22, 76.3%, 14.9%) */ '#431e09',
  ORANGE_730: /* hsl(22, 78.8%, 12.9%) */ '#3b1a07',
  ORANGE_760: /* hsl(21, 78.9%, 11.2%) */ '#331606',
  ORANGE_800: /* hsl(21, 80%, 9.8%) */ '#2d1305',
  ORANGE_830: /* hsl(20, 76.7%, 8.4%) */ '#261005',
  ORANGE_860: /* hsl(20, 73%, 7.3%) */ '#200e05',
  ORANGE_900: /* hsl(26, 72.4%, 5.7%) */ '#190d04',
  PARTNER: /* hsl(215, 82.8%, 59%) */ '#4087ed',
  PAYPAL: /* hsl(230, 69.8%, 24.7%) */ '#13216b',
  PLAYSTATION: /* hsl(220, 95.7%, 27.1%) */ '#032f87',
  PLUM_23_ALPHA: /* hsl(240, 63.4%, 83.9%) */ '#BCBCF0',
  PLUM_0: /* hsl(240, 9.1%, 97.8%) */ '#f9f9fa',
  PLUM_1: /* hsl(240, 4.3%, 95.5%) */ '#f3f3f4',
  PLUM_10: /* hsl(229, 6.8%, 53.7%) */ '#818491',
  PLUM_11: /* hsl(229, 6.9%, 45.5%) */ '#6c6f7c',
  PLUM_12: /* hsl(228, 7.6%, 38.6%) */ '#5b5e6a',
  PLUM_13: /* hsl(227, 8.3%, 32.9%) */ '#4d505b',
  PLUM_14: /* hsl(230, 8.5%, 27.8%) */ '#41434d',
  PLUM_15: /* hsl(229, 8.9%, 24.1%) */ '#383a43',
  PLUM_16: /* hsl(234, 9.3%, 21.2%) */ '#31323b',
  PLUM_17: /* hsl(233, 9.3%, 19%) */ '#2c2d35',
  PLUM_18: /* hsl(233, 10.6%, 16.7%) */ '#26272f',
  PLUM_19: /* hsl(233, 10.8%, 14.5%) */ '#212229',
  PLUM_2: /* hsl(220, 8.6%, 93.1%) */ '#ecedef',
  PLUM_20: /* hsl(231, 11.1%, 12.4%) */ '#1c1d23',
  PLUM_21: /* hsl(231, 12.7%, 10.8%) */ '#18191f',
  PLUM_22: /* hsl(240, 12%, 9.8%) */ '#16161c',
  PLUM_23: /* hsl(240, 11.6%, 8.4%) */ '#131318',
  PLUM_24: /* hsl(228, 14.3%, 6.9%) */ '#0f1014',
  PLUM_25: /* hsl(240, 14.3%, 5.5%) */ '#0c0c10',
  PLUM_26: /* hsl(240, 15.8%, 3.7%) */ '#08080b',
  PLUM_3: /* hsl(225, 8%, 90.2%) */ '#e4e5e8',
  PLUM_4: /* hsl(225, 6.2%, 87.5%) */ '#dddee1',
  PLUM_5: /* hsl(220, 7.3%, 83.9%) */ '#d3d5d9',
  PLUM_6: /* hsl(231, 6.7%, 79.4%) */ '#c7c8ce',
  PLUM_7: /* hsl(227, 6.8%, 73.9%) */ '#b8bac1',
  PLUM_8: /* hsl(230, 7.4%, 68.2%) */ '#a8aab4',
  PLUM_9: /* hsl(231, 7.1%, 61.2%) */ '#9597a3',
  PREMIUM_NITRO_PINK_DARK: /* hsl(299, 43.1%, 50.4%) */ '#b64ab7',
  PREMIUM_NITRO_PINK_LIGHT: /* hsl(299, 42.9%, 58.8%) */ '#c269c3',
  PREMIUM_PERK_BLUE: /* hsl(222, 100%, 75.1%) */ '#80a6ff',
  PREMIUM_PERK_BLUE_ALT: /* hsl(223, 100%, 80.6%) */ '#9cb8ff',
  PREMIUM_PERK_DARK_BLUE: /* hsl(220, 67.4%, 55.5%) */ '#4173da',
  PREMIUM_PERK_GOLD: /* hsl(38, 95.7%, 54.1%) */ '#faa61a',
  PREMIUM_PERK_GREEN: /* hsl(164, 55.1%, 69.4%) */ '#86dcc5',
  PREMIUM_PERK_LIGHT_BLUE: /* hsl(221, 100%, 84.1%) */ '#aec7ff',
  PREMIUM_PERK_ORANGE: /* hsl(25, 96.7%, 64.1%) */ '#fc964b',
  PREMIUM_PERK_PINK: /* hsl(305, 100%, 75.1%) */ '#ff80f4',
  PREMIUM_PERK_PURPLE: /* hsl(272, 100%, 80.2%) */ '#d09aff',
  PREMIUM_PERK_YELLOW: /* hsl(47, 98.9%, 63.9%) */ '#fed648',
  PREMIUM_TIER_0_BLUE: /* hsl(202, 100%, 38%) */ '#007cc2',
  PREMIUM_TIER_0_BLUE_FOR_GRADIENTS: /* hsl(202, 100%, 38%) */ '#007cc2',
  PREMIUM_TIER_0_BLUE_FOR_GRADIENTS_2: /* hsl(209, 80%, 45.1%) */ '#1776cf',
  PREMIUM_TIER_0_HEADER_GRADIENT_1: /* hsl(240, 55.2%, 47.3%) */ '#3736bb',
  PREMIUM_TIER_0_HEADER_GRADIENT_2: /* hsl(224, 77.9%, 59.2%) */ '#4670e8',
  PREMIUM_TIER_0_HEADER_GRADIENT_3: /* hsl(246, 74.4%, 69.4%) */ '#8377eb',
  PREMIUM_TIER_0_HEADER_GRADIENT_4: /* hsl(295, 79.9%, 72.7%) */ '#e782f1',
  PREMIUM_TIER_0_HEADER_GRADIENT_5: /* hsl(336, 55.2%, 72%) */ '#df90af',
  PREMIUM_TIER_0_PURPLE: /* hsl(235, 85.6%, 64.7%) */ '#5865f2',
  PREMIUM_TIER_0_PURPLE_FOR_GRADIENTS: /* hsl(235, 85.6%, 64.7%) */ '#5865f2',
  PREMIUM_TIER_1_BLUE: /* hsl(228, 86.7%, 70.6%) */ '#738ef5',
  PREMIUM_TIER_1_BLUE_FOR_GRADIENTS: /* hsl(235, 85.6%, 64.7%) */ '#5865f2',
  PREMIUM_TIER_1_DARK_BLUE_FOR_GRADIENTS: /* hsl(235, 68.5%, 52.7%) */ '#3442d9',
  PREMIUM_TIER_1_PURPLE: /* hsl(244, 100%, 84.1%) */ '#b3aeff',
  PREMIUM_TIER_2_PINK: /* hsl(342, 58%, 72.9%) */ '#e292aa',
  PREMIUM_TIER_2_PINK_FOR_GRADIENTS: /* hsl(325, 31.7%, 51.8%) */ '#ab5d8a',
  PREMIUM_TIER_2_PINK_FOR_GRADIENTS_2: /* hsl(295, 51.4%, 50%) */ '#b73ec1',
  PREMIUM_TIER_2_PURPLE: /* hsl(270, 86.7%, 70.6%) */ '#b473f5',
  PREMIUM_TIER_2_PURPLE_FOR_GRADIENTS: /* hsl(269, 52.7%, 52.7%) */ '#8547c6',
  PREMIUM_TIER_2_PURPLE_FOR_GRADIENTS_2: /* hsl(296, 50%, 51.4%) */ '#b845c1',
  PRIMARY_100: /* hsl(0, 0%, 97.6%) */ '#f9f9f9',
  PRIMARY_130: /* hsl(220, 13%, 95.5%) */ '#f2f3f5',
  PRIMARY_160: /* hsl(210, 11.1%, 92.9%) */ '#ebedef',
  PRIMARY_200: /* hsl(216, 9.8%, 90%) */ '#e3e5e8',
  PRIMARY_230: /* hsl(210, 9.1%, 87.1%) */ '#dbdee1',
  PRIMARY_260: /* hsl(214, 8.4%, 83.7%) */ '#d2d5d9',
  PRIMARY_300: /* hsl(210, 9.3%, 78.8%) */ '#c4c9ce',
  PRIMARY_330: /* hsl(215, 8.8%, 73.3%) */ '#b5bac1',
  PRIMARY_345: /* hsl(214, 8.4%, 67.5%) */ '#a5abb3',
  PRIMARY_360: /* hsl(214, 8.1%, 61.2%) */ '#949ba4',
  PRIMARY_400: /* hsl(223, 5.8%, 52.9%) */ '#80848e',
  PRIMARY_430: /* hsl(229, 4.8%, 44.9%) */ '#6d6f78',
  PRIMARY_460: /* hsl(228, 5.2%, 38%) */ '#5c5e66',
  PRIMARY_500: /* hsl(228, 6%, 32.5%) */ '#4e5058',
  PRIMARY_530: /* hsl(227, 6.5%, 27.3%) */ '#41434a',
  PRIMARY_560: /* hsl(225, 6.7%, 23.5%) */ '#383a40',
  PRIMARY_600: /* hsl(223, 6.7%, 20.6%) */ '#313338',
  PRIMARY_630: /* hsl(220, 6.5%, 18%) */ '#2b2d31',
  PRIMARY_645: /* hsl(220, 7%, 16.9%) */ '#282a2e',
  PRIMARY_660: /* hsl(228, 6.7%, 14.7%) */ '#232428',
  PRIMARY_700: /* hsl(225, 6.3%, 12.5%) */ '#1e1f22',
  PRIMARY_730: /* hsl(225, 7.1%, 11%) */ '#1a1b1e',
  PRIMARY_760: /* hsl(220, 6.4%, 9.2%) */ '#161719',
  PRIMARY_800: /* hsl(220, 8.1%, 7.3%) */ '#111214',
  PRIMARY_830: /* hsl(240, 4%, 4.9%) */ '#0c0c0d',
  PRIMARY_860: /* hsl(240, 7.7%, 2.5%) */ '#060607',
  PRIMARY_900: /* hsl(0, 0%, 0.8%) */ '#020202',
  RED_100: /* hsl(350, 75%, 98.4%) */ '#fef8f9',
  RED_130: /* hsl(0, 83.3%, 97.6%) */ '#fef4f4',
  RED_160: /* hsl(0, 90.9%, 95.7%) */ '#feeaea',
  RED_200: /* hsl(358, 88.9%, 92.9%) */ '#fdddde',
  RED_230: /* hsl(359, 85.2%, 89.4%) */ '#fbcdce',
  RED_260: /* hsl(359, 89%, 85.7%) */ '#fbbabb',
  RED_300: /* hsl(359, 91.4%, 81.8%) */ '#fba6a8',
  RED_330: /* hsl(359, 91.3%, 77.5%) */ '#fa9193',
  RED_345: /* hsl(358, 92.9%, 72.4%) */ '#fa777b',
  RED_360: /* hsl(358, 91.8%, 66.5%) */ '#f85b5f',
  RED_400: /* hsl(359, 87.3%, 59.8%) */ '#f23f43',
  RED_430: /* hsl(358, 68.8%, 53.5%) */ '#da373c',
  RED_460: /* hsl(359, 59.1%, 46.1%) */ '#bb3033',
  RED_500: /* hsl(360, 60.2%, 39.4%) */ '#a12829',
  RED_530: /* hsl(359, 63.4%, 34.3%) */ '#8f2022',
  RED_560: /* hsl(359, 63.9%, 30.4%) */ '#7f1c1e',
  RED_600: /* hsl(359, 64.7%, 26.7%) */ '#70181a',
  RED_630: /* hsl(359, 65.6%, 23.9%) */ '#651517',
  RED_660: /* hsl(359, 65.1%, 21.4%) */ '#5a1314',
  RED_700: /* hsl(358, 66.7%, 18.8%) */ '#501012',
  RED_730: /* hsl(359, 66.7%, 16.5%) */ '#460e0f',
  RED_760: /* hsl(359, 70.3%, 14.5%) */ '#3f0b0c',
  RED_800: /* hsl(359, 68.8%, 12.5%) */ '#360a0b',
  RED_830: /* hsl(358, 67.3%, 10.8%) */ '#2e090a',
  RED_860: /* hsl(0, 66.7%, 9.4%) */ '#280808',
  RED_900: /* hsl(0, 70%, 7.8%) */ '#220606',
  REDDIT: /* hsl(16, 100%, 50%) */ '#ff4500',
  RIOT_GAMES: /* hsl(349, 100%, 45.9%) */ '#ea0029',
  ROLE_BLUE: /* hsl(204, 64.4%, 35.3%) */ '#206694',
  ROLE_BROWN: /* hsl(24, 100%, 32.9%) */ '#a84300',
  ROLE_BURGUNDY: /* hsl(334, 79.3%, 37.8%) */ '#ad1457',
  ROLE_DARK_BLUE: /* hsl(199, 18.4%, 40.4%) */ '#546e7a',
  ROLE_DARK_GREY: /* hsl(200, 18.3%, 46.1%) */ '#607d8b',
  ROLE_DARK_PURPLE: /* hsl(282, 43.7%, 37.6%) */ '#71368a',
  ROLE_DARK_TEAL: /* hsl(168, 76.6%, 28.4%) */ '#11806a',
  ROLE_DEFAULT: /* hsl(204, 15.9%, 65.5%) */ '#99aab5',
  ROLE_GREEN: /* hsl(145, 63.5%, 33.3%) */ '#1f8b4c',
  ROLE_GREY: /* hsl(202, 4%, 60.8%) */ '#979c9f',
  ROLE_LIGHT_BLUE: /* hsl(204, 15.9%, 65.5%) */ '#99aab5',
  ROLE_LIGHT_GREEN: /* hsl(145, 63.2%, 49%) */ '#2ecc71',
  ROLE_LIGHT_GREY: /* hsl(184, 8.7%, 61.8%) */ '#95a5a6',
  ROLE_MAGENTA: /* hsl(340, 82.2%, 51.6%) */ '#e91e63',
  ROLE_ORANGE: /* hsl(28, 79.7%, 51.8%) */ '#e67e22',
  ROLE_PURPLE: /* hsl(283, 38.9%, 53.1%) */ '#9b59b6',
  ROLE_SALMON: /* hsl(6, 78.1%, 57.1%) */ '#e74c3c',
  ROLE_SKY_BLUE: /* hsl(204, 69.9%, 53.1%) */ '#3498db',
  ROLE_TAN: /* hsl(37, 86.5%, 40.8%) */ '#c27c0e',
  ROLE_TEAL: /* hsl(168, 75.7%, 42%) */ '#1abc9c',
  ROLE_TERRACOTTA: /* hsl(6, 63.6%, 36.7%) */ '#992d22',
  ROLE_YELLOW: /* hsl(48, 89%, 50.2%) */ '#f1c40f',
  SAMSUNG: /* hsl(231, 77.8%, 35.3%) */ '#1429a0',
  SKYPE: /* hsl(196, 100%, 42.2%) */ '#009dd7',
  SPOTIFY: /* hsl(141, 72.9%, 42%) */ '#1db954',
  STEAM: /* hsl(215, 35.1%, 14.5%) */ '#182332',
  TEAL_100: /* hsl(193, 69.2%, 97.5%) */ '#f4fbfd',
  TEAL_130: /* hsl(192, 83.3%, 95.3%) */ '#e9f9fd',
  TEAL_160: /* hsl(190, 83.3%, 90.6%) */ '#d3f4fb',
  TEAL_200: /* hsl(188, 85.7%, 83.5%) */ '#b1eff9',
  TEAL_230: /* hsl(188, 88.3%, 73.1%) */ '#7ee7f7',
  TEAL_260: /* hsl(188, 81.9%, 65.3%) */ '#5edbef',
  TEAL_300: /* hsl(189, 72.8%, 58.2%) */ '#47cbe2',
  TEAL_330: /* hsl(189, 65.6%, 52.2%) */ '#35bcd5',
  TEAL_345: /* hsl(190, 62.8%, 48.4%) */ '#2eb0c9',
  TEAL_360: /* hsl(190, 64%, 43.5%) */ '#289fb6',
  TEAL_400: /* hsl(190, 63.5%, 38.6%) */ '#248da1',
  TEAL_430: /* hsl(190, 63%, 33.9%) */ '#207a8d',
  TEAL_460: /* hsl(191, 64.2%, 29.6%) */ '#1b6b7c',
  TEAL_500: /* hsl(190, 65.2%, 25.9%) */ '#175e6d',
  TEAL_530: /* hsl(190, 66.7%, 22.4%) */ '#13525f',
  TEAL_560: /* hsl(190, 69.7%, 19.4%) */ '#0f4954',
  TEAL_600: /* hsl(189, 71.8%, 16.7%) */ '#0c4049',
  TEAL_630: /* hsl(190, 73.7%, 14.9%) */ '#0a3942',
  TEAL_660: /* hsl(188, 75.8%, 12.9%) */ '#08333a',
  TEAL_700: /* hsl(189, 79.3%, 11.4%) */ '#062d34',
  TEAL_730: /* hsl(189, 80%, 9.8%) */ '#05272d',
  TEAL_760: /* hsl(189, 81.4%, 8.4%) */ '#042227',
  TEAL_800: /* hsl(188, 83.3%, 7.1%) */ '#031d21',
  TEAL_830: /* hsl(189, 87.1%, 6.1%) */ '#02191d',
  TEAL_860: /* hsl(188, 85.2%, 5.3%) */ '#021619',
  TEAL_900: /* hsl(189, 90.9%, 4.3%) */ '#011215',
  TRANSPARENT: /* hsl(0, 0%, 0%) */ '#00000000',
  TWITCH: /* hsl(262, 46.8%, 39.8%) */ '#593695',
  TWITTER: /* hsl(203, 89.1%, 53.1%) */ '#1da1f2',
  WHITE: /* hsl(0, 0%, 100%) */ '#ffffff',
  WHITE_100: /* hsl(0, 0%, 100%) */ '#ffffff',
  WHITE_130: /* hsl(0, 0%, 100%) */ '#ffffff',
  WHITE_160: /* hsl(0, 0%, 100%) */ '#ffffff',
  WHITE_200: /* hsl(0, 0%, 100%) */ '#ffffff',
  WHITE_230: /* hsl(0, 0%, 100%) */ '#ffffff',
  WHITE_260: /* hsl(0, 0%, 100%) */ '#ffffff',
  WHITE_300: /* hsl(0, 0%, 100%) */ '#ffffff',
  WHITE_330: /* hsl(0, 0%, 100%) */ '#ffffff',
  WHITE_345: /* hsl(0, 0%, 100%) */ '#ffffff',
  WHITE_360: /* hsl(0, 0%, 100%) */ '#ffffff',
  WHITE_400: /* hsl(0, 0%, 100%) */ '#ffffff',
  WHITE_430: /* hsl(0, 0%, 100%) */ '#ffffff',
  WHITE_460: /* hsl(0, 0%, 100%) */ '#ffffff',
  WHITE_500: /* hsl(0, 0%, 100%) */ '#ffffff',
  WHITE_530: /* hsl(0, 0%, 91%) */ '#e8e8e8',
  WHITE_560: /* hsl(0, 0%, 81.2%) */ '#cfcfcf',
  WHITE_600: /* hsl(0, 0%, 67.8%) */ '#adadad',
  WHITE_630: /* hsl(0, 0%, 58.8%) */ '#969696',
  WHITE_660: /* hsl(0, 0%, 51.4%) */ '#838383',
  WHITE_700: /* hsl(0, 0%, 40%) */ '#666666',
  WHITE_730: /* hsl(0, 0%, 37.3%) */ '#5f5f5f',
  WHITE_760: /* hsl(0, 0%, 34.5%) */ '#585858',
  WHITE_800: /* hsl(0, 0%, 30.2%) */ '#4d4d4d',
  WHITE_830: /* hsl(0, 0%, 23.1%) */ '#3b3b3b',
  WHITE_860: /* hsl(0, 0%, 14.9%) */ '#262626',
  WHITE_900: /* hsl(0, 0%, 5.1%) */ '#0d0d0d',
  XBOX: /* hsl(120, 77.1%, 27.5%) */ '#107c10',
  YELLOW_100: /* hsl(34, 100%, 96.9%) */ '#fff8ef',
  YELLOW_130: /* hsl(31, 100%, 95.5%) */ '#fff4e8',
  YELLOW_160: /* hsl(33, 100%, 91.4%) */ '#ffebd3',
  YELLOW_200: /* hsl(35, 97.3%, 85.5%) */ '#fee0b6',
  YELLOW_230: /* hsl(37, 96.6%, 77.1%) */ '#fdd18c',
  YELLOW_260: /* hsl(41, 96.8%, 62.9%) */ '#fcc145',
  YELLOW_300: /* hsl(40, 86.4%, 56.9%) */ '#f0b232',
  YELLOW_330: /* hsl(40, 75.3%, 52.4%) */ '#e1a42a',
  YELLOW_345: /* hsl(40, 71%, 48.6%) */ '#d49824',
  YELLOW_360: /* hsl(39, 74.4%, 42.9%) */ '#bf861c',
  YELLOW_400: /* hsl(38, 78.6%, 38.4%) */ '#af7615',
  YELLOW_430: /* hsl(37, 84.4%, 32.7%) */ '#9a650d',
  YELLOW_460: /* hsl(36, 87.8%, 28.8%) */ '#8a5709',
  YELLOW_500: /* hsl(36, 93.8%, 25.1%) */ '#7c4b04',
  YELLOW_530: /* hsl(35, 92.9%, 22.2%) */ '#6d4104',
  YELLOW_560: /* hsl(34, 94%, 19.6%) */ '#613803',
  YELLOW_600: /* hsl(35, 93.1%, 17.1%) */ '#543203',
  YELLOW_630: /* hsl(35, 92.4%, 15.5%) */ '#4c2d03',
  YELLOW_660: /* hsl(35, 91.4%, 13.7%) */ '#432803',
  YELLOW_700: /* hsl(34, 90.3%, 12.2%) */ '#3b2303',
  YELLOW_730: /* hsl(33, 92.7%, 10.8%) */ '#351e02',
  YELLOW_760: /* hsl(33, 91.7%, 9.4%) */ '#2e1a02',
  YELLOW_800: /* hsl(32, 90.2%, 8%) */ '#271602',
  YELLOW_830: /* hsl(32, 88.9%, 7.1%) */ '#221302',
  YELLOW_860: /* hsl(32, 86.7%, 5.9%) */ '#1c1002',
  YELLOW_900: /* hsl(36, 83.3%, 4.7%) */ '#160e02',
  YOUTUBE: /* hsl(0, 72.8%, 46.1%) */ '#cb2120',
};
type RawColors = typeof RawColors;

export const _private = {
  SemanticColors,
  RawColors,
};
