import * as React from 'react';
import classNames from 'classnames';

import styles from './Flex.module.css';
import flexStyles from '@developers/styles/common/Flex.module.css';

export interface FlexProps extends React.HTMLAttributes<HTMLDivElement> {
  direction?: string;
  justify?: string;
  align?: string;
  wrap?: string;
  shrink?: number | string;
  grow?: number | string;
  basis?: number | string;
  gutter?: string;
  innerRef?: React.Ref<HTMLDivElement>;
}

export interface FlexChildProps {
  className?: string;
  style?: React.CSSProperties;
  shrink?: number | string;
  grow?: number | string;
  basis?: number | string;
  wrap?: boolean;
  children?: React.ReactNode;
  onClick?: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
}

const Direction = {
  VERTICAL: flexStyles.flexVertical,
  HORIZONTAL: flexStyles.flexHorizontal,
  HORIZONTAL_REVERSE: flexStyles.flexHorizontalReverse,
};

const Justify = {
  START: flexStyles.flexJustifyStart,
  END: flexStyles.flexJustifyEnd,
  CENTER: flexStyles.flexJustifyCenter,
  BETWEEN: flexStyles.flexJustifyBetween,
  AROUND: flexStyles.flexJustifyAround,
};

const Wrap = {
  NO_WRAP: flexStyles.flexNowrap,
  WRAP: flexStyles.flexWrap,
  WRAP_REVERSE: flexStyles.flexWrapReverse,
};

const Align = {
  START: flexStyles.flexAlignStart,
  END: flexStyles.flexAlignEnd,
  CENTER: flexStyles.flexAlignCenter,
  STRETCH: flexStyles.flexAlignStretch,
  BASELINE: flexStyles.flexAlignBaseline,
};

const Gutter = {
  SMALL: styles.flexGutterSmall,
  LARGE: styles.flexGutterLarge,
};

function FlexChild({
  className = styles.flexChild,
  style = {},
  wrap = false,
  children,
  shrink: flexShrink,
  grow: flexGrow,
  basis: flexBasis,
  ...props
}: FlexChildProps) {
  const newProps = {
    style: {flexGrow, flexShrink, flexBasis, ...style},
    className: className === styles.flexChild ? className : classNames(styles.flexChild, className),
    ...props,
  };

  if (!wrap && typeof children !== 'string' && React.Children.count(children) === 1) {
    const child = React.Children.only(children) as React.ReactElement;
    // Merge style and className
    newProps.style = {...newProps.style, ...child.props.style};
    newProps.className = classNames(child.props.className, className);
    return React.cloneElement(child, newProps);
  }
  return <div {...newProps}>{children}</div>;
}

export default function Flex({
  direction = Direction.HORIZONTAL,
  justify = Justify.START,
  align = Align.STRETCH,
  wrap = Wrap.WRAP,
  shrink: flexShrink = 1,
  grow: flexGrow = 1,
  basis: flexBasis = 'auto',
  style = {},
  children,
  className,
  innerRef,
  gutter,
  ...props
}: FlexProps) {
  const newStyle = {flexShrink, flexGrow, flexBasis, ...style};
  return (
    <div
      style={newStyle}
      className={classNames(styles.flex, direction, justify, align, wrap, gutter, className)}
      ref={innerRef}
      {...props}>
      {children}
    </div>
  );
}

Flex.Child = FlexChild;
Flex.Direction = Direction;
Flex.Align = Align;
Flex.Justify = Justify;
Flex.Wrap = Wrap;
Flex.Gutter = Gutter;
