/* stylelint-disable discord/var-validator */
/* THIS IS AN AUTOGENERATED FILE. DO NOT EDIT THIS FILE DIRECTLY
If you need to add or edit existing variables, check out
discord_common/js/packages/tokens/definitions, then run
`clyde mana tokens generate` */

import {_private as themeDefinitions} from '../../themes/generated/generated-definitions';

const {Themes} = themeDefinitions;
type Themes = typeof Themes;

import type {SemanticColors} from './generated-definitions';

export type ExperimentName = 'visual-refresh';

type SemanticColorName = keyof SemanticColors;

type ColorDefinition = Partial<SemanticColors[string]>;

type SemanticColorExperimentMap = {
  [K in SemanticColorName]?: {
    [E in ExperimentName]?: Partial<ColorDefinition>;
  };
};

const SemanticColorExperiments: SemanticColorExperimentMap = {
  BG_MOD_STRONG: {['visual-refresh']: {[Themes.LIGHT]: {raw: 'PRIMARY_400', opacity: 0.24}}},
};

export const _private = {SemanticColorExperiments};
