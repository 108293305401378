import * as React from 'react';
import {
  ConfettiCannon,
  useConfettiCannon,
  CreateConfettiArgs,
  CreateConfettiRequestedOptions,
  Confetti,
  ConfettiCanvasHandle,
  SpriteCanvasHandle,
} from 'confetti-cannon';

import styles from './CommonConfettiContext.module.css';
const DEFAULT_NUM_TO_FIRE = 20;

export type CanvasClickListener = (e: MouseEvent, confetti: Confetti | null) => void;

type CreateConfettiArgsBase = Partial<CreateConfettiArgs> & Pick<CreateConfettiArgs, 'size'>;
type CreateConfettiArgsMinusBase = Omit<CreateConfettiArgs, 'size'>;
type CreateConfettiAtArgs = Omit<CreateConfettiArgsMinusBase, 'position'>;

interface ConfettiCannonContextType {
  confettiCanvas: ConfettiCanvasHandle | null;
  cannon: ConfettiCannon | null;
  createConfetti: (
    createConfettiArgs: CreateConfettiArgsMinusBase,
    requestedOptions?: CreateConfettiRequestedOptions,
  ) => Confetti | undefined;
  createConfettiAt: (
    x: number,
    y: number,
    createConfettiArgs?: CreateConfettiAtArgs,
    requestedOptions?: CreateConfettiRequestedOptions,
  ) => Confetti | undefined;
  createMultipleConfetti: (
    createConfettiArgs?: CreateConfettiArgsMinusBase,
    numberToFire?: number,
    requestedOptions?: CreateConfettiRequestedOptions,
  ) => Confetti[];
  createMultipleConfettiAt: (
    x: number,
    y: number,
    createConfettiArgs?: CreateConfettiAtArgs,
    numberToFire?: number,
    requestedOptions?: CreateConfettiRequestedOptions,
  ) => Confetti[];
  createMultipleCoins: (
    createConfettiArgs?: CreateConfettiArgsMinusBase,
    numberToFire?: number,
    requestedOptions?: CreateConfettiRequestedOptions,
  ) => Confetti[];
  addClickListener: (listener: CanvasClickListener) => void;
  removeClickListener: (listener: CanvasClickListener) => void;
  defaultConfettiContainerRef: React.RefObject<HTMLDivElement | null>;
}

const DEFAULT_CONTEXT_VALUE: ConfettiCannonContextType = {
  confettiCanvas: null,
  cannon: null,
  createConfetti: () => undefined,
  createConfettiAt: () => undefined,
  createMultipleConfetti: () => [],
  createMultipleConfettiAt: () => [],
  createMultipleCoins: () => [],
  addClickListener: () => () => null,
  removeClickListener: () => null,
  defaultConfettiContainerRef: {current: null},
};

export const ConfettiCannonContext = React.createContext<ConfettiCannonContextType>(DEFAULT_CONTEXT_VALUE);

interface ConfettiCannonContextProviderProps {
  children: React.ReactNode;
  confettiCanvas: ConfettiCanvasHandle | null;
  confettiSpriteCanvas: SpriteCanvasHandle | null;
  coinSpriteCanvas: SpriteCanvasHandle | null;
  baseConfig: CreateConfettiArgsBase;
  addClickListener: (listener: CanvasClickListener) => () => void;
  removeClickListener: (listener: CanvasClickListener) => void;
}

export function ConfettiCannonContextProvider({
  children,
  confettiCanvas,
  confettiSpriteCanvas,
  coinSpriteCanvas,
  baseConfig,
  addClickListener,
  removeClickListener,
}: ConfettiCannonContextProviderProps) {
  const cannon = useConfettiCannon(confettiCanvas, confettiSpriteCanvas);
  const coinCannon = useConfettiCannon(confettiCanvas, coinSpriteCanvas);
  const reducedMotion = window.matchMedia('(prefers-reduced-motion: reduce)').matches;
  const defaultConfettiContainerRef = React.useRef<HTMLDivElement>(null);

  const value: ConfettiCannonContextType = React.useMemo(() => {
    if (reducedMotion) {
      return DEFAULT_CONTEXT_VALUE;
    }

    return {
      confettiCanvas: confettiCanvas,
      cannon,
      createConfetti: (createConfettiArgs, requestedOptions?) =>
        cannon.createConfetti({...baseConfig, ...createConfettiArgs}, requestedOptions),
      createConfettiAt: (x, y, createConfettiArgs, requestedOptions) =>
        cannon.createConfetti(
          {...baseConfig, position: {type: 'static', value: {x, y}}, ...createConfettiArgs},
          requestedOptions,
        ),
      createMultipleConfetti: (createConfettiArgs, numToFire = DEFAULT_NUM_TO_FIRE, requestedOptions) => {
        const defaultBoundingRect = defaultConfettiContainerRef.current?.getBoundingClientRect();
        if (defaultBoundingRect == null) return [];

        return cannon.createMultipleConfetti(
          {
            ...baseConfig,
            position: {
              type: 'static-random',
              minValue: {
                x: defaultBoundingRect.x,
                y: defaultBoundingRect.y,
              },
              maxValue: {
                x: defaultBoundingRect.x + defaultBoundingRect.width,
                y: defaultBoundingRect.y + defaultBoundingRect.height * 1.5,
              },
            },
            ...(createConfettiArgs ?? {}),
          },
          numToFire,
          requestedOptions,
        );
      },
      createMultipleConfettiAt: (x, y, createConfettiArgs, numToFire = DEFAULT_NUM_TO_FIRE, requestedOptions) =>
        cannon.createMultipleConfetti(
          {...baseConfig, position: {type: 'static', value: {x, y}}, ...createConfettiArgs},
          numToFire,
          requestedOptions,
        ),
      createMultipleCoins: (createConfettiArgs, numToFire = DEFAULT_NUM_TO_FIRE, requestedOptions) => {
        const defaultBoundingRect = defaultConfettiContainerRef.current?.getBoundingClientRect();
        if (defaultBoundingRect == null) return [];

        return coinCannon.createMultipleConfetti(
          {
            ...baseConfig,
            position: {
              type: 'static-random',
              minValue: {
                x: defaultBoundingRect.x,
                y: defaultBoundingRect.y,
              },
              maxValue: {
                x: defaultBoundingRect.x + defaultBoundingRect.width,
                y: defaultBoundingRect.y + defaultBoundingRect.height * 1.5,
              },
            },
            ...(createConfettiArgs ?? {}),
          },
          numToFire,
          requestedOptions,
        );
      },
      addClickListener,
      removeClickListener,
      defaultConfettiContainerRef,
    };
  }, [addClickListener, baseConfig, cannon, coinCannon, confettiCanvas, reducedMotion, removeClickListener]);

  return (
    <ConfettiCannonContext.Provider value={value}>
      {children}
      <div ref={defaultConfettiContainerRef} className={styles.defaultConfettiContainer} />
    </ConfettiCannonContext.Provider>
  );
}
