import * as React from 'react';
import classNames from 'classnames';

import {UnsavedChangesNoticePopupMountPoint} from './unsaved_changes/UnsavedChangesNoticePopup';

import styles from './PageContent.module.css';

interface PageContentProps {
  children: React.ReactNode;
  className?: string;
}

export default function PageContent({children, className}: PageContentProps) {
  return (
    <div className={styles.contentContainer}>
      <div className={classNames(styles.contentWrapper, className)}>
        {children}
        <UnsavedChangesNoticePopupMountPoint />
      </div>
    </div>
  );
}
