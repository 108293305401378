import * as React from 'react';
import classNames from 'classnames';
import {getClass} from '@discordapp/common/utils/StylesheetUtils';

import styles from './TextInput.module.css';

export const TextInputSizes = {
  DEFAULT: 'default',
  MINI: 'mini',
};

export interface Props<TName extends string = string> {
  readOnly?: boolean;
  className?: string;
  inputClassName?: string;
  name?: TName;
  disabled?: boolean;
  type?: string;
  value?: string;
  defaultValue?: string;
  placeholder?: string;
  error?: string | React.ReactNode;
  hasError?: boolean;
  autoFocus?: boolean;
  minLength?: number;
  maxLength?: number;
  editable?: boolean;
  size?: string;
  // Events
  onChange?: (value: string, name: TName | undefined, e: React.SyntheticEvent<HTMLInputElement>) => void;
  onFocus?: (e: React.SyntheticEvent<HTMLInputElement>, name?: TName) => void;
  onBlur?: (e: React.SyntheticEvent<HTMLInputElement>, name?: TName) => void;
  onKeyPress?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
  onKeyDown?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
  inputRef?: React.Ref<HTMLInputElement> | undefined | null;
  inputPrefix?: string | null;
  spellCheck?: boolean;
  autoComplete?: string;
}

export default function TextInput<TName extends string>({
  size = TextInputSizes.DEFAULT,
  disabled = false,
  type = 'text',
  placeholder = '',
  autoFocus = false,
  maxLength = 999,
  name = '' as TName,
  error,
  hasError,
  className,
  inputClassName,
  inputPrefix,
  editable,
  inputRef,
  ...props
}: Props<TName>) {
  const errorMessage = error != null ? <div className={styles.errorMessage}>{error}</div> : null;

  const onChange = (e: React.SyntheticEvent<HTMLInputElement>) => {
    props.onChange?.(e.currentTarget.value, name, e);
  };

  const onFocus = (e: React.SyntheticEvent<HTMLInputElement>) => {
    props.onFocus?.(e, name);
  };

  const onBlur = (e: React.SyntheticEvent<HTMLInputElement>) => {
    props.onBlur?.(e, name);
  };
  return (
    <div className={classNames(styles.inputWrapper, className)}>
      {inputPrefix != null && inputPrefix !== '' ? <span className={styles.inputPrefix}>{inputPrefix}</span> : null}
      <input
        className={classNames(getClass(styles, 'input', size), inputClassName, {
          [styles.error]: error != null || hasError,
          [styles.disabled]: disabled,
          [styles.editable]: editable,
        })}
        disabled={disabled}
        type={type}
        placeholder={placeholder}
        autoFocus={autoFocus}
        maxLength={maxLength}
        {...props}
        onChange={onChange}
        onBlur={onBlur}
        onFocus={onFocus}
        ref={inputRef}
      />
      {errorMessage}
    </div>
  );
}

TextInput.Sizes = TextInputSizes;
