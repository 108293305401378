enum ActionTypes {
  // Experiments
  FINGERPRINT_SET = 'FINGERPRINT_SET',
  EXPERIMENT_TRIGGER = 'EXPERIMENT_TRIGGER',
  EXPERIMENT_REGISTER = 'EXPERIMENT_REGISTER',
  EXPERIMENTS_FETCH_SUCCESS = 'EXPERIMENTS_FETCH_SUCCESS',

  // Analytics
  TRACK = 'TRACK',

  // User
  USER_FETCH_SUCCESS = 'USER_FETCH_SUCCESS',
  USER_GUILDS_FETCH_SUCCESS = 'USER_GUILDS_FETCH_SUCCESS',
  USER_IDENTITY_VERIFICATION_FETCH_SUCCESS = 'USER_IDENTITY_VERIFICATION_FETCH_SUCCESS',
  UPDATE_TOKEN = 'UPDATE_TOKEN',
  CHECKLIST_VIEWED = 'CHECKLIST_VIEWED',
  USER_LOGOUT = 'USER_LOGOUT',

  // Guilds
  GUILD_CHANNELS_FETCH_SUCCESS = 'GUILD_CHANNELS_FETCH_SUCCESS',
  GUILD_CHANNELS_FETCH_START = 'GUILD_CHANNELS_FETCH_START',

  // Invites
  INVITE_RESOLVE_SUCCESS = 'INVITE_RESOLVE_SUCCESS',
  INVITE_RESOLVE_FAILURE = 'INVITE_RESOLVE_FAILURE',

  // Platform
  UPDATE_BROWSER = 'UPDATE_BROWSER',
  UPDATE_PLATFORM = 'UPDATE_PLATFORM',

  // Achievements
  ACHIEVEMENTS_FETCH_SUCCESS = 'ACHIEVEMENTS_FETCH_SUCCESS',
  ACHIEVEMENT_CREATE_SUCCESS = 'ACHIEVEMENT_CREATE_SUCCESS',
  ACHIEVEMENT_UPDATE_SUCCESS = 'ACHIEVEMENT_UPDATE_SUCCESS',
  ACHIEVEMENT_DELETE_SUCCESS = 'ACHIEVEMENT_DELETE_SUCCESS',

  // Applications
  APPLICATIONS_FETCH_SUCCESS = 'APPLICATIONS_FETCH_SUCCESS',
  APPLICATION_ASSETS_ADD_SUCCESS = 'APPLICATION_ASSETS_ADD_SUCCESS',
  APPLICATION_ASSETS_FETCH_SUCCESS = 'APPLICATION_ASSETS_FETCH_SUCCESS',
  APPLICATION_ASSETS_REMOVE_SUCCESS = 'APPLICATION_ASSETS_REMOVE_SUCCESS',
  APPLICATION_DISCOVERABILITY_STATE_FETCH_SUCCESS = 'APPLICATION_DISCOVERABILITY_STATE_FETCH_SUCCESS',
  APPLICATION_FETCH_SUCCESS = 'APPLICATION_FETCH_SUCCESS',
  APPLICATION_CREATE_SUCCESS = 'APPLICATION_CREATE_SUCCESS',
  APPLICATION_DELETE_SUCCESS = 'APPLICATION_DELETE_SUCCESS',
  APPLICATION_LICENSE_ACTIVATE_SUCCESS = 'APPLICATION_LICENSE_ACTIVATE_SUCCESS',
  APPLICATION_REFRESH_STORE_LAYOUT = 'APPLICATION_REFRESH_STORE_LAYOUT',
  APPLICATION_WHITELIST_FETCH_SUCCESS = 'APPLICATION_WHITELIST_FETCH_SUCCESS',
  APPLICATION_WHITELIST_ADD_USER_SUCCESS = 'APPLICATION_WHITELIST_ADD_USER_SUCCESS',
  APPLICATION_WHITELIST_REMOVE_USER_SUCCESS = 'APPLICATION_WHITELIST_REMOVE_USER_SUCCESS',
  APPLICATION_OWNER_TRANSFER_SUCCESS = 'APPLICATION_OWNER_TRANSFER_SUCCESS',
  APPLICATION_PROXY_CONFIG_FETCH_SUCCESS = 'APPLICATION_PROXY_CONFIG_FETCH_SUCCESS',
  APPLICATION_PROXY_CONFIG_UPDATE_SUCCESS = 'APPLICATION_PROXY_CONFIG_UPDATE_SUCCESS',
  APPLICATION_EMBEDDED_ACTIVITY_CONFIG_FETCH_SUCCESS = 'APPLICATION_EMBEDDED_ACTIVITY_CONFIG_FETCH_SUCCESS',
  APPLICATION_EMBEDDED_ACTIVITY_CONFIG_UPDATE_SUCCESS = 'APPLICATION_EMBEDDED_ACTIVITY_CONFIG_UPDATE_SUCCESS',
  APPLICATION_RESET_SECRET_SUCCESS = 'APPLICATION_RESET_SECRET_SUCCESS',
  APPLICATION_RESET_BOT_TOKEN_SUCCESS = 'APPLICATION_RESET_BOT_TOKEN_SUCCESS',
  STORE_ASSETS_FETCH_SUCCESS = 'STORE_ASSETS_FETCH_SUCCESS',
  STORE_ASSET_REMOVE_SUCCESS = 'STORE_ASSET_REMOVE_SUCCESS',
  STORE_ASSET_UPLOAD_SUCCESS = 'STORE_ASSET_UPLOAD_SUCCESS',
  STORE_ASSETS_UPLOAD_SUCCESS = 'STORE_ASSETS_UPLOAD_SUCCESS',
  SUBSCRIPTION_PLANS_FETCH_SUCCESS = 'SUBSCRIPTION_PLANS_FETCH_SUCCESS',

  TEAM_APPLICATIONS_FETCH_SUCCESS = 'TEAM_APPLICATIONS_FETCH_SUCCESS',
  ACKNOWLEDGE_ONBOARDING = 'ACKNOWLEDGE_ONBOARDING',

  // Application Emoji
  APPLICATION_EMOJIS_FETCH_SUCCESS = 'APPLICATION_EMOJIS_FETCH_SUCCESS',
  APPLICATION_EMOJI_UPLOAD_SUCCESS = 'APPLICATION_EMOJI_UPLOAD_SUCCESS',
  APPLICATION_EMOJI_UPDATE_SUCCESS = 'APPLICATION_EMOJI_UPDATE_SUCCESS',
  APPLICATION_EMOJI_REMOVE_SUCCESS = 'APPLICATION_EMOJI_REMOVE_SUCCESS',

  // UI
  MOBILE_MENU_CLOSE = 'MOBILE_MENU_CLOSE',
  MOBILE_MENU_OPEN = 'MOBILE_MENU_OPEN',

  // Tooltips
  TOOLTIP_SHOW = 'TOOLTIP_SHOW',
  TOOLTIP_HIDE = 'TOOLTIP_HIDE',

  // Rich Presence
  RICH_PRESENCE_APPROVAL_FORM_FETCH_SUCCESS = 'RICH_PRESENCE_APPROVAL_FORM_FETCH_SUCCESS',
  RICH_PRESENCE_APPROVAL_FORM_UPDATE_SUCCESS = 'RICH_PRESENCE_APPROVAL_FORM_UPDATE_SUCCESS',

  // Store listing
  STORE_LISTINGS_FETCH_SUCCESS = 'STORE_LISTINGS_FETCH_SUCCESS',
  APPLICATION_STORE_LISTINGS_FETCH_SUCCESS = 'APPLICATION_STORE_LISTINGS_FETCH_SUCCESS',
  STORE_LISTING_UPDATE_SUCCESS = 'STORE_LISTING_UPDATE_SUCCESS',
  STORE_LISTING_CREATE_SUCCESS = 'STORE_LISTING_CREATE_SUCCESS',
  STORE_LISTING_DELETE_SUCCESS = 'STORE_LISTING_DELETE_SUCCESS',

  // SKU
  SKU_FETCH_SUCCESS = 'SKU_FETCH_SUCCESS',
  SKU_UPDATE_SUCCESS = 'SKU_UPDATE_SUCCESS',
  SKU_CREATE_SUCCESS = 'SKU_CREATE_SUCCESS',
  PRICE_TIER_VALUES_FETCH_SUCCESS = 'PRICE_TIER_VALUES_FETCH_SUCCESS',
  PRICE_TIERS_FETCH_SUCCESS = 'PRICE_TIERS_FETCH_SUCCESS',

  // Application subscriptions

  APPLICATION_SUBSCRIPTION_GROUP_LISTING_FETCH_SUCCESS = 'APPLICATION_SUBSCRIPTION_GROUP_LISTING_FETCH_SUCCESS',
  APPLICATION_SUBSCRIPTION_GROUP_LISTING_CREATE_SUCCESS = 'APPLICATION_SUBSCRIPTION_GROUP_LISTING_CREATE_SUCCESS',
  APPLICATION_SUBSCRIPTION_LISTING_CREATE_SUCCESS = 'APPLICATION_SUBSCRIPTION_LISTING_CREATE_SUCCESS',
  APPLICATION_SUBSCRIPTION_LISTING_UPDATE_SUCCESS = 'APPLICATION_SUBSCRIPTION_LISTING_UPDATE_SUCCESS',
  APPLICATION_SUBSCRIPTION_LISTING_DELETE_SUCCESS = 'APPLICATION_SUBSCRIPTION_LISTING_DELETE_SUCCESS',

  // Builds
  BRANCHES_FETCH_SUCCESS = 'BRANCHES_FETCH_SUCCESS',
  BUILDS_FETCH_SUCCESS = 'BUILDS_FETCH_SUCCESS',
  BUILD_PUBLISH_SUCCESS = 'BUILD_PUBLISH_SUCCESS',
  MANIFEST_LABELS_FETCH_SUCCESS = 'MANIFEST_LABELS_FETCH_SUCCESS',

  // Teams
  TEAMS_FETCH_SUCCESS = 'TEAMS_FETCH_SUCCESS',
  TEAM_CREATE_SUCCESS = 'TEAM_CREATE_SUCCESS',
  TEAM_FETCH_SUCCESS = 'TEAM_FETCH_SUCCESS',
  TEAM_UPDATE_SUCCESS = 'TEAM_UPDATE_SUCCESS',
  TEAM_DELETE_SUCCESS = 'TEAM_DELETE_SUCCESS',
  TEAM_INVITE_ACCEPTED = 'TEAM_INVITE_ACCEPTED',
  TEAM_PAYOUT_HISTORY_FETCH_SUCCESS = 'TEAM_PAYOUT_HISTORY_FETCH_SUCCESS',
  TEAM_IDENTITY_VERIFICATION_FETCH_SUCCESS = 'TEAM_IDENTITY_VERIFICATION_FETCH_SUCCESS',

  // TeamMembers
  TEAM_MEMBERS_FETCH_SUCCESS = 'TEAM_MEMBERS_FETCH_SUCCESS',
  TEAM_MEMBER_CREATE_SUCCESS = 'TEAM_MEMBER_CREATE_SUCCESS',
  TEAM_MEMBER_UPDATE_SUCCESS = 'TEAM_MEMBER_UPDATE_SUCCESS',
  TEAM_MEMBER_DELETE_SUCCESS = 'TEAM_MEMBER_DELETE_SUCCESS',

  // Companies
  COMPANY_CREATE_SUCCESS = 'COMPANY_CREATE_SUCCESS',
  COMPANY_QUERY_SUCCESS = 'COMPANY_QUERY_SUCCESS',

  // Billing
  PAYMENT_SOURCE_CREATE_SUCCESS = 'PAYMENT_SOURCE_CREATE_SUCCESS',
  PAYMENT_SOURCES_FETCH_SUCCESS = 'PAYMENT_SOURCES_FETCH_SUCCESS',

  // Entitlements
  ENTITLEMENTS_FETCH_SUCCESS = 'ENTITLEMENTS_FETCH_SUCCESS',
  ENTITLEMENT_CREATE_SUCCESS = 'ENTITLEMENT_CREATE_SUCCESS',
  GIFT_CODE_BATCHES_FETCH_SUCCESS = 'GIFT_CODE_BATCHES_FETCH_SUCCESS',
  GIFT_CODE_BATCH_CREATE_SUCCESS = 'GIFT_CODE_BATCH_CREATE_SUCCESS',

  // Application and Guild Analytics
  ANALYTICS_REQUEST_SUCCESS = 'ANALYTICS_REQUEST_SUCCESS',
  GUILD_ANALYTICS_REQUEST_SUCCESS = 'GUILD_ANALYTICS_REQUEST_SUCCESS',
  ANNOUNCEMENT_MESSAGE_FETCH_START = 'ANNOUNCEMENT_MESSAGE_FETCH_START',
  ANNOUNCEMENT_MESSAGE_FETCH_SUCCESS = 'ANNOUNCEMENT_MESSAGE_FETCH_SUCCESS',
  ANNOUNCEMENT_MESSAGE_FETCH_FAILURE = 'ANNOUNCEMENT_MESSAGE_FETCH_FAILURE',
  SET_GUILD_ANALYTICS_REQUEST_CACHE = 'SET_GUILD_ANALYTICS_REQUEST_CACHE',
  CLEAR_GUILD_ANALYTICS_REQUEST_CACHE = 'CLEAR_GUILD_ANALYTICS_REQUEST_CACHE',

  SHOW_MFA_MODAL = 'SHOW_MFA_MODAL',
  HIDE_MFA_MODAL = 'HIDE_MFA_MODAL',
  SHOW_GET_MFA_MODAL = 'SHOW_GET_MFA_MODAL',
  HIDE_GET_MFA_MODAL = 'HIDE_GET_MFA_MODAL',
}

export default ActionTypes;
