import {BatchedStoreListener} from './BatchedStoreListener';
import {Dispatcher, DispatchToken, ActionHandlers} from './Dispatcher';
import Emitter from './Emitter';
import {PersistedStore, DeviceSettingsStore, OfflineCacheStore} from './PersistedStore';
import {Store} from './Store';
import connectStores from './connectStores';
import {createFetchStore, revalidateFetchStoreData} from './createFetchStore';
import {
  useStateFromStores,
  useStateFromStoresObject,
  useStateFromStoresArray,
  statesWillNeverBeEqual,
} from './useStateFromStores';

import type {ActionBase} from './FluxTypes';

export {
  Store,
  Dispatcher,
  DispatchToken,
  BatchedStoreListener,
  createFetchStore,
  revalidateFetchStoreData,
  statesWillNeverBeEqual,
  useStateFromStores,
  useStateFromStoresObject,
  useStateFromStoresArray,
  ActionBase,
  ActionHandlers,
};

export function initialize() {
  Store.initialize();
}

export function destroy() {
  // will call Store.destroy() too
  PersistedStore.destroy();
}

export default {
  Emitter,
  Store,
  PersistedStore,
  DeviceSettingsStore,
  OfflineCacheStore,
  connectStores,
  initialize,
  get initialized(): Promise<void> {
    return Store.initialized;
  },
};
