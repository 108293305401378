import {useEffect, useRef} from 'react';
import invariant from 'invariant';

export default function useInterval(callback: () => void, delay: number, dependencies?: React.DependencyList) {
  const savedCallback = useRef(callback);

  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  useEffect(() => {
    function tick() {
      invariant(savedCallback.current != null, 'Missing callback');
      savedCallback.current();
    }
    const id = setInterval(tick, delay);
    return () => clearInterval(id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [delay, ...(dependencies ?? [])]);
}
