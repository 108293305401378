import * as React from 'react';
import {Location, useLocation} from 'react-router-dom';

import {closeMobileMenu} from '@developers/actions/UIActions';
import {fetchGuilds} from '@developers/actions/UserActionCreators';
import ScrollToTop from '@developers/uikit/ScrollToTop';
import Tooltips from '@developers/uikit/Tooltips';
import {
  getPageName,
  getApplicationProperties,
  getGuildProperties,
  trackPageLoad,
} from '@developers/utils/TrackingAnalyticsUtils';
import AppHeader from './AppHeader';
import DeveloperSurveyNagbar from './DeveloperSurveyNagbar';
import MFACodeModal from './MFACodeModal';

import {HistoryActions, NOOP} from '@developers/Constants';
import styles from './PageWrapper.module.css';

interface LocationData {
  previousLinkLocation?: string;
}

const handleRouteChange = (location: Location, action?: HistoryActions): void => {
  const applicationAnalyticsDetails = getApplicationProperties(location);
  const guildAnalyticsDetails = getGuildProperties(location);
  const previousLinkLocation = location.state != null ? location.state.previousLinkLocation : null;
  trackPageViews(location, previousLinkLocation, {...applicationAnalyticsDetails, ...guildAnalyticsDetails});
  if (action !== HistoryActions.REPLACE) {
    closeMobileMenu();
  }
};

const trackPageViews = (
  location: Location<LocationData>,
  previousLink: string | null | undefined,
  additionalDetails: Record<string, unknown>,
): void => {
  const pageName = getPageName(location);
  trackPageLoad(pageName, previousLink, additionalDetails);
};

interface Props {
  children: React.ReactNode;
}

export default function PageWrapper({children}: Props) {
  const location = useLocation();
  React.useEffect(() => {
    handleRouteChange(location);
  }, [location]);

  React.useEffect(() => {
    // TODO(beckwith) This appears to not be behind a route that requires authentication
    // so it's showing up as a failed request when logged out.
    // Queuing this up for a later investigation.
    fetchGuilds().catch(NOOP);
  }, []);

  return (
    <div className={styles.shakeShake}>
      <ScrollToTop />
      <DeveloperSurveyNagbar />
      <AppHeader />
      <div className={styles.content}>{children}</div>
      <Tooltips />
      <MFACodeModal />
    </div>
  );
}
