import chroma from 'chroma-js';
import clamp from 'lodash/clamp';
import mapValues from 'lodash/mapValues';

import {_private as experimentColorDefinitions, ExperimentName} from './colors/generated/experiment-definitions';
import {_private as colorDefinitions} from './colors/generated/generated-definitions';
import {_private as moduleDefintions} from './modules/generated/generated-definitions';
import {Radius} from './radii/generated/generated-definitions';
import {_private as shadowDefinitions} from './shadows/generated/generated-definitions';
import {_private as spacingDefinitions} from './spacing/generated/generated-definitions';
import {_private as themeDefinitions} from './themes/generated/generated-definitions';

const {Themes} = themeDefinitions;
const {SemanticColors, RawColors} = colorDefinitions;
const {SemanticColorExperiments} = experimentColorDefinitions;
const {Shadows} = shadowDefinitions;
const {Spacing} = spacingDefinitions;
const {Modules} = moduleDefintions;
type Themes = typeof Themes;
type SemanticColors = typeof SemanticColors;
type RawColors = typeof RawColors;
type Shadows = typeof Shadows;
type Spacing = typeof Spacing;
type Modules = typeof Modules;

type OpaqueSpringColor = string & {__opaque__: true};

interface Tweaks {
  /**
   * A value between 0 and 1, where 0 is fully transparent and 1 is the full
   * opacity of the underlying color/token.
   */
  opacity?: number;
}

interface SemanticColor {
  /**
   * A reference to the semantic color as a CSS custom property. The underlying
   * raw color that it references based on the theme applied to the element
   */
  css: string;

  /**
   * Resolve the semantic color to a raw token. Useful for cases where you need
   * access to the raw color value, such as when animating with React Spring.
   *
   * ```tsx
   * const theme = useTheme();
   * const resolved = tokens.colors.BACKGROUND_PRIMARY.resolve(theme);
   * const from = resolved.spring({opacity: 0});
   * const to = resolved.spring({opacity: 1});
   * ```
   */
  resolve(context: {
    theme: Themes[keyof Themes];
    saturation: number;
    enabledExperiments?: readonly ExperimentName[] | undefined;
  }): ResolvedRawColor;
}

interface RawColor {
  /**
   * A reference to the color as a CSS custom property. This is preferable
   * in most cases, but is not animatable in React Spring or useable in
   * canvases.
   */
  css: string;

  /**
   * Resolve a raw color to a specific saturation, which should generally
   * reflect the user's chosen accessibility saturation factor.
   */
  resolve(context: {saturation: number}): ResolvedRawColor;
}

interface ResolvedRawColor {
  /**
   * Returns a version of this color suitable for usage with React Spring,
   * allowing for tweaks to saturation and opacity. Depending on use case,
   * it may make sense to pass in the user's chosen saturation factor.
   */
  spring(tweaks?: Tweaks): OpaqueSpringColor;

  /**
   * Returns a CSS `hsl()` representation of this color. It's unlikely you'll
   * need this function—its main use case is in canvas rendering.
   */
  hsl(tweaks?: Tweaks): string;

  /**
   * Returns a hex code representation of the color, with alpha included if needed.
   */
  hex(tweaks?: Tweaks): string;

  /**
   * Returns an integer representation of the color, with alpha included if needed.
   */
  int(tweaks?: Tweaks): number;
}

interface Shadow {
  css: string;
  resolve(context: {theme: Themes[keyof Themes]}): ResolvedShadow;
}

interface ResolvedShadow {
  boxShadow: string;
  filter: string;
}

const rawChromas = mapValues(RawColors, (hex) => chroma(hex));

/**
 * A collection of functions for interacting with all Design System tokens.
 * At the moment, this only includes color tokens, but will eventually be
 * expanded to include typography, layout, elevations, border radii, and more.
 */
const tokens = {
  themes: Themes,
  modules: Modules,

  /**
   * In CSS, it's common to provide a custom property to apply [semantic colors][0] to elements.
   *
   * ```css
   * .example {
   *   background: var(--background-primary);
   * }
   * ```
   *
   * If instead you want to use a custom property in TypeScript, you can use this object to access all semantic token
   * custom property values in a type-safe way.
   *
   * ```tsx
   * import {tokens} from '@discordapp/tokens/web';
   * tokens.colors.BACKGROUND_PRIMARY.css
   * // → "var(--background-primary)"
   * ```
   *
   * The same object exists for React Native.
   *
   * [0]: TODO
   */
  colors: mapValues(SemanticColors, (token, stringKey) => {
    const key = stringKey as keyof SemanticColors;

    return {
      css: toCustomProperty(key),
      resolve(context): ResolvedRawColor {
        const mapping = token[context.theme];
        let rawKey = mapping.raw;
        let opacity = mapping.opacity;

        if (
          key in SemanticColorExperiments &&
          context.enabledExperiments != null &&
          context.enabledExperiments.length > 0
        ) {
          for (const experiment of context.enabledExperiments) {
            const override = SemanticColorExperiments[key]?.[experiment]?.[context.theme];
            if (override != null) {
              // Need to coerce to any because TypeScript reports an error about `raw` not
              // being compatible despite both objects using the same `keyof RawColors` type.
              rawKey = (override.raw as any) ?? rawKey;
              opacity = override.opacity ?? opacity;
            }
          }
        }

        if (opacity === 1) {
          return tokens.unsafe_rawColors[rawKey].resolve(context);
        } else {
          let baseColor = rawChromas[rawKey];

          if (baseColor.alpha() !== 0 && opacity !== 1) {
            baseColor = baseColor.alpha(opacity);
          }
          return createRawColor(baseColor, context.saturation);
        }
      },
    } as SemanticColor;
  }),

  /**
   * Retrieves a raw color token by name, returning it as a CSS custom property.
   *
   * @deprecated
   * **Working with raw color tokens is dangerous**—they do not respond to the
   * user's chosen theme. In most cases, you should use semantic color tokens
   * instead.
   *
   * ```tsx
   * import {tokens} from '@discordapp/tokens/web';
   * const color = tokens.unsafe_rawColors.PRIMARY_100.css;
   * <div style={{ color }} />
   * // → <div style="color: var(--primary-100)" />
   * ```
   */
  unsafe_rawColors: mapValues(RawColors, (_hex, stringKey) => {
    const key = stringKey as keyof RawColors;
    const baseColor = rawChromas[key];

    return {
      css: toCustomProperty(key),
      resolve(context) {
        return createRawColor(baseColor, context.saturation);
      },
    } as RawColor;
  }),

  /**
   * Retrieves a full string for a CSS box-shadow or drop-shadow filter.
   *
   * Use CSS first for shadows before resorting to JS. And use the
   * filter when animating shadows for better performance.
   *
   * ```css
   * .example {
   *   box-shadow: var(--shadow-low);
   * }
   *
   * .example-hover {
   *   filter: var(--shadow-low-filter);
   *
   *   &:hover {
   *     filter: var(--shadow-low-hover-filter);
   *   }
   * }
   * ```
   *
   * If JS is required for your shadow, then use useToken to get the themed token.
   *
   * ```tsx
   * import {tokens} from '@discordapp/tokens/web';
   * const shadow = useToken(tokens.shadows.SHADOW_LOW).filter
   * const shadowOnHover = useToken(tokens.shadows.SHADOW_LOW_HOVER).filter
   * ```
   *
   */
  shadows: mapValues(Shadows, (token, stringKey) => {
    const key = stringKey as keyof Shadows;

    return {
      css: toCustomProperty(key),
      resolve(context) {
        return {
          boxShadow: token[context.theme].boxShadow,
          filter: token[context.theme].filter,
          nativeStyles: token[context.theme].nativeStyles,
        };
      },
    } as Shadow;
  }),

  radii: Radius,

  /**
   * Provides an object with spacing values from 4 to 96.
   *
   * ```tsx
   * import {tokens} from '@discordapp/tokens/web';
   * const styles = {
   *   padding: tokens.spacing.PX_8
   * }
   * <div style={styles} />
   * ```
   */
  spacing: mapValues(Spacing, (token) => {
    return `${token}px`;
  }),
};

function createRawColor(baseColor: chroma.Color, saturation: number): ResolvedRawColor {
  return {
    spring(tweaks = {}) {
      return prepareColor(baseColor, saturation, tweaks).hex('rgba') as unknown as OpaqueSpringColor;
    },
    hsl(tweaks = {}) {
      return prepareColor(baseColor, saturation, tweaks).css('hsl');
    },
    hex(tweaks = {}) {
      return prepareColor(baseColor, saturation, tweaks).hex();
    },
    int(tweaks = {}) {
      const tweakedColor = prepareColor(baseColor, saturation, tweaks);

      const int = tweakedColor.num();
      if (tweakedColor.alpha() !== 1) {
        return (int << 8) | Math.round(tweakedColor.alpha() * 255);
      } else {
        return int;
      }
    },
  };
}

function prepareColor(color: chroma.Color, saturation: number, tweaks: Tweaks) {
  const opacity = clamp(tweaks.opacity ?? 1, 0, 1);

  let newColor = color;
  if (saturation !== 1) {
    newColor = newColor.set('hsl.s', newColor.get('hsl.s') * saturation);
  }
  if (opacity !== 1) {
    newColor = newColor.alpha(newColor.alpha() * opacity);
  }

  return newColor;
}

function lowerKebab(string: string) {
  return string.toLowerCase().replace(/_/g, '-');
}

function toCustomProperty(name: string, prefix?: string) {
  const kebabPrefix = prefix != null ? lowerKebab(prefix) : null;
  const kebabName = lowerKebab(name);
  return `var(--${[kebabPrefix, kebabName].filter(Boolean).join('-')})`;
}

export default tokens;

export type {SemanticColor, RawColor, Shadow, Spacing, OpaqueSpringColor, ResolvedRawColor};
